import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-document-upload-error',
  templateUrl: './document-upload-error.component.html',
  styleUrls: ['./document-upload-error.component.scss'],
})
export class DocumentUploadErrorComponent {
  constructor(public dialogRef: MatDialogRef<DocumentUploadErrorComponent>) {}
}
