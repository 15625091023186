import { Injectable } from '@angular/core';

import { DatabaseService } from './base/database.service';
import { Faq } from '../models/faq';
import { Kyc_Check } from '../models/kyc_check.model';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class KycService extends DatabaseService<Kyc_Check> {
  constructor(private http: HttpClient) {
    super('cp_kyc_check');
  }

  async getAllForUser(id: string): Promise<Kyc_Check[]> {
    return (
      await this.supabase
        .from('cp_kyc_check')
        .select()
        .eq('checked_user_id', id)
        .order('date_requested', { ascending: true })
    ).data as unknown as Kyc_Check[];
  }

  async submitKycCheck(body: any) {
    const token = (await this.supabase.auth.getSession()).data.session
      ?.access_token;
    const ret = (await firstValueFrom(
      this.http.post(environment.lambdaUrl + '/kyc_check', body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    )) as any;

    return ret;
  }
}
