import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-rounded',
  templateUrl: './button-rounded.component.html',
  styleUrls: ['./button-rounded.component.scss'],
})
export class ButtonRoundedComponent {
  @Input() type!: string;
}
