import { Base } from './base.model';

export interface Event extends Base {
  type: number;
  user_agent: any;
  context: any;
  user_id?: string;
}

export enum EventTypes {
  LOGIN_FAILED = 1,
  LOGIN_SUCCESSFUL = 2,
  DOCUMENT_DOWNLOAD = 3,
  DOCUMENT_SIGN_TRY = 4,
  DOCUMENT_SIGN_SUCCESS = 5,
  DOCUMENT_SIGN_FAIL = 6,
  QUESTIONNAIRE_NEXT_PAGE = 7,
  QUESTIONNAIRE_COMPLETED = 8,
  FAQ_READ = 9,
  ELIGIBILITY_CRITERIA_FAIL = 10,
  ELIGIBILITY_CRITERIA_SUCCESS = 11,
  KYC_FAIL = 12,
  KYC_SUCCESS = 13,
  CLIENT_ONBOARDED = 14,
}
