import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MfaEnrollComponent } from '../mfa-enroll.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-success-or-error',
  templateUrl: './success-or-error.component.html',
  styleUrls: ['./success-or-error.component.scss'],
})
export class SuccessOrErrorComponent {
  @Input() success: boolean = false;
  @Input() error: boolean = false;
  @Output() close$: EventEmitter<boolean> = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<MfaEnrollComponent>) {}
}
