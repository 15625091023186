import { Component, Input } from '@angular/core';
import { Case } from 'src/app/core/models/case';

@Component({
  selector: 'app-block-case',
  templateUrl: './block-case.component.html',
  styleUrls: ['./block-case.component.scss'],
})
export class BlockCaseComponent {
  random = Math.round(Math.random() * 300);
  @Input() case!: Case;
}
