import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss'],
})
export class BottomNavComponent {
  @Output() toggleSideNavEvent = new EventEmitter();

  toggleSideNav() {
    this.toggleSideNavEvent.emit();
  }
}
