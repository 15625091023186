import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Question } from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';
import { AutofillFilter } from '../question-text/question-text.component';
import { DynamicService } from 'src/app/core/services/base/dynamic.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-question-date',
  templateUrl: './question-date.component.html',
  styleUrls: ['./question-date.component.scss'],
})
export class QuestionDateComponent implements OnInit {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  min!: Date;
  max!: Date;
  autofillMode: boolean = false;
  autofillEditable: boolean = false;
  userId: string = '';

  constructor(
    private dynamicService: DynamicService<any>,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    console.log('CAIO4 - QuestionDateComponent - ngOnInit', this.question);

    if (this.question.configuration) {
      const config = this.question.configuration;
      console.log('setting up date', config);

      if (config.autofill && !this.question.answer?.value) {
        this.processAutofill(config.autofill);
      }

      if (config.min) {
        this.min = new Date(config.min);
      }

      if (config.max) {
        this.max = new Date(config.max);
      }
    }
    this.change();
  }

  private async processAutofill(autofillConfig: any) {
    this.autofillMode = true;
    this.autofillEditable = autofillConfig.editable ?? false;
    await this.fetchCurrentUserId();

    console.log('CAIO4 - QuestionPhoneComponent - processAutofill', {
      autofillConfig,
      userId: this.userId,
    });

    const filters = this.replacePlaceholdersInFilters(autofillConfig.filters);
    const autofillValue = await this.fetchAutofillValue(
      autofillConfig.from,
      autofillConfig.select,
      filters
    );

    console.log('CAIO4 - QuestionPhoneComponent - processAutofill', {
      autofillValue,
    });

    if (autofillValue) {
      console.log('CAIO4 - QuestionPhoneComponent - processAutofill', {
        autofillValue,
      });
      const date = moment(autofillValue).add(12, 'hours');

      this.question.answer = { value: date.toDate() };

      this.change();
    }
  }

  private async fetchCurrentUserId() {
    const currentUser = await this.userService.getCurrentUser();
    this.userId = currentUser?.id || '';
  }

  private replacePlaceholdersInFilters(
    filters: AutofillFilter[]
  ): AutofillFilter[] {
    return filters.map((filter) => ({
      ...filter,
      val: filter.val.replace('$userId', this.userId),
    }));
  }

  private async fetchAutofillValue(
    from: string,
    select: string,
    filters: AutofillFilter[]
  ): Promise<string | null> {
    try {
      this.dynamicService.load(from);
      const result = await this.dynamicService.autofillQuery(
        from,
        select,
        filters
      );

      console.log('CAIO4 - QuestionPhoneComponent - fetchAutofillValue', {
        result,
        result2: result?.[0],
        result3: result?.[0]?.[select as any],
        result4:
          result?.[0]?.[
            (select as any).split('->>')[
              (select as any).split('->>').length - 1
            ]
          ],
      });

      return (
        result?.[0]?.[
          (select as any).split('->>')[(select as any).split('->>').length - 1]
        ] || null
      );
    } catch (error) {
      console.error('Erro ao buscar valor de autofill:', error);
      return null;
    }
  }

  change() {
    this.question.isValid = this.checkValidation();

    if (this.question.answer) {
      this.changeAnswer.next({
        answer: this.question.answer,
        question: this.question,
      });
    } else {
      console.log('Changed to invalid value');
    }
  }

  checkValidation() {
    const date = new Date(this.question.answer!.value as Date);
    const mindate = this.min || new Date(-1899240400000);
    const maxdate = this.max || new Date(1899240400000);
    let result = true;
    console.log('Checking date', {
      mindate,
      maxdate,
      date,
      valid: true,
      dateTime: date.getTime(),
      mindatetime: mindate.getTime(),
      maxdatetime: maxdate.getTime(),
      higher_than_min: date.getTime() > mindate.getTime(),
      lower_than_max: date.getTime() < maxdate.getTime(),
    });

    if (!this.question.answer!.value) {
      return false;
    }

    result =
      date &&
      date.getTime() > mindate.getTime() &&
      date.getTime() < maxdate.getTime();
    return result;
  }
}
