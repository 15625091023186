import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { createClient } from '@supabase/supabase-js';
import { Observable, combineLatest, filter, first } from 'rxjs';
import { UploadReturn } from 'src/app/core/services/base/document.service';
import { ClaimDocumentService } from 'src/app/core/services/claim_document.service';
import { PersonalDocumentService } from 'src/app/core/services/personal_document.service';

export interface FileWrapper {
  file: File;
  upload: number;
}

@Component({
  selector: 'app-document-upload-modal',
  templateUrl: './document-upload-modal.component.html',
  styleUrls: ['./document-upload-modal.component.scss'],
})
export class DocumentUploadModalComponent {
  URL = 'path_to_api';
  boxFile: string = '';
  renderFile: string = '';
  sendFile: string = '';
  nameFile: string = '';
  successFile: string = '';
  errorFile: string = '';
  files?: File[];
  isPdf = false;
  backgroundImage: string | undefined = '';

  result: any = {};

  uploadStatus: UploadReturn[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      bucket: string;
      name: string;
      type: 'PERSONAL' | 'CLAIM';
      multiple: boolean;
      ref: string;
    },
    public dialog: MatDialog,
    private pds: PersonalDocumentService,
    private cds: ClaimDocumentService,
    private dialogRef: MatDialogRef<DocumentUploadModalComponent>
  ) {
    console.log('DocumentUploadModal -> this', this);
    this.result = {
      status: 'CANCELLED',
      value: null,
    };

    const sub = this.dialogRef
      .backdropClick()
      .pipe(first())
      .subscribe((e) => this.close());
  }

  close() {
    console.log('On Modal', this.result);
    this.dialogRef.close(JSON.stringify(this.result));
  }

  fileChange(ev: any) {
    if (ev.target.files) {
      this.files = ev.target.files;
      this.isPdf = this.files![0].type == 'application/pdf' ? true : false;
      console.log(this.isPdf);
      console.log('   Nome: ', ev.target.files[0].name);
      this.nameFile = ev.target.files[0].name;
      this.boxFile = '2';
      console.log('Tamanho: ', ev.target.files[0].size);
      console.log('   Tipo: ', ev.target.files[0].type);

      this.getBase64(this.files![0])
        .then((res) => {
          this.renderFile = String(res);
          console.log(this.renderFile);
        })
        .catch((rej) => {
          console.log(rej);
        });
    }
  }
  getBase64(file: File) {
    return new Promise((res, rej) => {
      var reader = new FileReader();
      reader.onload = function () {
        res(reader.result);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
        rej(error);
      };
      reader.readAsDataURL(file);
    });
  }

  async uploadFile() {
    this.renderFile = '';
    this.sendFile = '2';
    let uls: ClaimDocumentService | PersonalDocumentService;
    let doc: DocumentType;
    this.uploadStatus = [];
    if (!this.files) {
      throw new Error('No files.');
    }

    if (this.data.type == 'CLAIM') {
      uls = this.cds;
    } else {
      uls = this.pds;
    }

    if (this.data.multiple) {
      for (let i = 0; i < this.files.length; i++) {
        const f = this.files[i];
        const a = await uls.upload(
          f,
          this.data.ref,
          this.data.name,
          this.data.bucket,
          i
        );
        this.uploadStatus.push(a);
      }
    } else {
      const f = this.files[0];
      const a = await uls.upload(
        f,
        this.data.ref,
        this.data.name,
        this.data.bucket
      );
      this.uploadStatus.push(a);
    }

    this.successFile = '1';
    this.sendFile = '';

    this.result.value = this.uploadStatus.map((e) => e.data?.path);
    this.result.status = 'COMPLETED';

    // this.successFile = '2';
    // this.errorFile = '2';
  }

  openDialog() {
    const dialogRef = this.dialog.open(DocumentUploadModalComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    event.preventDefault();
  }
  @HostListener('dragenter', ['$event']) onDragEnter(event: any) {
    event.preventDefault();
  }
  @HostListener('dragend', ['$event']) onDragEnd(event: any) {
    event.preventDefault();
  }
  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.isPdf = files![0].type == 'application/pdf' ? true : false;
      this.getBase64(files![0])
        .then((res) => {
          this.renderFile = String(res);
          this.backgroundImage = `url(${this.renderFile})`;
          console.log(this.renderFile);
        })
        .catch((rej) => {
          console.log(rej);
        });
    }
  }
}
function decode(arg0: string): any {
  throw new Error('Function not implemented.');
}
