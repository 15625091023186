import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-document-preview-modal',
  templateUrl: './document-preview-modal.component.html',
  styleUrls: ['./document-preview-modal.component.scss'],
})
export class DocumentPreviewModalComponent implements OnInit {
  URL!: string;
  extension!: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.URL = this.data.URL;
    this.extension = this.data.extension;
  }
}
