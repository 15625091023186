import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Message, Thread } from 'src/app/core/models/comms.model';
import { ClaimsService } from 'src/app/core/services/claims.service';
import { CommunicationService } from 'src/app/core/services/communication.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-new-communication',
  templateUrl: './new-communication.component.html',
  styleUrls: ['./new-communication.component.scss'],
})
export class NewCommunicationComponent implements OnInit {
  claimId: string = '';
  user: any;
  thread: Thread | undefined;
  message: Message | undefined;
  phone?: string;
  email?: string;

  withoutCase: boolean = false;

  loading: boolean = false;
  constructor(
    private router: Router,
    private cs: CommunicationService,
    private activatedRoute: ActivatedRoute,
    private us: UserService,
    private cls: ClaimsService
  ) {}
  async ngOnInit(): Promise<void> {
    console.log('New Communication Component =< Hello');
    // Get claim ID from URL
    const claimId = this.activatedRoute.snapshot.paramMap.get('claim');
    console.log('claimId', claimId);
    if (claimId !== 'new') {
      this.claimId = claimId ?? '';
      if (!this.claimId) {
        this.router.navigate(['/client']);
      }

      this.cls.getClaimWithCase(this.claimId).then((e) => {
        this.phone = e.cp_case.attributes?.contact?.phone;
        this.email = e.cp_case.attributes?.contact?.email;
      });
    } else {
    }
    this.user = await this.us.getCurrentUser();

    this.thread = this.createNewThread();
    this.message = this.createNewMessage();
  }
  createNewThread(): Thread {
    const newThread: Thread = {
      medium: 'INTERNAL_MESSAGE',
      related_claim_id: this.claimId,
      open_for_replies: true,

      subject: '',
      started_by_user_id: this.user?.id ?? '-',

      attributes: {},
      language: '',
      started_at: new Date(),
    };

    return newThread;
  }
  createNewMessage(): Message {
    const newMessage: Message = {
      value: '',
      thread_id: '',
      author_user_id: this.user?.id ?? '-',

      type_message: 'INTERNAL_MESSAGE',
      author_display_name: '',
      attachments: {},
      user_agent: {},
      metadata: {},
      user_reads: [],
    };

    return newMessage;
  }

  async saveThread(): Promise<void> {
    this.loading = true;
    try {
      if (!this.thread?.subject) return;

      const [resThread, ...rest] = await this.cs.create(this.thread!);

      this.message!.thread_id = resThread.id ?? '';
      if (!this.message?.value || !this.message.thread_id) return;
      const resMessage = await this.cs.sendMessage(this.message!);

      this.router.navigate(['/client/claim/' + this.claimId]);
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
