import { Injectable } from '@angular/core';
import { SupabaseClient, createClient } from '@supabase/supabase-js';
import { Database } from '../../models/types';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private supabaseUrl: string;
  private supabaseKey: string;
  public supabase: SupabaseClient<Database>;
  constructor() {
    this.supabaseUrl = environment.supabaseUrl;
    this.supabaseKey = environment.supabaseKey;
    this.supabase = createClient<Database>(this.supabaseUrl, this.supabaseKey);
  }
}
