import { CommonModule } from '@angular/common';
import { Component, type OnInit } from '@angular/core';
import { User } from 'src/app/core/models/user.model';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-notification-preferencies',
  templateUrl: './notification-preferencies.component.html',
  styleUrls: ['./notification-preferencies.component.scss'],
})
export class NotificationPreferenciesComponent implements OnInit {
  u: User | null = null;

  importantNotifications = true;
  defaultAttributes = {
    comm_preferences: {
      reminders: false,
      news_announcements: false,
    },
  };

  constructor(private us: UserService) {}
  async ngOnInit() {
    this.u = await this.us.getCurrentUser();

    if (this.u && !this.u?.attributes) {
      this.u.attributes = {};
    }

    if (this.u && !this.u?.attributes.comm_preferences) {
      this.u.attributes = {
        ...this.u.attributes,
        comm_preferences: this.defaultAttributes.comm_preferences,
      };

      console.log(this.u.attributes.comm_preferences);
    }
  }

  save() {
    if (!this.u) {
      return;
    }

    this.us.update(this.u!);
  }
}
