import { Component, Input } from '@angular/core';
import { ButtonDefaultType } from 'src/app/shared/types/button-default-type.type';

@Component({
  selector: 'app-button-default',
  templateUrl: './button-default.component.html',
  styleUrls: ['./button-default.component.scss'],
})
export class ButtonDefaultComponent {
  @Input() type!: ButtonDefaultType;
  @Input() size!: string;

  @Input() disabled: boolean = false;
}
