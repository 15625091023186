import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Answer,
  Question,
  QuestionConfigurationTable,
  QuestionInPage,
  QuestionTable,
  QuestionTableConfigQuestions,
  QuestionType,
} from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';
import slugify from 'slugify';
import { v4 as uuid } from 'uuid';
import { QuestionContext } from '../question-multi-file/question-multi-file.component';
import { runConditions } from 'src/app/shared/utils/hideif';
import { BehaviorSubject, debounceTime } from 'rxjs';

interface Section {
  questions: QuestionInPage[];
  hideMap: HideMap;
  answers: AnswersMap;
}

interface HideMap {
  [questionIndex: number]: boolean;
}

interface AnswersMap {
  [slug: string]: Answer;
}

@Component({
  selector: 'app-question-table',
  templateUrl: './question-table.component.html',
  styleUrls: ['./question-table.component.scss'],
})
export class QuestionTableComponent implements OnInit {
  @Input('question') question!: QuestionTable;
  @Input('context') context!: QuestionContext;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  sections: Section[] = [];

  constructor() {}

  ngOnInit() {
    if (this.question.answer && this.question.answer.addtional_information) {
      console.log('qtc => init', this.question);

      this.sections = this.question.answer.addtional_information.map(
        (sectionInDB) => {
          const section: Section = {
            answers: sectionInDB,
            hideMap: {},
            questions: this.question.configuration.table.map((t) => {
              let ans: Answer = {
                value: '',
              };

              if (sectionInDB[t.slug]) {
                ans = {
                  value: sectionInDB[t.slug].value,
                  addtional_information:
                    sectionInDB[t.slug].addtional_information,
                };
              }
              return configToQinT(t, this.context.claim_id, ans);
            }),
          };
          return section;
        }
      );
    }
  }

  addNewSection() {
    const newSection: Section = {
      answers: {},
      hideMap: {},
      questions: this.question.configuration.table.map((q) =>
        configToQinT(q, this.context.claim_id, { value: '' })
      ),
    };

    console.log('qtc => New Section', newSection);

    this.sections.push(newSection);
  }

  removeSection(event: any, section: number) {}

  onChildQuestionsChangeAnswer(
    sectionIndex: number,
    childAnswer: QuestionAnswer
  ) {
    console.log('qtc => received onChildQuestionsChangeAnswer event', {
      sectionIndex,
      answer: childAnswer,
    });

    this.sections[sectionIndex].answers[childAnswer.question.slug] =
      childAnswer.answer;
    this.question.isValid = true;

    this.sections[sectionIndex].hideMap = evalHideMap(
      this.sections[sectionIndex]
    );

    console.log('qtc => hide map', this.sections[sectionIndex].hideMap);

    this.changeAnswer.next({
      answer: {
        value: 'TABLE',
        addtional_information: this.sections.map((s) => s.answers),
      },
      question: this.question,
    });
  }
}

function configToQinT(
  t: QuestionTableConfigQuestions,
  claim_id: string,
  ans: Answer
): QuestionInPage {
  const iq: QuestionInPage = {
    claim_id: claim_id,
    page_id: '',
    id: t.slug,
    priority: 1,
    question_id: t.slug,
    question_logic: t.logic,
    cp_question: {
      configuration: { ...t.configuration },
      created_at: new Date(),
      id: '',
      name: t.slug,
      questionnaire_id: '',
      response_type: t.response_type,
      slug: t.slug,
      statement: t.statement,
      answer: ans,
    },
  };

  return iq;
}

function evalHideMap(s: Section): HideMap {
  const ret: HideMap = {};
  console.log(
    'qtc => HM => init',
    s.questions.map((q) => q.question_logic)
  );
  s.questions.forEach((q, i) => {
    if (!q.question_logic || !q.question_logic.hide_if) {
      ret[i] = false;
    } else {
      console.log('qtc => HM => Running conditions for', { q, s });
      ret[i] = runConditions(q.question_logic.hide_if, { answers: s.answers });
    }
  });

  console.log('qtc => HM => Hide map', ret);

  return ret;
}
