import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Action } from 'src/app/core/models/action.model';
import { KycService } from 'src/app/core/services/kyc.service';
import { UserService } from 'src/app/core/services/user.service';
import { DocumentKycUploadModalComponent } from 'src/app/shared/components/simple/document-kyc-upload-modal/document-kyc-upload-modal.component';
import { DocumentUploadModalComponent } from 'src/app/shared/components/simple/document-upload-modal/document-upload-modal.component';

type KYCActionState =
  | 'LOADING'
  | 'BLOCKED'
  | 'PENDING'
  | 'IN_PROGRESS'
  | 'DONE_OK'
  | 'DONE_FAIL';
@Component({
  selector: 'app-oa-kyc',
  templateUrl: './oa-kyc.component.html',
  styleUrls: ['./oa-kyc.component.scss', '../oa-styles.scss'],
})
export class OaKycComponent {
  @Input() action!: Action;
  @Output() changed = new EventEmitter<boolean>();

  state: KYCActionState = 'LOADING';

  constructor(
    public dialog: MatDialog,
    private us: UserService,
    private ks: KycService
  ) {
    // this.uploadDialog();
  }

  async ngOnInit() {
    console.log('[OA] [KYC] init', this.action);
    this.state = await this.checkState();
    console.log('[OA] [KYC] state', this.state);
  }

  async uploadDialog() {
    const uid = (await this.us.getCurrentUser())?.id;

    if (!this.action) {
      throw new Error('No requirement loaded');
    }

    if (!uid) {
      throw new Error('No user logged in');
    }

    console.log('ACTION', this.action);
    const dialogData: {
      bucket: string;
      name: string;
      type: 'PERSONAL' | 'CLAIM';
      multiple: boolean;
      ref: string;
      provider: string;
    } = {
      bucket: 'client-documents',
      name: 'kyc.png',
      multiple: false,
      ref: uid,
      type: 'PERSONAL',
      provider: this.action.config.provider,
    };

    const dialogRef = this.dialog.open(DocumentKycUploadModalComponent, {
      data: dialogData,
      height: '50vh',
      minHeight: '500px',
      maxHeight: '700px',
      width: '90vw',
      maxWidth: '700px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      console.log('[OA] [KYC]', this);

      this.changed.emit(true);
    });
  }
  checkDependsOn() {
    return false;
    // this.action.description?.includes('depends on');
  }

  // Possible states
  /*
  Blocked
    action.prstatus !== 'DONE'

  Unblocked, pending
    action.prstatus == 'DONE'
    action.acstatus == null || action.acstatus == 'ERROR'

  Unblocked, in progress
    action.prstatus == 'DONE'
    action.acstatus == 'PROCESSING'


  Unblocked, done, OK
    action.prstatus == 'DONE'
    action.acstatus == 'DONE'
      -> Load done action id

  Unblocked, done, Fail
    action.prstatus == 'DONE'
      action.acstatus == 'DONE'
        -> Load done action id       

 */

  async checkState(): Promise<KYCActionState> {
    if (
      this.action.prstatus !== 'DONE' &&
      this.action.prstatus !== 'SUBMITTED'
    ) {
      return 'BLOCKED';
    }

    if (!this.action.ac_status || this.action.ac_status == 'ERROR') {
      return 'PENDING';
    }

    if (this.action.ac_status == 'PROCESSING') {
      return 'IN_PROGRESS';
    }

    // Will need to pull the result from the action.
    if (!this.action.done_action_id || this.action.ac_status !== 'DONE') {
      console.error(
        'Action should be in the done state and have a done_action_id ',
        this.action
      );
      throw new Error(
        'Action should be in the done state and have a done_action_id '
      );
    }
    const res = await this.ks.get(this.action.done_action_id!);

    if (res.passed) {
      return 'DONE_OK';
    }

    if (!res.passed) {
      return 'DONE_FAIL';
    }

    throw new Error(
      'Action is in an inconsistent state.' +
        JSON.stringify(this.action, () => {}, 4)
    );
  }
}
