import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-pre-init',
  templateUrl: './pre-init.component.html',
  styleUrls: ['./pre-init.component.scss'],
})
export class PreInitComponent {
  confirmed: boolean = false;

  @Output() go$: EventEmitter<void> = new EventEmitter<void>();
  init() {
    this.go$.emit();
  }
}
