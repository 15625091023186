import { Injectable } from '@angular/core';
import * as _ from 'lodash';

// Define a interface para o objeto de diferenças
interface Difference {
  value1: any;
  value2: any;
}

// Define a interface para o objeto de mudanças
type Changes = Record<string, Difference>;

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  /**
   * Compara profundamente dois objetos e retorna um objeto contendo as diferenças.
   * @param obj1 - Primeiro objeto para comparar
   * @param obj2 - Segundo objeto para comparar
   * @returns Um objeto que contém as diferenças entre os dois objetos fornecidos
   */
  getDeepDifferences(
    obj1: Record<string, any> | null | undefined,
    obj2: Record<string, any> | null | undefined
  ): Changes {
    const changes: Changes = {};

    /**
     * Função recursiva para comparar dois objetos.
     * @param o1 - Primeiro objeto para comparar
     * @param o2 - Segundo objeto para comparar
     * @param basePath - Caminho base para rastrear a profundidade da comparação
     */
    function compareObjects(
      o1: Record<string, any> | null | undefined,
      o2: Record<string, any> | null | undefined,
      basePath: string
    ): void {
      // Se qualquer um dos objetos é null ou undefined, registra a diferença e retorna
      if (o1 == null || o2 == null) {
        if (o1 !== o2) {
          changes[basePath] = { value1: o1, value2: o2 };
        }
        return;
      }

      // União de todas as chaves presentes em ambos os objetos
      const allKeys = _.union(_.keys(o1), _.keys(o2));

      for (const key of allKeys) {
        // Criação do caminho completo para a chave atual
        const path = basePath ? `${basePath}.${key}` : key;

        // Se ambas as chaves são objetos, realiza a comparação recursiva
        if (_.isObject(o1[key]) && _.isObject(o2[key])) {
          compareObjects(o1[key], o2[key], path);
        }
        // Se os valores não são iguais, armazena a diferença
        else if (!_.isEqual(o1[key], o2[key])) {
          changes[path] = { value1: o1[key], value2: o2[key] };
        }
      }
    }

    // Inicia a comparação com os objetos fornecidos
    compareObjects(obj1, obj2, '');

    return changes;
  }
}
