import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {Case} from 'src/app/core/models/case';

@Component({
  selector: 'app-case-teaser-card',
  templateUrl: './case-teaser-card.component.html',
  styleUrls: ['./case-teaser-card.component.scss'],
})
export class CaseTeaserCardComponent implements OnInit {
  @Input() public case?: Partial<Case>;
  @Input() public action_types: { name: string; slug: string }[] = [];

  ngOnInit(): void {
  }

  getCaseTypeName(slug: string | undefined): string {
    if (!slug) {
      return "Category";
    }
    const type = this.action_types.find(item => item.slug === slug);
    return type ? type.name : "Category";
  }

  removeHtmlTags(content: string): string {
    const div = document.createElement('div');
    div.innerHTML = content;
    return div.textContent || div.innerText || '';
  }

}
