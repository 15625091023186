import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      titleIcon: string;
      title: string;
      message: string;
      confirmText: string;
      cancelButton: string;
    } = {} as any
  ) {}

  onConfirm() {
    this.dialogRef.close(true);
  }
  onDismiss() {
    this.dialogRef.close(false);
  }
}
