import { Injectable } from '@angular/core';
import { Claim_Document } from '../models/claim_document.model';
import { DocumentService } from './base/document.service';
import { v4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class ClaimDocumentService extends DocumentService<Claim_Document> {
  constructor() {
    super('cp_claim_document');
  }

  public async upload(
    f: File,
    claim_id: string,
    name: string,
    bucket: string,
    seq?: number
  ) {
    const ext = f.name.split('.').pop();

    const id = v4();
    const url =
      seq === undefined
        ? `${claim_id}/${id}.${ext}`
        : `${claim_id}/${id}/${seq}.${ext}`;
    const pd: Claim_Document = {
      bucket,
      doc_type: name,
      kv_extract: {},
      ocr_extract: {},
      status: 'PRE_UPLOAD',
      url,
      claim_id: claim_id,
      created_at: new Date(),
      id,
    };

    const ulret = await this.uploadDocument(pd, await f.arrayBuffer());

    if (ulret.error || !ulret.data) {
      return { data: null, error: ulret.error };
    }

    return {
      data: {
        doc: pd,
        path: ulret.data.path,
      },
      error: null,
    };
  }
}
