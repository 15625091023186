import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  hasNotificationSubject = new BehaviorSubject<boolean>(false);
  constructor() {}

  setHasNotification(value: boolean) {
    return this.hasNotificationSubject.next(value);
  }

  getHasNotification(): Observable<boolean> {
    return this.hasNotificationSubject.asObservable();
  }
}
