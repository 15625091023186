import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Question } from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';

import { FormControl } from '@angular/forms';
import * as _moment from 'moment';
import { Moment } from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-question-month-year',
  templateUrl: './question-month-year.component.html',
  styleUrls: ['./question-month-year.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class QuestionMonthYearComponent implements OnInit {
  @Input('question') question!: Question;
  @Input() monthYear = false;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  date = new FormControl();

  min!: Date;
  max!: Date;

  ngOnInit(): void {
    if (this.question.answer!.value) {
      this.date.setValue(_moment(this.question.answer!.value));
      this.handleChangeOnType();
    }

    if (this.question.configuration) {
      const config = this.question.configuration;

      if (config.min) {
        this.min = new Date(config.min);
      }

      if (config.max) {
        this.max = new Date(config.max);
      }
    }
  }

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const m = normalizedMonthAndYear.month() + 1;
    const y = normalizedMonthAndYear.year();

    console.log('Change On set 1' + new Date(), {
      normalizedMonthAndYear,
      datepicker,
      m,
      y,
      date: this.date,
    });
    const ctrlValue = this.date.value || _moment(new Date());
    console.log('Change On set 2', {
      ctrlValue,
      moment_is_valid: _moment(this.question.answer!.value).isValid(),
      toBeChanged: _moment(this.question.answer!.value)?.toDate()?.toString(),
    });

    ctrlValue?.month(normalizedMonthAndYear.month());
    ctrlValue?.year(normalizedMonthAndYear.year());

    console.log('Change On set 2', {
      ctrlValue,
      moment_is_valid: _moment(this.question.answer!.value).isValid(),
      toBeChanged: _moment(this.question.answer!.value)?.toDate()?.toString(),
    });
    this.question.answer!.value = ctrlValue;
    this.question.isValid = _moment(this.question.answer!.value).isValid();
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.change(ctrlValue?.toString());
  }

  handleChangeOnType() {
    const ctrlValue = this.date.value;
    console.log('Change On Type', {
      ctrlValue,
      moment_is_valid: _moment(this.question.answer!.value).isValid(),
      toBeChanged: _moment(this.question.answer!.value)?.toDate()?.toString(),
    });
    this.question.answer!.value = ctrlValue;
    this.question.isValid = _moment(this.question.answer!.value).isValid();
    this.change(_moment(this.question.answer!.value)?.toDate()?.toString());
  }

  change(value: string) {
    this.question.isValid = this.checkValid(value);

    this.changeAnswer.next({ answer: { value }, question: this.question });
  }

  checkValid(value?: string): boolean {
    if (!this.question.configuration?.required && !value) {
      return true;
    }

    if (this.question.configuration.required && !value) {
      return false;
    }

    return true;
  }
}
