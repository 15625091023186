import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent {
  imageData;
  constructor(
    public dialogRef: MatDialogRef<DocumentPreviewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string;
      imageData: {
        name: string;
        src: string;
      };
    }
  ) {
    this.imageData = data.imageData;
    console.log('data', data);
  }
}
