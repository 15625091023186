import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  type OnInit,
} from '@angular/core';
import { Column, TableSettings } from '../../table.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Filter } from 'src/app/core/services/base/database.service';
import { DynamicService } from 'src/app/core/services/base/dynamic.service';

@Component({
  selector: 'app-filter-options',
  templateUrl: './filter-options.component.html',
  styleUrls: ['./filter-options.component.scss'],
})
export class FilterOptionsComponent implements OnInit, OnDestroy, OnChanges {
  @Input('column') column!: Column;
  @Input('settings') settings!: TableSettings;
  @Output('columnChanges') columnChanges: EventEmitter<Column> =
    new EventEmitter();

  currentColumn!: Column;
  value: any;
  values: {
    label: string;
    value: string;
  }[] = [];

  debouncer$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  subscriptions: Subscription[] = [];

  filters: Filter[] = [];
  constructor(private sbs: DynamicService<any>) {}

  ngOnInit(): void {
    console.log('Filter-Text', this);
    if (this.column.filters[0]) {
      this.currentColumn = this.column;
    }

    this.sbs.load(this.settings.entity);
    this.sbs.getAllDistinct('author_display_name').then((data) => {
      console.log('Distinct values', data);
    });

    // Load distinct values
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('Filter-Text changes', changes);
    if (changes['column'] && changes['column'].currentValue) {
      this.currentColumn = changes['column'].currentValue;
      // Atualizar filtros com a nova coluna, se necessário
      if (this.currentColumn.filters) {
        this.filters = this.currentColumn.filters;
      } else {
        this.filters = [];
      }
    }
  }

  getOperatorLabel(op: string): string {
    return this.values.find((o) => o.value === op)?.label || '';
  }

  clearFilter() {
    this.filters = [];
    this.columnChanges.emit({ ...this.currentColumn, filters: this.filters });
  }
  addFilter() {
    // this.filters.push({
    //   column: this.column.id,
    //   operator: 'in',
    //   value: this.text,
    //   visible: false,
    // });
    // this.values = 'eq';
    // this.text = '';
    // this.columnChanges.emit({ ...this.currentColumn, filters: this.filters });
  }
  removeFilter(filter: number) {
    this.filters.splice(filter, 1);
    this.columnChanges.emit({ ...this.currentColumn, filters: this.filters });
  }

  ngOnDestroy(): void {}
}
