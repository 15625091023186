/// <reference types="@types/google.maps" />

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';
import { Question } from 'src/app/core/models/questionnaire.model';
import { UKAddressInfo } from '../../../../core/models/uk_address';
import { AddressService } from '../../../../core/services/address.service';
import { MaterialModule } from '../../../modules/material/material-module.module';
import { QuestionAnswer } from '../../simple/question/question.component';

@Component({
  selector: 'app-question-uk-address',
  templateUrl: './question-uk-address.component.html',
  styleUrls: ['./question-uk-address.component.scss'],
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
})
export class QuestionUKAddressComponent implements OnInit, OnDestroy {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  addressList: { value: string; info: UKAddressInfo }[] = [];
  addressForm = new FormGroup({
    address: new FormControl<string>(''),
    additional_info: new FormControl(),
  });

  callAPI: Subject<any> = new Subject();

  loading = false;

  googleAutocomplete = new google.maps.places.AutocompleteService();

  constructor(public zone: NgZone, private addressService: AddressService) {}

  ngOnInit(): void {
    if (this.question.answer && this.question.answer.value) {
      this.addressForm.controls.address.setValue(
        this.question.answer.value.toString()
      );
      this.addressForm.controls.additional_info.setValue(
        this.question.answer.addtional_information
      );
    }
    this.change();

    if (!this.question.elective) {
      this.addressForm.controls.address.setValidators(Validators.required);
      this.addressForm.controls.additional_info.setValidators(
        Validators.required
      );
    }

    this.callAPI.pipe(debounceTime(1000)).subscribe(() => {
      if (this.addressForm.controls.address.value) {
        this.addressForm.controls.additional_info.reset();
        this.updateSearchResults(
          this.addressForm.controls.address.value.trim()
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.callAPI.unsubscribe();
  }

  doSearch() {
    this.loading = true;
    this.callAPI.next(null);
  }

  async updateSearchResults(searchValue: string) {
    this.loading = true;

    if (!searchValue) {
      this.addressList = [];
      this.loading = false;
      return;
    }

    try {
      this.addressList = [];
      const res = await this.addressService.searchUKAddress(searchValue);

      res.Address.data.forEach((res) => {
        const addInfo = res.addtional_information[0];
        const pafInfo = res.pafInfo[0];
        const parts = [
          pafInfo['Street Number'],
          pafInfo.Street,
          addInfo['Ward Name'],
          addInfo['District Name'],
          addInfo['Country Name'],
        ];
        const value = parts.filter(Boolean).join(', ');
        this.addressList.push({ value, info: res });
        this.change();
        this.loading = false;
      });
    } catch (error) {
      console.error('Question UK Address -> Error', error);
      this.addressList = [];
      this.loading = false;
    }
  }

  selectAddress(addressSelected: UKAddressInfo) {
    this.addressForm.controls.additional_info.setValue({
      ...addressSelected.pafInfo[0],
      ...addressSelected.addtional_information[0],
    });
    this.change();
  }

  change() {
    this.question.isValid = this.addressForm.valid;
    this.question.answer!.value = this.addressForm.controls.address.value;
    this.question.answer!.addtional_information =
      this.addressForm.controls.additional_info.value;

    console.log(`${this.question.name} valid: `, this.question.isValid);
    this.changeAnswer.next({
      answer: this.question.answer!,
      question: this.question,
    });
  }
}
