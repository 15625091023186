import {CommonModule} from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  type OnInit,
} from '@angular/core';
import {News} from 'src/app/core/models/news';

@Component({
  selector: 'card-news',
  templateUrl: './card-news.component.html',
  styleUrls: ['./card-news.component.scss'],
})
export class CardNewsComponent implements OnInit, AfterViewInit {
  @Input() news: Partial<News> | undefined;
  @Input() showNoImage: boolean = false;
  @Input() isDark: boolean = false;
  @Input() isPreview: boolean = false;
  @Input() forceVertical: boolean = false;

  @Output() clickNews = new EventEmitter<News>();
  @ViewChild('newsContainer') newsElement: ElementRef | undefined;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    console.log('check 2', this.checkConditionStyle());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    console.log('check resize', this.checkConditionStyle());
  }

  getFirstSentence(content: string) {
    if (!content) return '';

    const sentences = content.split('.');
    return sentences[0];
  }

  readMore(news: any) {
    this.clickNews.emit(news);
  }

  checkConditionStyle() {
    if (!this.newsElement) return;
    const width = this.newsElement.nativeElement.offsetWidth;

    if (width < 500) {
      this.newsElement.nativeElement.classList.add('vertical');
      this.newsElement.nativeElement.classList.remove('horizontal');
    } else {
      this.newsElement.nativeElement.classList.add('horizontal');
      this.newsElement.nativeElement.classList.remove('vertical');
    }
  }
}
