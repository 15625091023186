import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ThreadWithMessages } from 'src/app/core/services/communication.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-toolbar-search',
  templateUrl: './toolbar-search.component.html',
  styleUrls: ['./toolbar-search.component.scss'],
})
export class ToolbarSearchComponent implements OnInit {
  @ViewChild('icon', { static: true }) iconNotification!: ElementRef;
  @Output() toggleSideNavEvent = new EventEmitter();
  @Input() public notifications$?: Observable<ThreadWithMessages[]>;
  @Input() isSidenavOpen: boolean = false;
  notifications?: ThreadWithMessages[];

  profileName = '-';
  profileImg: string | null = null;
  constructor(private us: UserService, private ss: StorageService) {}

  ngOnInit(): void {
    const a = this.notifications$?.subscribe((ev) => {
      this.notifications = ev;
      // console.log('Notify Triggered', ev);
    });

    this.us.getCurrentUser().then((u) => {
      // Ckech user is logged

      console.log('User', u);

      if (u && u.avatar_url) {
        const imgUrl = `profile-pictures/${u.id}/${u.avatar_url}`;
        const bucket = 'profile-pictures';
        const path = `${u.id}/${u.avatar_url}`;

        console.log('Image URL', imgUrl);

        console.log('Bucket', bucket);
        console.log('Path', path);
        this.ss
          .signedUrl(bucket, path)
          .then((url) => {
            console.log('URL', url);
            if (url.error) {
              console.error('Error getting URL', url.error);
              return;
            }

            if (!url.data) {
              console.error('Error getting URL', url);
              return;
            }

            if (!url.data.signedUrl) {
              console.error('Error getting URL', url);
              return;
            }

            this.profileImg = url.data.signedUrl;
          })
          .catch((err) => {
            console.error('Error getting URL', err);
          });

        return;
      }

      if (!u || !u.display_name) {
        this.profileName = '-';
      } else {
        // Get the First Letter by each word splitted by space and limit to 2.
        // Always get the the first and the last letter
        // Example: John Doe Mark => JM

        if (!u) {
          this.profileName = '-';
          return;
        }

        if (!u.display_name) {
          this.profileName = '-';
          return;
        }

        if (!u.display_name.trim()) {
          this.profileName = '-';
          return;
        }
        if (u.display_name === 'undefined') {
          this.profileName = '-';
          return;
        }

        const nameSplits = u.display_name.split(' ');
        const firstName = nameSplits[0];
        const lastName =
          nameSplits.length > 1 ? nameSplits[nameSplits.length - 1] : null;

        const firstLetter = firstName[0];
        const lastLetter = lastName ? lastName[0] : '';

        this.profileName = `${firstLetter}${lastLetter}`;
      }
      console.log('Profile Text', this.profileName);
    });
  }

  logout() {
    localStorage.removeItem('userRole');
    this.us.signOut();
  }

  toggleSideNav() {
    this.toggleSideNavEvent.emit();
  }
}
