import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Contract } from 'src/app/core/models/contract';
import { EventTypes } from 'src/app/core/models/events.model';
import { EventService } from 'src/app/core/services/event.service';
import {
  applyTemplate,
  saveData,
} from 'src/app/core/services/fileTemplater.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-doc-bundle',
  templateUrl: './doc-bundle.component.html',
  styleUrls: ['./doc-bundle.component.scss'],
})
export class DocBundleComponent implements OnInit {
  @Input() public contract?: Contract;
  @Output() valid = new EventEmitter<boolean>();

  state: { [id: string]: boolean } = {};

  constructor(
    private es: EventService,
    private http: HttpClient,
    private ss: StorageService,
    private us: UserService
  ) {}

  ngOnInit(): void {
    this.valid.emit(this.checkvalid());
  }

  async download(doc: {
    name: string;
    path: string;
    download_required: boolean;
    templated?: boolean;
  }) {
    this.state[doc.name] = true;
    this.valid.emit(this.checkvalid());
    console.log('DOC', doc);
    if (doc.templated) {
      console.log('TEMPLATED');
      const { data: file, error } = await this.ss.download(
        'contracts',
        `${this.contract?.id}/${doc.path}`
      );

      if (error) {
        console.error('Error downloading file', error);
        return;
      }

      if (!file) {
        console.error('File not found');
        return;
      }

      const pii = await this.us.getWithPII(
        (
          await this.us.getCurrentUser()
        )?.id!
      );

      console.log('pii', pii);

      console.log('file', file);
      const data = await this.getData();

      applyTemplate(await file.arrayBuffer(), data, doc.path);
    } else {
      console.log('NO TEMPLATED');
      const { data: file, error } = await this.ss.download(
        'contracts',
        `${this.contract?.id}/${doc.path}`
      );

      if (error) {
        console.error('Error downloading file', error);
        return;
      }

      if (!file) {
        console.error('File not found');
        return;
      }

      saveData(file, doc.path);
    }
    this.es.pushNew(EventTypes.DOCUMENT_DOWNLOAD, doc);
  }

  checkvalid(): boolean {
    const docs = this.contract?.configuration.bundle || [];

    for (let i = 0; i < docs.length; i++) {
      const doc = docs[i];

      if (!(this.state[doc.name] || !doc.download_required)) {
        return false;
      }
    }

    return true;
  }

  async getData(): Promise<ContractData> {
    const cu = await this.us.getCurrentUser();
    if (!cu) {
      throw new Error('User is not logged in.');
    }

    const u = await this.us.getWithPII(cu.id!);

    if (!u.pii) {
      throw new Error('User does not have PII');
    }

    if (!u.pii.attributes) {
      throw new Error('User does not have PII Attributes');
    }

    const fa = this.formatAddress(u.pii.primary_address);

    const name =
      (u.pii.attributes.first_name || '') +
      ' ' +
      (u.pii.attributes.middle_name || '') +
      ' ' +
      (u.pii.attributes.last_name || '').replace(/\s+/g, ' ').trim();

    const data: ContractData = {
      title: u.pii.attributes.title || '', // TODO: Add title
      signature: '', // TODO: Add signature
      name,
      firstName: u.pii.attributes.first_name,
      lastName: u.pii.attributes.last_name,
      addressLine1: fa.addressLine1,
      addressLine2: fa.addressLine2,
      city: fa.city,
      country: fa.country,
      postcode: fa.postalCode,
      'agreement.dateAgreed': new Date().toLocaleDateString(),
      'signed.date': new Date().toLocaleDateString(),
    };

    console.log('[DOC BUNDLE] DATA', data);

    return data;
  }

  formatAddress({
    streetNumber = '',
    streetName = '',
    buildingName = '',
    unitNumber = '',
    neighborhood = '',
    city = '',
    state = '',
    county = '',
    country = '',
    postalCode = '',
    postcode = '',
  }: AddressInput): FormattedAddress {
    // Concatenate streetNumber and streetName for addressLine1
    const addressLine1 = [streetNumber, streetName].filter(Boolean).join(' ');

    // Concatenate buildingName, unitNumber, and neighborhood for addressLine2
    const addressLine2 = [buildingName, unitNumber, neighborhood]
      .filter(Boolean)
      .join(', ');

    return {
      addressLine1,
      addressLine2,
      city,
      state,
      county,
      country,
      postalCode: postalCode || postcode || '',
    };
  }
}

interface ContractData {
  title: string;
  signature: string;
  name: any;
  firstName: any;
  lastName: any;
  addressLine1: any;
  addressLine2: any;
  city: any;
  country: any;
  postcode: any;
  'agreement.dateAgreed': string;
  'signed.date': string;
}

interface FormattedAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  county: string;
  country: string;
  postalCode: string;
}

interface AddressInput {
  streetNumber?: string;
  streetName?: string;
  buildingName?: string;
  unitNumber?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  county?: string;
  country?: string;
  postalCode?: string;
  postcode?: string;
}
