import { Component, OnInit } from '@angular/core';
import { users, user_files } from 'src/app/core/mock/users';
import { User, UserFiles } from 'src/app/core/models/user.model';
import { LegalInformationComponent } from '../../shared/smart/legal-information/legal-information.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  user!: User;

  tabActive: 'edit' | 'docs' | 'sec' | 'notification-preferencies' = 'edit';

  constructor(private actRoute: ActivatedRoute) {}
  ngOnInit(): void {
    let params: string | null = this.actRoute.snapshot.paramMap.get('tab');
    console.log(params);

    if (
      params !== 'edit' &&
      params !== 'docs' &&
      params !== 'sec' &&
      params !== 'notification-preferencies'
    ) {
      params = null;
    }

    if (params) {
      this.tabActive =
        (params as 'edit' | 'docs' | 'sec' | 'notification-preferencies') ||
        'edit';
    }
  }

  changeTab(tab: 'edit' | 'docs' | 'sec' | 'notification-preferencies') {
    this.tabActive = tab;
  }
}
