import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-recovery-new-password',
  templateUrl: './recovery-new-password.component.html',
  styleUrls: ['./recovery-new-password.component.scss'],
})
export class RecoveryNewPasswordComponent implements OnInit {
  isLoading = false;

  form: FormGroup<any> = new FormGroup({});
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private _matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.form = this.fb.group(
      {
        password: ['', [Validators.required, passwordMatchPattern()]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validators: passwordMatchValidator(),
      }
    );
  }

  getErrorMessage(input: string): string {
    const control = this.form.get(input);
    let message = '';

    if (this.form.invalid && control && control.errors && control.touched) {
      if (control.errors['required']) {
        message = 'This field is required.';
      } else if (control.errors['minLength']) {
        message = 'Password must be at least 12 characters long.';
      } else if (control.errors['lowercase']) {
        message = 'Password must contain at least one lowercase letter.';
      } else if (control.errors['uppercase']) {
        message = 'Password must contain at least one uppercase letter.';
      } else if (control.errors['number']) {
        message = 'Password must contain at least one number.';
      } else if (control.errors['specialChar']) {
        message = 'Password must contain at least one special character.';
      }
    } else {
      message = '';
    }
    if (
      this.form.invalid &&
      control &&
      control.touched &&
      input === 'confirmPassword' &&
      this.form!.errors &&
      this.form!.errors!['passwordMismatch']
    ) {
      message = 'Passwords do not match.';
    }

    return message;
  }

  async submit() {
    try {
      this.isLoading = true;

      const password = this.form.get('password')?.value;
      if (!password) throw Error('Invalid Password');

      const { error } = await this.userService.updatePassword(password);

      if (error) throw error;

      this.onSubmit.emit();
    } catch (error: any) {
      console.error(error);
      const message =
        error.message || error.msg || 'An error occurred. Please try again.';

      this._matSnackBar.open(message, 'OK', {
        duration: 5000,
      });
    } finally {
      this.isLoading = false;
    }
  }
}

export function passwordMatchValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const newPassword = formGroup.get('password')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;

    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      // Se as senhas não combinam, adiciona um erro ao campo confirmPassword
      return { passwordMismatch: true };
    }
    // Se as senhas combinam, retorna null (sem erro)
    return null;
  };
}

export function passwordMatchPattern(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    const errors: ValidationErrors = {};

    if (!value) {
      return null; // Early return if no value
    }

    // Check for at least 8 characters
    if (value.length < 12) {
      errors['minLength'] = true;
    }

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(value)) {
      errors['lowercase'] = true;
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(value)) {
      errors['uppercase'] = true;
    }

    // Check for at least one number
    if (!/[0-9]/.test(value)) {
      errors['number'] = true;
    }

    // Check for at least one special character
    if (!/[^a-zA-Z0-9]/.test(value)) {
      errors['specialChar'] = true;
    }

    // If there are any errors, return them
    if (Object.keys(errors).length > 0) {
      return errors;
    }

    return null; // Return null if no errors
  };
}
