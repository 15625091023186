import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/core/models/user.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  user$!: Observable<User | null>;
  multiClaims: boolean = false;

  ngOnInit(): void {}

  handleMultipleClaims(multi: boolean) {
    if (multi) this.multiClaims = true;
  }
}
