import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-mfa-enroll',
  templateUrl: './mfa-enroll.component.html',
  styleUrls: ['./mfa-enroll.component.scss'],
})
export class MfaEnrollComponent {
  confirmed: boolean = false;
  step: 'before-init' | 'qrcode' | 'code-verify' | 'success' | 'error' =
    'before-init';

  factor: {
    targetId: string;
    challengeId: string;
    token: string;
  } = {
    targetId: '',
    challengeId: '',
    token: '',
  };
  constructor(
    public dialogRef: MatDialogRef<MfaEnrollComponent>,
    private userService: UserService
  ) {}

  changeStep(
    step: 'before-init' | 'qrcode' | 'code-verify' | 'success' | 'error'
  ) {
    this.step = step;
  }

  async getTargetId(id: string) {
    console.log('Target ID:', id);
    this.factor.targetId = id;

    const challengeId = await this.userService.challengeMFAEnroll(id);
    console.log('Challenge ID:', challengeId);
    this.factor.challengeId = challengeId;

    console.log('Factor:', this.factor);

    this.changeStep('code-verify');
  }

  async verify(code: string) {
    this.factor.token = code;

    const isValid = await this.userService.verifyMFAEnroll(
      this.factor.targetId,
      this.factor.challengeId,
      this.factor.token
    );

    console.log('Is Valid:', isValid);
    if (isValid) {
      this.changeStep('success');
    } else {
      this.changeStep('error');
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
