import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { EventTypes } from 'src/app/core/models/events.model';
import { EventService } from 'src/app/core/services/event.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  email!: string;
  password!: string;
  form!: FormGroup;
  isLoading!: boolean;

  mlform!: FormGroup;

  // MFA
  mfaNeeded: boolean = false;
  mfaForm!: FormGroup;
  emailCodeForm!: FormGroup;
  magicLinkCodeForm!: FormGroup;
  magiclink!: string;
  factorId!: string;
  magicLinkCodeNeeded: boolean = false;

  // Email Code
  emailCodeNeeded: boolean = false;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private toastService: ToastService,
    private es: EventService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      // TODO: remove when finish
      email: ['', [Validators.required, Validators.email]],
      magiclink: ['', [Validators.email]],
      password: ['', [Validators.required]],
    });

    this.mfaForm = this.fb.group({
      code: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
    });

    this.emailCodeForm = this.fb.group({
      emailCode: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
    });

    this.magicLinkCodeForm = this.fb.group({
      magicLinkCode: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
    });
  }

  async onSubmit() {
    this.isLoading = true;
    try {
      if (!this.form.valid) {
        throw Error('Invalid Email and password');
      }

      const email = this.form.get('email')?.value;
      this.email = email;
      const password = this.form.get('password')?.value;
      this.password = password;
      const { data, error } = await this.userService.signIn(email, password);

      console.log('AuthError', error?.message);

      if (error && error.message == 'Email not confirmed') {
        this.emailCodeNeeded = true;
      }
      const { session } = data;
      // Check if this user needed mfa code
      console.log(session);
      console.log(session?.user?.factors);

      const mfaNeeded =
        session?.user?.factors &&
        session?.user?.factors.filter((e) => e.status === 'verified').length >
          0;
      // if yes
      if (mfaNeeded) {
        // Hide login form and show MFA form
        if (!session) throw Error('No session found');
        if (!session.user) throw Error('No user found');
        if (!session.user.factors) throw Error('No factors found');
        if (!session.user.factors[0]) throw Error('No factor found');

        this.mfaNeeded = true;
        this.factorId = session.user.factors[0].id;
      } else {
        // Redirect to client page
        this.router.navigateByUrl('/client');
      }

      if (error) {
        throw Error(error.message);
      }
    } catch (error: any) {
      this.toastService.error(error.message);
    } finally {
      this.isLoading = false;
    }
  }

  sendMagicLink() {
    this.userService.sendMagicLinkEmail(this.form.get('magiclink')?.value);
    this.magiclink = this.form.get('magiclink')?.value;
    this.magicLinkCodeNeeded = true;
  }

  async submitMfa() {
    try {
      this.isLoading = true;

      if (!this.mfaForm.valid) {
        throw Error('Invalid MFA code');
      }

      const code = this.mfaForm.get('code')?.value;
      if (!this.factorId) throw Error('No factorId found');
      if (!code) throw Error('No code found');

      const data = await this.userService.verifyMFACode(this.factorId, code);

      console.log('Verify MFA Data: ', data);

      this.router.navigateByUrl('/client');

      this.onSubmit();
    } catch (error: any) {
      this.toastService.error(error.message || error.msg || 'Error');
    } finally {
      this.isLoading = false;
    }
  }

  async submitCode() {
    try {
      this.isLoading = true;

      if (!this.emailCodeForm.valid) {
        throw Error('Invalid MFA code');
      }

      const code = this.emailCodeForm.get('emailCode')?.value;

      console.log('code', code);

      const data = await this.userService.verifyEmail(this.email, code);

      console.log('Verify MFA Data: ', data);

      this.router.navigateByUrl('/client');
    } catch (error: any) {
      this.toastService.error(error.message || error.msg || 'Error');
    } finally {
      this.isLoading = false;
    }
  }

  async submitMagicLink() {
    try {
      this.isLoading = true;

      if (!this.magicLinkCodeForm.valid) {
        throw Error('Invalid Magic Link code');
      }

      const code = this.magicLinkCodeForm.get('magicLinkCode')?.value;

      console.log('code', code);

      const data = await this.userService.logInWithOTP(this.magiclink, code);

      console.log('Verify MFA Data: ', data);

      this.router.navigateByUrl('/client');
    } catch (error: any) {
      this.toastService.error(error.message || error.msg || 'Error');
    } finally {
      this.isLoading = false;
    }
  }
}
