import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { News } from 'src/app/core/models/news';
import { NewsService } from 'src/app/core/services/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  news: News[] = [];
  otherNews: News[] = [];
  loadingNews = true;

  constructor(private ns: NewsService, private router: Router) {}

  async ngOnInit(): Promise<void> {
    try {
      this.news = await this.ns.getNewsByNewest({
        limit: 3,
        order: 'desc',
        orderBy: 'created_at',
      });
      this.otherNews = await this.ns.getNewsByNewest({
        limit: 3,

        order: 'desc',
        orderBy: 'created_at',
      });

      console.log('NEWS', this.news);
      console.log('OTHER NEWS', this.otherNews);
    } catch (error) {
    } finally {
      this.loadingNews = false;
    }
  }

  goToNews(news: News) {
    this.router.navigate([`client/news/${news.id}`]);
  }
}
