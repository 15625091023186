import {
  NgSignaturePadOptions,
  SignaturePadComponent,
} from '@almothafar/angular-signature-pad';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ViewChild,
  Inject,
  HostListener,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-signature-pad-electronic-assignature',
  templateUrl: './signature-pad-electronic-assignature.component.html',
  styleUrls: ['./signature-pad-electronic-assignature.component.scss'],
})
export class SignaturePadElectronicAssignatureComponent
  implements AfterViewInit
{
  @Output() backEventEmitter = new EventEmitter();
  @Output() signEventEmitter = new EventEmitter();
  @Output() termsEventEmiiter = new EventEmitter();
  data: any;
  @ViewChild('signature', { static: true })
  public signaturePad!: SignaturePadComponent;
  signaturePadOptions: NgSignaturePadOptions = {
    // passed through to szimek/signature_pad constructor
    canvasHeight: 300,
    canvasWidth: 500,
    minWidth: 5,
  };
  checkbox = false;
  disabled = true;
  loading = false;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngAfterViewInit(): void {
    this.signaturePad.set(
      'canvasWidth',
      this.document.getElementById('content-signature')?.clientWidth
    );
    this.signaturePad.set(
      'canvasHeight',
      this.document.getElementById('content-signature')?.clientHeight
    );

    (
      this.document.getElementById('signature-component') as HTMLElement
    ).style.display = 'flex';
    (
      this.document.getElementById('signature-component') as HTMLElement
    ).style.justifyContent = 'center';
  }

  drawComplete(event: MouseEvent | Touch) {
    this.data = this.signaturePad.toDataURL();
    this.disabled = false;
  }

  drawStart(event: MouseEvent | Touch) {}

  drawClear() {
    this.signaturePad.clear();
    this.data = null;
  }

  doSignDocument() {
    this.loading = true;
    this.signEventEmitter.emit(this.data);
  }
}
