import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Question } from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';

@Component({
  selector: 'app-question-select',
  templateUrl: './question-select.component.html',
  styleUrls: ['./question-select.component.scss'],
})
export class QuestionSelectComponent implements OnInit {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  constructor() {
    console.log('QUESTION', this);
  }

  ngOnInit(): void {
    this.change();
  }

  change() {
    setTimeout(() => {
      console.log('change', this.question.answer!.value);
      this.question.isValid = !!this.question.answer!.value;
      this.changeAnswer.next({
        answer: this.question.answer!,
        question: this.question,
      });
    }, 10);
  }
}
