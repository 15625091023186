import { Component } from '@angular/core';

@Component({
  selector: 'app-typograph',
  templateUrl: './typograph.component.html',
  styleUrls: ['./typograph.component.scss']
})
export class TypographComponent {

}
