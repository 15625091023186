import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-mfa-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit {
  qrcode: string | null = null;
  secret: string | null = null;
  targetId: string | null = null;

  showSecret: boolean = false;

  @Output() go$: EventEmitter<{
    targetId: string;
  }> = new EventEmitter();

  constructor(
    private userService: UserService,
    private _snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    await this.checkAndUnerollAll();

    const { qrcode, secret, id } = await this.userService.initMFAEnroll();
    this.targetId = id;
    this.qrcode = qrcode;
    this.secret = secret;
  }

  async checkAndUnerollAll() {
    const { data, error } = await this.userService.listMFAEnrolls();
    console.log('All MFA Enrolls:', data);

    if (error) throw new Error(error.message);
    if (!data) throw new Error('No data returned');

    for (const enroll of data.all) {
      await this.userService.removeFactorMFAEnroll(enroll.id);
    }
  }

  toggleSecret() {
    this.showSecret = !this.showSecret;
  }

  copySecret() {
    navigator.clipboard.writeText(this.secret || '');
    const message = 'Message copied to clipboard';
    this._snackBar.open(message, 'Close', {
      duration: 2000,
    });
  }
}
