import { Component } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Route,
  Router,
} from '@angular/router';

@Component({
  selector: 'app-questionnaire-answer-client',
  templateUrl: './questionnaire-answer-client.component.html',
  styleUrls: ['./questionnaire-answer-client.component.scss'],
})
export class QuestionnaireAnswerClientComponent {
  claimId?: string;
  surveyId?: string;
  constructor(private route: ActivatedRoute, private router: Router) {
    console.log('ROUTE', this.route.snapshot.paramMap);

    const cid = this.route.snapshot.paramMap.get('cid');
    if (cid) {
      this.claimId = cid;
    }

    const sid = this.route.snapshot.paramMap.get('qcode');
    console.log('sid', sid);
    if (sid) {
      this.surveyId = sid;
    }
  }

  onComplete() {
    this.router.navigateByUrl('/client/claim/' + this.claimId);
  }
}
