import { Component } from '@angular/core';

@Component({
  selector: 'example-questionnaire-answer',
  templateUrl: './questionnaire-answer.component.html',
  styleUrls: ['./questionnaire-answer.component.scss'],
})
export class QuestionnaireAnswerComponent {
  cid: string = 'b016dd10-ebbd-4ea7-aed3-b2a0fd14fad8';
  sid: string = 'a4aad8d5-6074-4b88-b3f7-042c6e5a754a';
}
