import { Injectable, inject } from '@angular/core';
import { Base } from '../../models/base.model';
import { DatabaseService } from './database.service';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { DocumentType } from '../../models/document.model';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export abstract class DocumentService<
  T extends DocumentType
> extends DatabaseService<T> {
  private http: HttpClient = inject(HttpClient);

  constructor(table: string) {
    super(table);
  }

  public async getDownloadURL(docval: T) {
    const b = await this.supabase.storage
      .from(docval.bucket)
      .createSignedUrl(docval.url, 3600, {
        download: true,
      });

    const a = b.data?.signedUrl;
    console.log('a', a);

    if (!b.data) {
      console.log('Error getting URL', b);
      console.log('Requested URL', docval.bucket + '/' + docval.url);
      throw new Error('Could not get signed url');
    }
    return a!;
  }

  protected async uploadDocument(docval: T, bytes: ArrayBuffer) {
    try {
      console.log('DOCVAL', docval);
      console.log('BUCKET', docval.bucket);
      console.log('URL', docval.url);

      await this.create(docval);

      const a = await this.supabase.storage
        .from(docval.bucket)
        .upload(docval.url, bytes, { contentType: 'application/octet-stream' });

      docval.status = a.data ? 'UPLOADED' : 'ERROR_UPLOAD';
      await this.update(docval);

      return a;
    } catch (error) {
      console.error('Error uploading document', error);
      (error as any).then((e: any) =>
        console.error('Error uploading document = Promise', e)
      );
      throw error;
    }
  }
  protected async uploadDocumentV2(docval: T, file: File) {
    try {
      console.log('DOCVAL v2', docval);
      console.log('BUCKET v2 ', docval.bucket);
      console.log('URL v2', docval.url);

      await this.create(docval);
      const aBuffer = await file.arrayBuffer();

      const a = await this.supabase.storage
        .from(docval.bucket)
        .upload(docval.url, aBuffer, { upsert: true, contentType: file.type });

      docval.status = a.data ? 'UPLOADED' : 'ERROR_UPLOAD';
      await this.update(docval);

      return a;
    } catch (error) {
      console.error('Error uploading document', error);
      (error as any).then((e: any) =>
        console.error('Error uploading document = Promise', e)
      );
      throw error;
    }
  }

  public abstract upload(
    f: File,
    user_id: string,
    name: string,
    bucket: string,
    seq?: number
  ): Promise<UploadReturn>;
}

export type UploadReturn =
  | {
      data: {
        doc: DocumentType;
        path: string;
      };
      error: null;
    }
  | {
      data: null;
      error: any;
    };
