import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Question } from '../../../../core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';
import { currencies } from './currencies';

@Component({
  selector: 'app-question-currency',
  templateUrl: './question-currency.component.html',
  styleUrls: ['./question-currency.component.scss'],
})
export class QuestionCurrencyComponent implements OnInit {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  curs = currencies;

  val?: string;

  currencyForm = new FormGroup({
    cValue: new FormControl('', Validators.required),
  });
  currency: string = 'GBP';

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.question.answer && this.question.answer.value) {
      this.currencyForm.controls.cValue.setValue(
        this.question.answer.value.toString()
      );
      this.currency = this.question.answer.addtional_information.currency;
    }
    this.change();
  }

  changeCurrency() {
    this.change();
  }

  change() {
    // console.log('Curr Changed', this);
    // if (!this.val) {
    //   return;
    // }
    // this.changeAnswer.emit({
    //   answer: {
    //     value: Number.parseFloat(this.val),
    //     addtional_information: {
    //       currency: this.currency,
    //     },
    //   },
    //   question: {
    //     ...this.question,
    //     isValid: this.currencyForm.controls.cValue.valid,
    //   },
    // });

    setTimeout(() => {
      this.question.answer!.value = this.currencyForm.controls.cValue?.value;
      this.question.answer!.addtional_information = { currency: this.currency };
      this.question.isValid = this.currencyForm.controls.cValue?.valid;
      this.cdr.detectChanges();
      this.changeAnswer.next({
        answer: {
          value: this.currencyForm.controls.cValue.value,
          addtional_information: { currency: this.currency },
        },
        question: this.question,
      });
    }, 100);
  }
}
