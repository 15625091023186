import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-reset-modal',
  templateUrl: './email-reset-modal.component.html',
  styleUrls: ['./email-reset-modal.component.scss']
})
export class EmailResetModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public router: Router) {}

  email:FormControl = new FormControl('', [Validators.required, Validators.email]);

  openDialog() {
    const dialogRef = this.dialog.open(EmailResetModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  goToResetPage(){
    if(this.email.valid){
      this.dialog.closeAll()
      this.router.navigate(['change-password'])
    }
  }

}
