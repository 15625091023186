import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from 'src/app/core/models/user.model';

@Component({
  selector: 'app-presentation-card',
  templateUrl: './presentation-card.component.html',
  styleUrls: ['./presentation-card.component.scss'],
})
export class PresentationCardComponent {
  @Output() changeModeEvent = new EventEmitter<boolean>();
  @Input() user!: User;
  emitChangeMode() {
    this.changeModeEvent.emit();
  }
}
