import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Case } from 'src/app/core/models/case';
import { ClaimsService } from 'src/app/core/services/claims.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-case-information',
  templateUrl: './case-information.component.html',
  styleUrls: ['./case-information.component.scss'],
})
export class CaseInformationComponent {
  @Input() public case?: Case;

  loading = false;
  constructor(
    private cs: ClaimsService,
    private us: UserService,
    private router: Router
  ) {}

  async createClaim() {
    try {
      this.loading = true;
      const user = await this.us.getCurrentUser();

      const r = await this.cs.create({
        status: 'open',
        case_id: this.case?.id,
        // owner_client_id: user?.id,
        // is_private_access: false,
      });

      console.log('CREATED CLAIM', r);

      this.router.navigateByUrl('/client/claim/' + r.id);
    } catch (error) {
      console.error('ERROR', error);
    } finally {
      this.loading = false;
    }
  }
}
