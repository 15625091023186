import {
  NgSignaturePadOptions,
  SignaturePadComponent,
} from '@almothafar/angular-signature-pad';
import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/core/models/user.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Contract } from 'src/app/core/models/contract';
import { ContractService } from 'src/app/core/services/contract.service';
import { Claim } from 'src/app/core/models/claim';
import { SignedContractService } from 'src/app/core/services/signed_contract.service';

@Component({
  selector: 'app-eletronic-signature-modal',
  templateUrl: './eletronic-signature-modal.component.html',
  styleUrls: ['./eletronic-signature-modal.component.scss'],
})
export class EletronicSignatureModalComponent implements OnInit {
  template = 'pdf';

  date = new Date();
  user?: User;

  contract?: Contract;
  claim?: Claim;
  url?: string;

  canProceedToSign: boolean = false;

  loading = false;
  constructor(
    private userService: UserService,
    private contractService: ContractService,
    private signedContractService: SignedContractService,
    @Inject(MAT_DIALOG_DATA)
    public data: { contract_id: string; claim_id: string }
  ) {}

  async ngOnInit() {
    console.log('EletronicSignatureModalComponent -> this', this);
    this.contract = await this.contractService.get(this.data.contract_id);

    console.log('CONTRACT', this.contract);
    if (!this.contract) {
      console.error('Contract not found');
    }
    if (this.contract.configuration.explanation_text) {
      this.changeTemplate('explanation');
    }

    this.url = await this.contractService.getContractDocumentURLById(
      this.contract.id!
    );

    const u = await this.userService.getCurrentUser();
    if (!u) {
      console.error('This screen requires a user.');
      throw new Error('This screen requires a user.');
    }
    this.user = u;
    console.log('USER', this.user);
  }

  changeTemplate(template: string) {
    this.template = template;
  }

  docBundleValid(event: boolean) {
    this.canProceedToSign = event;
  }

  async sendDraw(event: any) {
    this.loading = true;
    try {
      console.log('DRAW', event);
      await this.signedContractService.doSign(
        this.contract!.id!,
        this.data.claim_id,
        event,
        this.userService.getFingerprint(),
        '000.000.000.000'
      );

      // this.scs.create({
      //   claim_id: this.data.claim_id,
      //   contract_id: this.contract?.id,
      //   status: 'PENDING',
      //   user_agent: this.us.getFingerprint(),
      //   signer_id: this.user?.id,
      //   signature_data: {
      //     claim_id: this.data.claim_id,
      //     contract_id: this.data.contract_id,
      //     qrcode_info: {
      //       full_legal_name: this.user?.display_name!,
      //       ip: '123.32.212.55',
      //     },
      //     signature_bytes: event,
      //     user_agent: this.us.getFingerprint(),
      //   },
      // });
      this.changeTemplate('sucess');
    } catch (error) {
      console.error('ERROR ON ElectronicSignatureModalComponent', error);
      this.changeTemplate('error');
    } finally {
      this.loading = false;
    }
  }
}
