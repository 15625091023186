import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-document-delete-document',
  templateUrl: './document-delete-document.component.html',
  styleUrls: ['./document-delete-document.component.scss'],
})
export class DocumentDeleteDocumentComponent {
  constructor(
    public dialogRef: MatDialogRef<DocumentDeleteDocumentComponent>
  ) {}
}
