import { Component, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputDateComponent,
      multi: true,
    },
  ],
})
export class InputDateComponent implements ControlValueAccessor {
  @Input() placeholder!: string;
  field = new FormControl('');

  constructor() {}

  onChange: any = () => {};
  onTouch: any = () => {};

  // sets the value used by the ngModel of the element
  set value(val: string) {
    this.field.setValue(val);
    this.onChange(val);
    this.onTouch(val);
  }

  // This will will write the value to the view if the the value changes occur on the model programmatically
  writeValue(value: any) {
    this.field.setValue(value);
  }

  // When the value in the UI is changed, this method will invoke a callback function
  registerOnChange(fn: (value: any) => void) {
    this.field.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: Function) {
    this.onTouch = fn;
  }
}
