import { Component, Input } from '@angular/core';
import { ClaimWithCase } from 'src/app/core/services/claims.service';
import { ThreadWithMessages } from 'src/app/core/services/communication.service';

@Component({
  selector: 'app-claim-messagelist',
  templateUrl: './claim-messagelist.component.html',
  styleUrls: ['./claim-messagelist.component.scss'],
})
export class ClaimMessagelistComponent {
  @Input() public comms: ThreadWithMessages[] | null = null;
}
