import {Component} from '@angular/core';
import {MatDialogRef, MatDialog} from '@angular/material/dialog';
import {PrivacyNoticeModalComponent} from '../privacy-notice-modal/privacy-notice-modal.component';

@Component({
  selector: 'app-terms-of-use-modal',
  templateUrl: './terms-of-use-modal.component.html',
  styleUrls: ['./terms-of-use-modal.component.scss'],
})
export class TermsOfUseModalComponent {
  constructor(
    public dialogRef: MatDialogRef<TermsOfUseModalComponent>,
    public dialog: MatDialog
  ) {
  }

  close(): void {
    this.dialogRef.close();
  }

  openPrivacy(): void {
    this.dialogRef.close();
    this.dialog.open(PrivacyNoticeModalComponent);
  }
}
