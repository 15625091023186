import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-shared-form-code-challenger',
  templateUrl: './input-code-challenger.component.html',
  styleUrls: ['./input-code-challenger.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCodeChallengerComponent),
      multi: true,
    },
  ],
})
export class InputCodeChallengerComponent
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  @ViewChildren('codeInput') codeInputs!: QueryList<ElementRef>;

  codes: string[] = ['', '', '', '', '', ''];
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.codeInputs.toArray().forEach((input, index) => {
      input.nativeElement.addEventListener('keydown', (event: any) => {
        if (event.keyCode === 8 && event.target.value === '')
          this.codeInputs
            .toArray()
            [Math.max(0, index - 1)].nativeElement.focus();
      });
      input.nativeElement.addEventListener('input', (e: any) => {
        // take the first character of the input
        // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
        // but I'm willing to overlook insane security code practices.
        const [first, ...rest] = e.target.value;
        e.target.value = first ?? ''; // first will be undefined when backspace was entered, so set the input to ""
        const lastInputBox = index === this.codeInputs.toArray().length - 1;
        const didInsertContent = first !== undefined;
        if (didInsertContent && !lastInputBox) {
          // continue to input the rest of the string
          this.codeInputs.toArray()[index + 1].nativeElement.focus();
          this.codeInputs.toArray()[index + 1].nativeElement.value =
            rest.join('');
          this.codeInputs
            .toArray()
            [index + 1].nativeElement.dispatchEvent(new Event('input'));
        }

        // Get atual full value
        const value = this.codeInputs.toArray().reduce((acc, input) => {
          return acc + input.nativeElement.value;
        }, '');

        console.log('value', value);
        this.updateValue(value);
      });
    });
  }

  writeValue(value: string): void {
    console.log('writeValue value', value);
    if (value) {
      setTimeout(() => {
        this.codeInputs.toArray().forEach((input, index) => {
          input.nativeElement.value = value[index] ?? '';
        });
      }, 100);
    } else {
      this.codes = ['', '', '', '', '', ''];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle disabled state if necessary
  }

  // TODO: Refactor
  onInput(event: any, index: number): void {}

  // TODO: Refactor
  onKeyDown(event: KeyboardEvent, index: number): void {}

  private updateValue(value: string): void {
    this.onChange(value);
    this.onTouched();
  }

  private focusNext(index: number): void {
    const inputElement = this.codeInputs.toArray()[index].nativeElement;
    inputElement.focus();
  }
}
