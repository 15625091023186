import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {
  ClaimsService,
  ClaimWithCase,
} from 'src/app/core/services/claims.service';

@Component({
  selector: 'app-os-actions-lst',
  templateUrl: './os-actions-lst.component.html',
  styleUrls: ['./os-actions-lst.component.scss'],
})
export class OsActionsLstComponent implements OnInit {
  claims?: ClaimWithCase[];

  @Output() multiClaims: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private cs: ClaimsService) {
  }

  async ngOnInit() {
    setTimeout(async () => {
      this.claims = await this.cs.getMyClaimsWithCase(3, 0);

      let swiperContainer = document.querySelector('swiper-container');
      swiperContainer?.initialize();

      if (this.claims.length === 2) {
        this.multiClaims.emit(true);
      }
    }, 200);
  }
}
