import { Component, Input, type OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ThreadWithMessagesAndClaimCase,
  checkIfIsUnread,
} from 'src/app/core/services/communication.service';

@Component({
  selector: 'message-card',
  templateUrl: './message-card.component.html',
  styleUrls: ['./message-card.component.scss'],
})
export class MessageCardComponent implements OnInit {
  @Input() thread!: ThreadWithMessagesAndClaimCase;
  isUnread: boolean = true;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.isUnread = checkIfIsUnread(this.thread);
  }

  getNewestMessageFromThread(thread: ThreadWithMessagesAndClaimCase) {
    // Sort messages and get the most recent
    return thread.cp_message?.sort((a, b) => {
      return (
        new Date(b.created_at!).getTime() - new Date(a.created_at!).getTime()
      );
    })[0];
  }

  goToMessage() {
    this.router.navigate(['client/messages/chat', this.thread.id]);
  }
}
