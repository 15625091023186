import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import slugify from 'slugify';

@Component({
  selector: 'custom-tab',
  templateUrl: './custom-tab.component.html',
  styleUrls: ['./custom-tab.component.scss'],
})
export class CustomTabComponent implements OnChanges, OnInit {
  @Input() tabs: string[] = ['Tab 1', 'Tab 2', 'Tab 3', 'Tab 4', 'Tab 5'];
  @Input() activeTab: string | undefined = undefined;
  @Input() disabledTabs: string[] = [];

  _tabs: Tab[] = [];

  @Output() tabChange = new EventEmitter<Tab>();

  ngOnInit(): void {
    this.updateTabsConfig();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['tabs'] || changes['disabledTabs'] || changes['activeTab']) {
      this.updateTabsConfig();
    }
    if (changes['activeTab']) {
      this.notifyTabChange(
        this._tabs.find((tab) => tab.name === this.activeTab)!
      );
    }
  }

  updateTabsConfig() {
    const slugSet = new Set<string>();
    const disabledSlugs = this.disabledTabs.map((tab) =>
      slugify(tab, { lower: true })
    );

    this._tabs = this.tabs
      .map((tab) => {
        const slug = slugify(tab, { lower: true });
        return {
          name: tab,
          selector: `[tab=${tab}]`,
          id: slug,
          active: this.activeTab ? tab === this.activeTab : false,
          disabled: disabledSlugs.includes(slug),
        };
      })
      .filter((tab) => {
        if (!slugSet.has(tab.id)) {
          slugSet.add(tab.id);
          return true;
        }
        return false;
      });

    if (!this.activeTab) {
      this.activeTab = this._tabs[0].name;
      this._tabs[0].active = true;
    }

    console.log('Updated _tabs:', this._tabs);
  }

  selectTab(tab: Tab) {
    if (tab.active) return;

    console.log('selectTab', tab);
    if (tab.disabled) {
      return;
    }

    this.activeTab = tab.name;
    this.updateTabsConfig();
    this.notifyTabChange({ ...tab, active: true });
  }

  notifyTabChange(tab?: Tab) {
    console.log('notifyTabChange', tab);
    if (tab) this.tabChange.emit(tab);
  }
}

export interface Tab {
  name: string;
  id: string;
  active: boolean;
  disabled: boolean;
  selector: string;
}
