import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SearchUKAddressResponse } from '../models/uk_address';
import { DatabaseService } from './base/database.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService extends DatabaseService<any> {
  constructor(private http: HttpClient) {
    super('cp_questionnaire_answer');
  }

  async searchUKAddress(searchValue: string): Promise<SearchUKAddressResponse> {
    // TODO: remove mock and test UK
    return Promise.resolve({
      Address: {
        data: [
          {
            _id: 'ab101ab',
            postcode: 'AB10 1AB',
            pafInfo: [
              {
                Thoroughfare: '',
                Street: 'Broad Street',
                'Double Dependent Locality': '',
                'Dependent Locality': '',
                'Post Town': 'ABERDEEN',
                Postcode: 'AB10 1AB',
                'Postcode Type': 'L',
                X_Coord: 394235,
                Y_Coord: 806529,
                Latitude: 57,
                Longitude: -2,
              },
            ],
            addtional_information: [
              {
                Postcode: 'AB10 1AB',
                'Postcode Sector': 'AB10 1',
                'Postcode District': 'AB10',
                'Postcode Area': 'AB',
                'Ward Code': 'S13002842',
                'Ward Name': 'George St/Harbour',
                'District Code': 'S12000033',
                'District Name': 'Aberdeen City',
                'Region Code': 'S99999999',
                'Region Name': 'Scotland',
                'County Code': '',
                'County Name': '',
                'Country Code': 'S92000003',
                'Country Name': 'Scotland',
                'LA Executive': 'NOC',
                Introduced: '201106',
                Retired: '',
              },
            ],
          },
          {
            _id: 'ab101af',
            postcode: 'AB10 1AF',
            pafInfo: [
              {
                Thoroughfare: '',
                Street: 'Broad Street',
                'Double Dependent Locality': '',
                'Dependent Locality': '',
                'Post Town': 'ABERDEEN',
                Postcode: 'AB10 1AF',
                'Postcode Type': 'L',
                X_Coord: 394181,
                Y_Coord: 806429,
                Latitude: 57,
                Longitude: -2,
              },
            ],
            addtional_information: [
              {
                Postcode: 'AB10 1AF',
                'Postcode Sector': 'AB10 1',
                'Postcode District': 'AB10',
                'Postcode Area': 'AB',
                'Ward Code': 'S13002842',
                'Ward Name': 'George St/Harbour',
                'District Code': 'S12000033',
                'District Name': 'Aberdeen City',
                'Region Code': 'S99999999',
                'Region Name': 'Scotland',
                'County Code': '',
                'County Name': '',
                'Country Code': 'S92000003',
                'Country Name': 'Scotland',
                'LA Executive': 'NOC',
                Introduced: '199606',
                Retired: '',
              },
            ],
          },
        ],
        error: null,
      },
    });

    const ret = (await firstValueFrom(
      this.http.get(
        environment.lambdaUrl +
          `/uk_address?pageSize=2&page=1&query=${searchValue}`,
        {
          headers: {
            apikey: environment.supabaseKey,
          },
        }
      )
    )) as SearchUKAddressResponse;

    return ret;
  }
}
