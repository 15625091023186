import { Injectable } from '@angular/core';
import { News } from '../models/news';
import { DatabaseService } from './base/database.service';

@Injectable({
  providedIn: 'root',
})
export class NewsService extends DatabaseService<News> {
  public teste: any;

  constructor() {
    super('cp_news');
  }

  async getNews() {
    const ret = await this.getAll(3, 0);

    return ret;
  }

  async getNewsByNewest(
    config: {
      limit?: number;
      page?: number;
      order?: 'desc' | 'asc';
      orderBy?: string;
    } = {}
  ) {
    const newsOptionsQuery = {
      limit: 10,
      page: 0,
      order: 'desc',
      orderBy: 'created_at',
      ...config,
    };

    const query = this.supabase
      .from(this.table)
      .select()
      .order(newsOptionsQuery.orderBy, {
        ascending: newsOptionsQuery.order === 'asc',
      });
    if (newsOptionsQuery.limit) query.limit(newsOptionsQuery.limit);
    if (newsOptionsQuery.page) {
      const { from, to } = this._getPagination(
        newsOptionsQuery.page,
        newsOptionsQuery.limit
      );
      query.range(from, to);
    }

    const { data, error } = await query;

    return (data as News[]) || [];
  }
  async getNewsByRelatedCase(
    idCase: string,
    limit: number = 3
  ): Promise<News[]> {
    const q = this.supabase
      .from(this.table)
      .select()
      .eq('case_id', idCase)
      .order('created_at', { ascending: false });
    if (limit) q.limit(limit);

    const { data, error } = await q;
    if (error) throw new Error(error.message);

    return data;
  }

  async getByHighlightPriority(limit: number = 3) {
    const q = this.supabase
      .from(this.table)
      .select()
      .order('metadata->highlight_priority', { ascending: false });
    if (limit) q.limit(limit);

    const { data, error } = await q;
    if (error) throw new Error(error.message);

    return data;
  }
}
