import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-actions-modal-ui',
  templateUrl: './actions-modal-ui.component.html',
  styleUrls: ['./actions-modal-ui.component.scss'],
})
export class ActionsModalUiComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      description?: string;
      cancelText: string;
      confirmText: string;
    }
  ) {}
}
