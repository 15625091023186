import { Component } from '@angular/core';

@Component({
  selector: 'app-status-progress-bar',
  templateUrl: './status-progress-bar.component.html',
  styleUrls: ['./status-progress-bar.component.scss']
})
export class StatusProgressBarComponent {
  availableContactStatuses = [
    {
      display: 'Join',
      explanation: 'You have manifested the desire to obtain compensation from AirBnB.'
    },
    {
      display: 'Instruction',
      explanation: 'Details of the claim are collected.'
    },
    {
      display: 'Analysis',
      explanation: 'The provided information is analysed.'
    },
    {
      display: 'Dispute',
      explanation: 'The claim is brought against the defendants, in or outside court.'
    },
    {
      display: 'Resolution',
      explanation: 'An outcome is reached and the claim is concluded.'
    }
  ]

  contactStatusIndex = 2
}
