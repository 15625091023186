import { Component, Input } from '@angular/core';
import { Claim } from 'src/app/core/models/claim';
import { ClaimWithCase } from 'src/app/core/services/claims.service';

@Component({
  selector: 'app-claim-information',
  templateUrl: './claim-information.component.html',
  styleUrls: ['./claim-information.component.scss'],
})
export class ClaimInformationComponent {
  @Input() public claim?: ClaimWithCase;
}
