import {
  BehaviorSubject,
  debounceTime,
  filter,
  Observable,
  Subscription,
  tap,
} from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  QuestionInPage,
  QuestionType,
  Questionnaire,
  QuestionnaireAnswer,
  QuestionnairePage,
  QuestionnaireWithQuestions,
} from 'src/app/core/models/questionnaire.model';
import {
  QuestionnaireService,
  QuestionnaireWithQuestionsWithContext,
} from 'src/app/core/services/survey.service';

import { ActionsModalUiComponent } from '../../simple/actions-modal-ui/actions-modal.ui.component';
import { EventService } from 'src/app/core/services/event.service';
import { EventTypes } from 'src/app/core/models/events.model';
import { MatDialog } from '@angular/material/dialog';
import { QuestionAnswer } from '../../simple/question/question.component';
import { Survey_answersService } from 'src/app/core/services/survey_answers.service';
import { ToastService } from 'angular-toastify';
import { UserService } from 'src/app/core/services/user.service';
import { runConditions } from '../../../utils/hideif';
import { runOneValidateCondition } from '../../../utils/validate';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { DynamicService } from 'src/app/core/services/base/dynamic.service';

interface QuestionnaireErrors {
  page: Record<string, string>;
  global: Record<string, string>;
}

interface Errors {
  errors: Record<string, string>;
}
export interface PageErrors extends Errors {
  page: string;
  isValid: boolean;
}

@Component({
  selector: 'app-questionnaire-answer',
  templateUrl: './questionnaire-answer.component.html',
  styleUrls: ['./questionnaire-answer.component.scss'],
})
export class QuestionnaireAnswerComponent implements OnInit, OnDestroy {
  // Inputs
  @Input('claimId') claimId!: string; //= 'b016dd10-ebbd-4ea7-aed3-b2a0fd14fad8';
  @Input('questionnaireId') questionnaireId!: string; // = 'a4aad8d5-6074-4b88-b3f7-042c6e5a754a';
  @Input('goToProgressSaved') askForUseSavedAnswers: boolean = true;

  @Output('completed') complete: EventEmitter<QuestionnaireAnswer> =
    new EventEmitter();

  questionnaire!: Questionnaire;
  questionsMap: { [id: string]: QuestionInPage } = {};
  navstack: {
    page: QuestionnairePage;
    order: number;
    isActive: boolean;
  }[] = [];

  user!: any;

  questionnaireAnswer$ = new BehaviorSubject<QuestionnaireAnswer | null>(null);
  questionnaireAnswer: QuestionnaireAnswer | null = null;
  pageValid$: BehaviorSubject<PageErrors | null> =
    new BehaviorSubject<PageErrors | null>(null);

  skipMap: Record<string, boolean> = {};
  errors: QuestionnaireErrors = {
    page: {},
    global: {},
  };

  isPageValid = true;
  isQuestionnaireValid = true;
  showPrevButton: boolean = false;
  isPreviousAllowed: boolean = false;
  isNextAllowed: boolean = false;

  loading$ = new BehaviorSubject<{
    loading: boolean;
    message?: string;
  }>({
    loading: false,
  });
  loadingPage = false;

  subscriptions: Subscription[] = [];
  constructor(
    private ss: QuestionnaireService,
    private ds: DynamicService<any>,
    private sas: Survey_answersService,
    private us: UserService,
    private matDialog: MatDialog,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
    private ee: EventService,
    private router: Router
  ) {}

  ngOnInit() {
    this._initObservables();
    this._init();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /**
   * Initialize Context functions
   */
  private _initObservables() {
    const laodingSub$ = this.loading$.subscribe({
      next: ({ loading, message }) => {
        if (loading) {
          // this.toastService.info(message || 'Loading...');
        }
      },
    });
    const answer$ = this.questionnaireAnswer$
      .pipe(
        filter((e) => !!e),
        debounceTime(50)
      )
      .subscribe({
        next: (answer) => {
          console.log('Answer on sub => ', answer);
          this.evalSkipIf();
          this.evalQuestionnaireErrors();
          this.isPreviousAllowed = this.canGoPrev();
          this.isNextAllowed = this.canGoNext();

          this.questionnaireAnswer = answer;
        },
      });
    const pageValid$ = this.pageValid$
      .pipe(
        filter((e) => !!e),
        debounceTime(50)
      )
      .subscribe({
        next: (page) => {
          // console.log('pageValid on sub => ', page);
          this.evalSkipIf();
          this.evalPageErrors(page!);
          this.evalQuestionnaireErrors();
          this.isPreviousAllowed = this.canGoPrev();
          this.isNextAllowed = this.canGoNext();
        },
      });

    this.subscriptions.push(...[laodingSub$, answer$, pageValid$]);
  }

  private async _init() {
    this.us.getCurrentUser().then((user) => {
      this.user = user;
    });
    // Check claimId and questionnaireId
    if (!this.claimId || !this.questionnaireId) {
      // console.error('ClaimId or questionnaireId not set');
      this.router.navigate(['client']);
    }
    const questionnaire = await this.getQuestionnaire();
    console.log('Questionnaire founded! => ', questionnaire);
    this.questionnaire = questionnaire.questionnaire;
    this.questionsMap = questionnaire.questions_map;
    // Mount Navstack
    this.mountNavstack(questionnaire);
    console.log('Navstack mounted! => ', this.navstack);

    // Get questionnaire answers if exists
    const answers = await this.getQuestionnaireAnswer();
    console.log('qap -> answers ', answers);

    console.log('CAIO6 - emitting questionnaireAnswer$ 1');

    this.questionnaireAnswer$.next(answers);

    try {
    } catch (error) {
    } finally {
      this.loading$.next({
        loading: false,
      });
    }
  }

  private async getQuestionnaire() {
    this.loading$.next({
      loading: true,
      message: 'Loading questionnaire...',
    });
    // Get the questionnaire
    const questionnaire = await this.ss.getQuestionnaireWithQuestionsById(
      this.questionnaireId
    );

    if (!questionnaire) {
      // console.error('Questionnaire not found');
      this.router.navigate(['client']);
    }

    return questionnaire;
  }

  private mountNavstack({ questionnaire }: QuestionnaireWithQuestions) {
    const firstPage = questionnaire.cp_questionnaire_page.find(
      (cpqp: any) => cpqp.id === questionnaire.first_page
    );

    if (!firstPage) {
      // console.error('First page not found');
      return [];
    }

    this.navstack.push({
      page: firstPage,
      order: 0,
      isActive: true,
    });

    this.pushNextPageOnNavstack(questionnaire, firstPage, 1);

    return this.navstack;
  }

  private pushNextPageOnNavstack(
    questionnaire: Questionnaire,
    currentPage: QuestionnairePage,
    order: number
  ) {
    if (!currentPage.next_page_id) {
      return;
    }

    const nextPage = questionnaire.cp_questionnaire_page.find(
      (cpqp) => cpqp.id === currentPage.next_page_id
    );

    if (!nextPage) {
      // console.error('Next page not found');
      return;
    }

    this.navstack.push({
      page: nextPage,
      order: order,
      isActive: false,
    });

    this.pushNextPageOnNavstack(questionnaire, nextPage, order + 1);
  }

  private async getQuestionnaireAnswer(): Promise<QuestionnaireAnswer> {
    this.loading$.next({
      loading: true,
      message: 'Loading possible answers...',
    });
    const currentUser = (await this.us.getCurrentUser())!;

    if (!currentUser) {
      // console.error('Current User error!');
      this.router.navigate(['client']);
    }
    const newAnswer: QuestionnaireAnswer = {
      claim_id: this.claimId,
      created_at: new Date(),
      id: uuidv4(),
      answers: {},
      questionnaire_id: this.questionnaire!.id,
      completed_at: null,
      responsible_user_id: currentUser.id!,
      campaign_data: null,
      history: [],
      navstack: [],
      started_at: new Date(),
      status: 'OPEN',
      user_data: this.us.getFingerprint(),
    };

    try {
      const questionnaireWithAnswersList =
        await this.ss.getSurveyWithAnswersByClaimAndQuestionnaire(
          this.claimId,
          this.questionnaireId
        );

      if (!questionnaireWithAnswersList) {
        // console.error('Questionnaire Not Found not found');
        this.router.navigate(['client']);
      }
      const questionnaireWithAnswers = questionnaireWithAnswersList[0];
      if (!questionnaireWithAnswers) {
        // console.error('Questionnaire Not Found not found');
        return newAnswer;
      }

      const answers = questionnaireWithAnswers.answers;
      if (!answers) {
        // console.error('Answers not found');
        return newAnswer;
      }

      if (answers.length === 0) {
        return newAnswer;
      }

      // Check Progress?
      if (!this.askForUseSavedAnswers) {
        return this.populateAnswers(answers);
      } else {
        // Check if user wants to go to the last answres saved or init a new one
        const dialogRef = this.matDialog.open(ActionsModalUiComponent, {
          data: {
            title: 'Progress found',
            description: 'Do you want to recover your questionnaire progress?',
            cancelText: 'No, reset progress.',
            confirmText: 'Yes, continue.',
          },
        });
        return new Promise((resolve, reject) => {
          dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
              // Init new
              this.clearQuestionnaire().finally(() => {
                (newAnswer as any).id = undefined;
                return resolve(newAnswer);
              });
            } else {
              // Continue
              resolve(this.populateAnswers(answers));
            }
          });
        });
      }
    } catch (error) {
      console.error('Error on get questionnaire answer');
      return newAnswer;
    }
  }

  private populateAnswers(answers: QuestionnaireAnswer[]): QuestionnaireAnswer {
    console.log('CAIO Populating answers');
    // Get most recent answer from the list (started_at: Date ISO string)
    const answerList: QuestionnaireAnswer = answers.reduce((prev, current) => {
      return new Date(prev.started_at).getTime() >
        new Date(current.started_at).getTime()
        ? prev
        : current;
    });

    const newNavstack: any[] = Object.assign([], this.navstack);
    console.log('CAIO Populated answers 1', answerList.navstack);

    if (!answerList.navstack) {
      console.log('PREBUILT NAVSTACK', this.navstack);
      answerList.navstack = this.navstack;
    }
    console.log('CAIO Populated answers 2', answerList.navstack);

    if (answerList.navstack.length !== newNavstack.length) {
      answerList.navstack = newNavstack;
    }

    if (this.isOldNavstackFormat(answerList.navstack)) {
      const uniquePagesList = [...new Set(answerList.navstack)];
      const newNavstack = uniquePagesList
        .map((pageId: string, index: number) => {
          return {
            page: this.questionnaire.cp_questionnaire_page.find(
              (cpqp: any) => cpqp.id === pageId
            ),
            order: index,
            isActive: false,
          };
        })
        .filter((n) => n.page)
        .map((n, i) => ({ ...n, order: i }));
      newNavstack[0].isActive = true;
      answerList.navstack = newNavstack;
    }
    // TODO: Update page objects to the new entries on this.questionnaire.cp_questionnaire_page
    console.log('CAIO Populated answers 3', answerList.navstack);

    answerList.navstack = answerList.navstack.map((ns, i) => ({
      ...ns,
      page: newNavstack[i].page,
    }));

    (this.navstack as any) = answerList.navstack;
    console.log('CAIO Populated answers 4', answerList.navstack);
    return answerList;
  }

  private isOldNavstackFormat(navstack: any[]): boolean {
    return typeof navstack[0] === 'string';
  }

  // View functions

  public atualPage() {
    return this.navstack.find((ns) => ns.isActive);
  }
  public getProgressBarValue() {
    // Get state on x/100% value on progress bar
    // Get current page index on navstack and check the referal page on navstack position, then calculate the percentage
    const currentPageIndex = this.navstack.findIndex((ns) => ns.isActive);
    const totalPages = this.navstack.length;

    // % = (currentPageIndex + 1) / totalPages * 100
    return ((currentPageIndex + 1) / totalPages) * 100;
  }
  public getAnswersObservable(): Observable<QuestionnaireAnswer | null> {
    return this.questionnaireAnswer$.asObservable();
  }
  public onChangeQuestionnaireAnswer(event: QuestionnaireAnswer) {
    console.log('100 - Emitting questionnaire answers');
    console.log('CAIO6 - emitting questionnaireAnswer$ 2');
    this.questionnaireAnswer$.next(event);
  }
  public onChangePageValid(event: PageErrors) {
    this.pageValid$.next(event);
  }
  public onPageLoading(event: boolean) {
    // console.log('Page loading => ', event);
    this.loadingPage = event;
  }
  public getErrorsList() {
    const errors: {
      page: string[];
      global: string[];
    } = {
      page: [],
      global: [],
    };
    if (!this.isPageValid) errors.page.push(...Object.values(this.errors.page));
    if (!this.isQuestionnaireValid)
      errors.page.push(...Object.values(this.errors.global));

    return errors;
  }

  public async goNext() {
    console.log('Go Next');
    const canGoNext = this.canGoNext();
    if (canGoNext) {
      const atualPageIndex = this.navstack.findIndex((ns) => ns.isActive);
      console.log('Can go next', this.navstack[atualPageIndex]);

      if (atualPageIndex === this.navstack.length - 1) {
        // console.log('Finishing survey...');
        try {
          this.loading$.next({
            loading: true,
            message: 'Saving survey...',
          });
          await this.saveFullQuestionnaire();
          console.log('Questionnaire completed!');
          await this.updateAllQuestionWithFillDBConfiguration(atualPageIndex);
          await this.updateAllQuestionWithSplitDbConfiguration(atualPageIndex);
        } catch (error) {
          // console.error('Error on save full questionnaire');
        } finally {
          this.loading$.next({
            loading: false,
          });
        }
      } else {
        // console.log('Go next page...');
        try {
          this.loading$.next({
            loading: true,
          });
          this.navstack[atualPageIndex].isActive = false;

          // Find on navstack the closest next page that is not in skipIf
          const closestPageIndex = this.navstack
            .slice(atualPageIndex + 1)
            .findIndex((ns) => !this.skipMap[ns.page.id]);

          if (closestPageIndex === -1) {
            console.error('No next page found');
            return;
          } else {
            const nextPageIndex = atualPageIndex + 1 + closestPageIndex;
            this.navstack[nextPageIndex].isActive = true;
          }
          await this.savePartialQuestionnaire();
          console.log('Questionnaire completed!');
          await this.updateAllQuestionWithFillDBConfiguration(atualPageIndex);
          await this.updateAllQuestionWithSplitDbConfiguration(atualPageIndex);
        } catch (error) {
          console.error('Error on save partial questionnaire', error);
          this.goPrev();
          // console.error('Error on save partial questionnaire');
        } finally {
          this.loading$.next({
            loading: false,
          });
        }
      }
    }
  }

  public goPrev() {
    const canGoPrev = this.canGoPrev();
    if (canGoPrev) {
      const atualPageIndex = this.navstack.findIndex((ns) => ns.isActive);
      console.log('Can go prev', this.navstack[atualPageIndex]);

      if (atualPageIndex === 0) {
        // console.log('First page...');
      } else {
        // console.log('Go prev page...');
        this.navstack[atualPageIndex].isActive = false;

        // Find on navstack a closest page that is not in skipIf
        const closestPageIndexReversed = this.navstack
          .slice(0, atualPageIndex)
          .reverse()
          .findIndex((ns) => !this.skipMap[ns.page.id]);

        if (closestPageIndexReversed === -1) {
          console.error('No page found');
          return;
        } else {
          const closestPageIndex =
            atualPageIndex - 1 - closestPageIndexReversed;
          this.navstack[closestPageIndex].isActive = true;
        }
      }
    }
  }

  private canGoPrev(): any {
    console.log(
      'Can go prev',
      this.navstack.findIndex((ns) => ns.isActive)
    );
    const atualPageIndex = this.navstack.findIndex((ns) => ns.isActive);
    if (atualPageIndex === 0) {
      console.log('First page');

      this.isPreviousAllowed = false;
      return false;
    }
    const pagesBefore = this.navstack
      .slice(0, atualPageIndex)
      .filter((ns) => !this.skipMap[ns.page.id]);
    if (pagesBefore.length === 0) {
      console.log('No page before');
      this.isPreviousAllowed = false;
      return false;
    }
    this.isPreviousAllowed = true;
    return true;
  }
  private canGoNext(): boolean {
    if (!this.isPageValid || !this.isQuestionnaireValid) {
      this.isNextAllowed = false;
      return false;
    }

    this.isNextAllowed = true;
    return true;
  }

  private evalSkipIf(): void {
    this.questionnaire.cp_questionnaire_page.forEach((page) => {
      const pageHideIfCondition = page.page_config?.skip_if;
      // console.log('Page Skip If => ', pageHideIfCondition);

      if (!pageHideIfCondition) {
        this.skipMap[page.id] = false;
        return;
      } else {
        const a = this.questionnaireAnswer$.value as any;
        // console.log('skip => data to be checked', a);
        this.skipMap[page.id] = runConditions(pageHideIfCondition, a);
      }

      // console.log('New Skip Map => ', this.skipMap);
    });

    // console.log('Skip Map => ', this.skipMap);
  }
  private evalPageErrors(pageErrors: PageErrors): void {
    console.log('Page Errors => ', pageErrors);
    if (pageErrors.isValid) {
      this.isPageValid = true;

      this.errors.page = {};
    } else {
      this.isPageValid = false;
      this.errors.page = pageErrors.errors;
    }
  }
  private evalQuestionnaireErrors(): void {
    // TODO: Evaluate questionnaire errors
    this.isQuestionnaireValid = true;
  }

  private async savePartialQuestionnaire() {
    this.loading$.next({
      loading: true,
      message: 'Saving partial survey...',
    });

    this.questionnaireAnswer!.status = 'OPEN';
    this.questionnaireAnswer!.navstack = this.navstack;

    this.ee
      .pushNew(EventTypes.QUESTIONNAIRE_NEXT_PAGE, {
        questionnaire_id: this.questionnaire?.id,
        claim_id: this.claimId,
      })
      .then()
      .catch();

    await this.saveQuestionnaire();
  }
  private async saveFullQuestionnaire() {
    this.loading$.next({
      loading: true,
      message: 'Saving full survey...',
    });

    this.questionnaireAnswer!.status = 'SUBMITTED';
    this.questionnaireAnswer!.navstack = this.navstack;
    this.questionnaireAnswer!.completed_at = new Date();

    this.ee
      .pushNew(EventTypes.QUESTIONNAIRE_COMPLETED, {
        questionnaire_id: this.questionnaire?.id,
        claim_id: this.claimId,
      })
      .then()
      .catch();

    try {
      await this.saveQuestionnaire();
      this.complete.next(this.questionnaireAnswer!);
    } catch (error) {
      console.error('Error on save full questionnaire');
    }
  }
  private async updateAllQuestionWithFillDBConfiguration(pageIndex: number) {
    console.log('CAAIO_ Updating all questions with fill configuration');
    try {
      // Get atual page
      const atualPage = this.navstack[pageIndex];
      if (!atualPage) return;

      // Get all questions on page
      const questions = atualPage.page.cp_question_in_page.map(
        (cqip) => cqip.cp_question
      );
      if (questions.length === 0) return;
      console.log('CAAIO_ Questions on page => ', questions);

      // Get all questions with fill configuration
      const questionWithFillConfig = questions.filter(
        (q) => !!q.configuration?.fillInfo
      );

      console.log(questionWithFillConfig);

      if (questionWithFillConfig.length === 0) return;

      for (const q of questionWithFillConfig) {
        const fillInfo = q.configuration?.fillInfo;
        if (!fillInfo) continue;

        const from = fillInfo.from;
        const update = fillInfo.update;
        const updateType = fillInfo.updateType || 'string';
        const valueSource = fillInfo.valueSource || 'value';
        const jsonKey = fillInfo.jsonKey;

        const filters = fillInfo.filters.map(
          (f: { op: string; col: string; val: string }) => ({
            ...f,
            val: f.val === '$userId' ? this.user!.id : f.val,
          })
        );
        if (!from || !update || filters.length === 0) continue;

        await this.ds.load(from);

        let valueToUpdate: any;

        if (valueSource === 'addtional_information') {
          valueToUpdate = q.answer?.addtional_information;
        } else {
          valueToUpdate = q.answer?.value;
        }

        if (!valueToUpdate) continue;

        try {
          const r = await this.ds.updatefillQuery(
            from,
            update,
            filters,
            valueToUpdate,
            updateType,
            jsonKey
          );
        } catch (error) {
          console.error('Error on update question with fill configuration');
        }
      }

      return;
    } catch (error) {
      return;
    }
  }

  private async updateAllQuestionWithSplitDbConfiguration(pageIndex: number) {
    try {
      // Get atual page
      const atualPage = this.navstack[pageIndex];
      if (!atualPage) return;

      // Get all questions on page
      const questions = atualPage.page.cp_question_in_page.map(
        (cqip) => cqip.cp_question
      );
      if (questions.length === 0) return;
      console.log('CAAIO_ Questions on page => ', questions);

      // Get all questions with fill configuration
      const questionWithFillConfig = questions.filter(
        (q) => !!q.configuration?.splitInfo
      );

      console.log(questionWithFillConfig);

      if (questionWithFillConfig.length === 0) return;

      console.log(
        'CAAIO_ Updating all questions with split configuration',
        questionWithFillConfig
      );

      for (const q of questionWithFillConfig) {
        const splitInfo = q.configuration?.splitInfo;
        if (!splitInfo) continue;

        const from = splitInfo.from;
        const update = splitInfo.source;
        // const updateType = fillInfo.updateType || 'string';
        const valueSource = splitInfo.valueSource || 'value';

        const filters = splitInfo.filters.map(
          (f: { op: string; col: string; val: string }) => ({
            ...f,
            val: f.val === '$userId' ? this.user!.id : f.val,
          })
        );
        if (!from || !update || filters.length === 0) continue;

        await this.ds.load(from);

        let valueToUpdate: any;

        if (valueSource === 'addtional_information') {
          valueToUpdate = q.answer?.addtional_information;
        } else {
          valueToUpdate = q.answer?.value;
        }

        if (!valueToUpdate) continue;

        const valReformed = this.createResultForSplitConfig(
          valueToUpdate,
          splitInfo
        );

        console.log('CAAIO_ Val reformed => ', valReformed);

        try {
          const r = await this.ds.updatefillQuery(
            from,
            update,
            filters,
            valReformed,
            'string'
          );
        } catch (error) {
          console.error('Error on update question with split configuration');
        }
      }
    } catch (error) {}
  }

  createResultForSplitConfig(data: string, config: any) {
    // Dividimos a string com base no separador configurado
    const values = data.split(config.separator);

    const result: any = {};

    // Processamos cada campo definido no array de fields
    config.fields.forEach((field: any) => {
      let value;

      if (field.position === 'first') {
        // Pega a primeira parte
        value = values[0];
      } else if (field.position === 'last') {
        // Pega a última parte
        value = values[values.length - 1];
      } else if (field.position === 'middle') {
        // Pega as partes intermediárias
        value = values.slice(1, values.length - 1).join(' ');
      } else if (field.position === 'index' && field.index >= 0) {
        // Caso o campo tenha um index específico
        value = values[field.index] || ''; // Garante que o valor exista
      }

      // Atribuímos o valor para o campo no resultado final
      result[field.db_column] = value;
    });

    // Agora persistimos o resultado no banco de dados
    return result;
  }

  private async clearQuestionnaire() {
    // TODO: Clear questionnaire answer
    return;
  }
  private async saveQuestionnaire() {
    try {
      const ret = await this.sas.submit(this.questionnaireAnswer!);
      // console.log('ANSWER SUBMIT RETURN', ret);
      return true;
    } catch (error) {
      // console.log('ERROR during PARTIAL SUBMITTING');
      this.toastService.error(
        'An error has ocurred during partial saving! Please, try again.'
      );
      return false;
    }
  }
}
