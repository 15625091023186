import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, of } from 'rxjs';
import { DatabaseService } from './base/database.service';
import { Contract, SignedContract } from '../models/contract';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignedContractService extends DatabaseService<SignedContract> {
  constructor(private http: HttpClient) {
    super('cp_signed_contract');
  }

  async doDownload(sc_id: string) {
    return this.supabase.storage
      .from('doc_signed')
      .createSignedUrl(sc_id + '.pdf', 3600, { download: true });
  }

  async doDownloadToSignedContract(bucket: string, sc_id: string) {
    return this.supabase.storage.from(bucket).createSignedUrl(sc_id, 3600, {});
  }

  async getByIdWithRelations(id: string): Promise<SignedContract> {
    const { data, error } = await this.supabase
      .from(this.table)
      .select()
      .eq('id', id)
      .select(
        '*, cp_contract(*), cp_claim(*, cp_case(*)), cp_user(*, cp_pii(*))'
      );

    if (error) throw error;
    console.log('GET', data);

    return data[0] ?? null;
  }

  async doSign(
    contract_id: string,
    claim_id: string,
    signature_bytes: string,
    user_agent: string,
    ip: string
  ) {
    const body = {
      contract_id,
      claim_id,
      signature_bytes,
      user_agent,
      ip,
    };
    const token = (await this.supabase.auth.getSession()).data.session
      ?.access_token;

    const ret = (await firstValueFrom(
      this.http.post(environment.lambdaUrl + '/sign-contract', body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    )) as any;

    return ret.message.claim;
  }
}
