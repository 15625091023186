import { Component, Input } from '@angular/core';
interface Item {
  title: string;
  content: string;
}
@Component({
  selector: 'app-accordion-multi',
  templateUrl: './accordion-multi.component.html',
  styleUrls: ['./accordion-multi.component.scss'],
})
export class AccordionMultiComponent {
  @Input()
  items!: Item[];
  @Input()
  title!: string;
}
