import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Question,
  QuestionType,
} from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';
import { DynamicService } from 'src/app/core/services/base/dynamic.service';
import { UserService } from 'src/app/core/services/user.service';

export interface AutofillFilter {
  op: string;
  col: string;
  val: string;
}

@Component({
  selector: 'app-question-text',
  templateUrl: './question-text.component.html',
  styleUrls: ['./question-text.component.scss'],
})
export class QuestionTextComponent implements OnInit {
  @Input() question!: Question;
  @Output() changeAnswer = new EventEmitter<QuestionAnswer>();

  isLongText: boolean = false;
  autofillMode: boolean = false;
  autofillEditable: boolean = false;
  userId: string = '';
  regexPattern: string = '';

  constructor(
    private dynamicService: DynamicService<any>,
    private userService: UserService
  ) {}

  async ngOnInit() {
    this.initializeQuestion();
  }

  private async initializeQuestion() {
    this.determineQuestionType();
    await this.handleConfiguration();
    this.validateAndEmit();
  }

  private determineQuestionType() {
    this.isLongText = this.question.response_type === QuestionType.LONG_TEXT;
  }

  private async handleConfiguration() {
    if (this.question.configuration) {
      if (
        this.question.configuration.autofill &&
        !this.question.answer?.value
      ) {
        await this.processAutofill(this.question.configuration.autofill);
      }
      if (this.question.configuration.validate) {
        this.processValidationConfig(this.question.configuration.validate);
      }
    }
  }

  private async processAutofill(autofillConfig: any) {
    this.autofillMode = true;
    this.autofillEditable = autofillConfig.editable ?? false;
    await this.fetchCurrentUserId();

    const filters = this.replacePlaceholdersInFilters(autofillConfig.filters);
    const autofillValue = await this.fetchAutofillValue(
      autofillConfig.from,
      autofillConfig.select,
      filters
    );

    if (autofillValue) {
      this.question.answer = { value: autofillValue };
    }
  }

  private async fetchCurrentUserId() {
    const currentUser = await this.userService.getCurrentUser();
    this.userId = currentUser?.id || '';
  }

  private replacePlaceholdersInFilters(
    filters: AutofillFilter[]
  ): AutofillFilter[] {
    return filters.map((filter) => ({
      ...filter,
      val: filter.val.replace('$userId', this.userId),
    }));
  }

  private async fetchAutofillValue(
    from: string,
    select: string,
    filters: AutofillFilter[]
  ): Promise<string | null> {
    try {
      this.dynamicService.load(from);
      const result = await this.dynamicService.autofillQuery(
        from,
        select,
        filters
      );
      return result?.[0]?.[select as any] || null;
    } catch (error) {
      console.error('Erro ao buscar valor de autofill:', error);
      return null;
    }
  }

  private processValidationConfig(validateConfig: any) {
    if (validateConfig.regex) {
      if (typeof validateConfig.regex === 'string') {
        this.regexPattern = validateConfig.regex;
      } else {
        this.regexPattern = validateConfig.regex.value;
      }
    }
  }

  /**
   * Realiza a validação da resposta e emite o evento de mudança.
   */
  validateAndEmit() {
    this.validateAnswer();
    this.emitChange();
  }

  private validateAnswer() {
    this.resetValidation();

    if (!this.question.elective && !this.hasValue()) {
      this.setValidationError('is required.');
      return;
    }

    if (this.hasValue()) {
      this.performValidations();
    }
  }

  private resetValidation() {
    this.question.isValid = true;
    this.question.messageError = '';
  }

  private hasValue(): boolean {
    const value = this.question.answer?.value;
    return (
      value !== null && value !== undefined && value.toString().trim() !== ''
    );
  }

  private performValidations() {
    if (this.regexPattern) {
      this.validateRegex();
    }

    // Outras validações podem ser adicionadas aqui no futuro:
    // this.validateMinLength();
    // this.validateMaxLength();
    // this.validateCustomRule();
  }

  private validateRegex() {
    const regex = new RegExp(this.regexPattern);
    const value = this.question.answer!.value;

    if (!value) {
      return;
    }

    if (typeof value !== 'string') {
      return;
    }

    console.log('Validating regex', { regex, value });

    if (!regex.test(value)) {
      const errorMessage =
        this.question.configuration?.validate?.regex?.messageError ||
        'The system only supports English characters.';
      this.setValidationError(errorMessage);
    }
  }

  private setValidationError(message: string) {
    this.question.isValid = false;
    this.question.messageError = message;
  }

  private emitChange() {
    this.changeAnswer.emit({
      answer: this.question.answer!,
      question: this.question,
    });
  }
}
