import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { SupabaseService } from 'src/app/core/services/base/supabase.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent {
  token: string | null = null;
  verificationMessage: string = '';
  constructor(
    private route: ActivatedRoute,
    private supabaseService: SupabaseService,
    private as: UserService,
    private router: Router,
    private toastService: ToastService
  ) {}

  async ngOnInit(): Promise<void> {
    this.token = this.route.snapshot.queryParamMap.get('token');
    if (this.token) {
      const a = await this.as.verifyEmailWithTokenHash(this.token);

      if (!a.error) {
        this.router.navigateByUrl('/auth/login');
        this.toastService.success('Thank you for confirming your email');
      } else {
        this.toastService.error(a.error.message);
        this.router.navigateByUrl('/auth/login');
      }
    }
  }
}
