import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-one-time-link',
  templateUrl: './one-time-link.component.html',
  styleUrls: ['./one-time-link.component.scss'],
})
export class OneTimeLinkComponent {
  token: string | null = null;
  verificationMessage: string = '';
  constructor(
    private route: ActivatedRoute,
    private as: UserService,
    private router: Router,
    private toastService: ToastService
  ) {}

  async ngOnInit(): Promise<void> {
    console.log('Loaded OTL Component');
    this.token = this.route.snapshot.queryParamMap.get('token');
    if (this.token) {
      const a = await this.as.signInWithMagicLink(this.token);

      if (!a.error) {
        this.toastService.success('Welcome back');
        this.router.navigateByUrl('/client');
      } else {
        this.toastService.error(a.error.message);
        // this.router.navigateByUrl('/auth/login');
      }
    }
  }
}
