import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { Question } from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';

@Component({
  selector: 'app-question-yes-no',
  templateUrl: './question-yes-no.component.html',
  styleUrls: ['./question-yes-no.component.scss'],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
  ],
})
export class QuestionYesNoComponent implements OnInit {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  ngOnInit(): void {
    this.question.isValid = this.question.answer!.value !== undefined;
    this.changeAnswer.next({
      answer: this.question.answer!,
      question: this.question,
    });
  }

  change() {
    this.question.isValid = this.question.answer!.value !== undefined;
    this.changeAnswer.next({
      answer: this.question.answer!,
      question: this.question,
    });
  }
}
