import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-mfa-code-verify',
  templateUrl: './code-verify.component.html',
  styleUrls: ['./code-verify.component.scss'],
})
export class CodeVerifyComponent {
  code: string = '';
  @Output() code$: EventEmitter<string> = new EventEmitter();

  constructor() {}

  sendCode() {
    this.code$.emit(this.code);
  }
}
