import { EntityMetadataMap, EntityDataModuleConfig } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
  Claims: { }
};

const pluralNames = {Claims: 'claims'};

export const entityConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames
};
