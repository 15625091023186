import { Base } from './base.model';

export interface Questionnaire extends Base {
  id: string;
  created_at: Date;
  name: string;
  version: string;
  slug: string;
  apply_from: Date;
  apply_until: Date;
  cp_questionnaire_page: QuestionnairePage[];
  first_page: string;
  active: boolean;
  audience: PublicAudience | PrivateAudience | CaseAudience;
  configuration: PageConfig;
  case_id: string;
  status: string;

  SkipIf?: {
    aggregator: 'any' | 'none' | 'all';
    conditions: {
      key: string;
      condition:
        | '$eq'
        | '$dif'
        | '$neq'
        | '$includes'
        | '$contains'
        | '$notContains'
        | '$exists';
      value: string | boolean;
    }[];
  };
}

export interface QuestionInPage extends Base {
  id: string;
  page_id: string;
  priority: number;
  question_id: string;
  question_logic?: { validate?: Logic; hide_if?: Logic; required?: boolean };
  cp_question: Question;
  claim_id: string;
}

export interface Question extends Base {
  id: string;
  created_at: Date;
  name: string;
  slug: string;
  statement: string;
  response_type: QuestionType;
  configuration:
    | any
    | QuestionConfigurationMultiFile
    | QuestionConfigurationOptions
    | QuestionConfigurationTable;
  questionnaire_id: string;
  elective?: boolean;

  // front-end utils
  answer?: Answer;
  isValid?: boolean;
  messageError?: string;
}

export interface QuestionTable extends Question {
  configuration: QuestionConfigurationTable;
  answer?: {
    value: string | number | Date | null;
    addtional_information?: {
      [slug: string]: Answer;
    }[];
    isIgnored?: boolean;
  };
}

export interface QuestionWithContext extends Question {
  claim_id: string;
}

export interface Answer {
  value: string | number | Date | null;
  addtional_information?: any;
  isIgnored?: boolean;
}

export interface TableAnswer {
  id: string;
  priority: number;
  questions: QuestionInPage[];
}

export interface QuestionnaireAnswer extends Base {
  id: string;
  created_at: Date;
  answers: { [key: string]: Answer };
  completed_at: Date | null;
  started_at: Date;
  status: 'OPEN' | 'SUBMITTED';
  user_data: any;
  campaign_data: any;
  claim_id: string;
  questionnaire_id: string;
  history: any;
  responsible_user_id: string;
  navstack: string[] | any[];
}

export interface PageConfig {
  skip_if?: Logic;
}

export interface Logic {
  aggregator: 'any' | 'none' | 'all';
  conditions: {
    key: string;
    condition:
      | '$eq'
      | '$dif'
      | '$neq'
      | '$includes'
      | '$contains'
      | '$notContains'
      | '$exists';
    value: string | boolean;
  }[];
}

export interface QuestionnairePage {
  id: string;
  code: string;
  name: string;
  cp_question_in_page: QuestionInPage[];
  page_config: PageConfig;
  questionnaire_id: string;
  next_page_id: string | null;
}

export interface Audience {
  audience_type: 'PUBLIC' | 'PRIVATE' | 'CASE';
}

interface PublicAudience extends Audience {
  audience_type: 'PUBLIC';
}

interface PrivateAudience extends Audience {
  audience_type: 'PRIVATE';
  user_tags_included: string[];
  user_tags_excluded: string[];
  claim_tags_included: string[];
  claim_tags_excluded: string[];
}

interface CaseAudience extends Audience {
  audience_type: 'CASE';
}

export interface QuestionnaireWithQuestions {
  questionnaire: Questionnaire;
  questions_map: { [id: string]: QuestionInPage };
}

interface QuestionConfiguration {}

export interface QuestionConfigurationOptions extends QuestionConfiguration {
  options: { key: string; value: string }[];
}

interface QuestionConfigurationMultiFile extends QuestionConfiguration {
  bucket: string;
}

export interface QuestionTableConfigQuestions extends Question {
  priority: number;
  logic?: { validate?: Logic; hide_if?: Logic; required?: boolean };
}
export interface QuestionConfigurationTable extends QuestionConfiguration {
  prefixSection?: string;
  sectionName?: string;
  table: QuestionTableConfigQuestions[];
}

export enum QuestionType {
  'SHORT_TEXT' = 'SHORT_TEXT',
  'LONG_TEXT' = 'LONG_TEXT',
  'OPTIONS' = 'OPTIONS',
  'MULTI_OPTIONS' = 'MULTI_OPTIONS',
  'FILE' = 'FILE',
  'MULTI_FILE' = 'MULTI_FILE',
  'CURRENCY' = 'CURRENCY',
  'INTEGER' = 'INTEGER',
  'DECIMAL' = 'DECIMAL',
  'DATE' = 'DATE',
  'DATETIME' = 'DATETIME',
  'PHONE' = 'PHONE',
  'ADDRESS' = 'ADDRESS',
  'YESNO' = 'YESNO',
  'EXPLANATION' = 'EXPLANATION',
  'TABLE' = 'TABLE',
  'CONCAT' = 'CONCAT',
}
