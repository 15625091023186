import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-document-upload-photo',
  templateUrl: './document-upload-photo.component.html',
  styleUrls: ['./document-upload-photo.component.scss'],
})
export class DocumentUploadPhotoComponent {
  imageData: any = null;

  uploadLoading = false;
  constructor(public dialogRef: MatDialogRef<DocumentUploadPhotoComponent>) {}

  onFileSelected(event: any): void {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadstart = (e) => (this.uploadLoading = true);
      reader.onloadend = (e) => (this.uploadLoading = false);
      reader.onload = (e) => {
        console.log('file', file);

        this.imageData = {
          name: file.name,
          src: reader.result,
        };
      };
      reader.readAsDataURL(file);
    }
  }
}
