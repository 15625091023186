import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(private router: Router, private userService: UserService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree | Observable<boolean | UrlTree>> {
    console.log('[AUTH GUARD] START');
    let user = await this.userService.getCurrentUser();
    console.log('[AUTH GUARD]  is logged:', user);

    if (!user) {
      console.log('[AUTH GUARD] Trying login with token');

      const success = await this.userService.tryToLogin(
        route.queryParams['token'],
        route.queryParams['rt']
      );

      if (success) {
        console.log('[AUTH GUARD] Successfully logged in using RT', success);
        user = await this.userService.getCurrentUser();
      } else {
        console.log('[AUTH GUARD] log in unsuccessful', success);
        this.router.navigate(['/auth/login']);
        return false;
      }
    }

    console.log('[AUTH GUARD] User', user);
    if (!user) {
      return false;
    }
    const module = state.url.split('/')[1].split('?')[0];

    console.log('[AUTH GUARD] Module', module);

    switch (module.toLowerCase()) {
      case 'client': {
        if (user?.primary_role == 'CLIENT') {
          return true;
        } else {
          this.router.navigate(['/firm']);
          return true;
        }
      }
      case 'firm': {
        if (user?.primary_role == 'ADMINISTRATOR') {
          return true;
        } else {
          this.router.navigate(['/client']);
          return true;
        }
      }
      default: {
        console.log(
          '[AUTH GUARD] Module does not support authentication',
          module
        );

        return false;
      }
    }
  }
}
