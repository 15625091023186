import { Component, Input, Renderer2 } from '@angular/core';
import { Case } from 'src/app/core/models/case';

@Component({
  selector: 'app-avaiable-list-case',
  templateUrl: './avaiable-list-case.component.html',
  styleUrls: ['./avaiable-list-case.component.scss'],
})
export class AvaiableListCaseComponent {
  itemMenuSelected!: HTMLElement;
  @Input() cases!: Case[];
  casesToShow!: Case[];
  itemsMenu!: string[];

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.casesToShow = [...this.cases];
    this.itemsMenu = this.cases
      .map((data) => data.case_type!)
      .filter(onlyUnique);
  }

  onClickItemMenu(target: any) {
    let element = target as HTMLElement;
    let value = target.id;
    if (this.itemMenuSelected) {
      this.renderer.removeClass(this.itemMenuSelected, 'selected');
    }
    if (this.itemMenuSelected == element) {
      this.casesToShow = [...this.cases];
    } else {
      this.itemMenuSelected = element;
      this.renderer.addClass(element, 'selected');
      this.casesToShow = [
        ...this.cases.filter((data) => data.case_type == value),
      ];
    }
  }
}

function onlyUnique(value: string, index: number, array: string[]) {
  return array.indexOf(value) === index;
}
