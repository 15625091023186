import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Action } from 'src/app/core/models/action.model';
import { EletronicSignatureModalComponent } from '../../../electronic-signature/components/eletronic-signature-modal/eletronic-signature-modal.component';
import { DownloadDocumentComponent } from '../../../../simple/os-action-item/modals/download-document/download-document.component';
import { PendingSignatureComponent } from '../../../../simple/os-action-item/modals/pending-signature/pending-signature.component';
import { DynamicService } from 'src/app/core/services/base/dynamic.service';

@Component({
  selector: 'app-oa-signature',
  templateUrl: './oa-signature.component.html',
  styleUrls: ['./oa-signature.component.scss', '../oa-styles.scss'],
})
export class OaSignatureComponent {
  @Input() action!: Action;
  @Output() changed = new EventEmitter<boolean>();

  infoPendingMessage = 'This action is pending. Please wait.';
  constructor(public dialog: MatDialog, private ds: DynamicService<any>) {}

  ngOnInit() {
    // this.signatureDialog();
    // get case config

    this.ds.load('cpv_actions_complete');
    this.ds
      .autofillQuery('cp_case_actions', '*', [
        { op: 'eq', col: 'action_id', val: this.action.action_id! },
      ])
      .then((data) => {
        console.log('CAIO9 - data', data);
        if (data.length > 0) {
          this.infoPendingMessage = (data[0] as any).config.info_pending;
        }
      });
  }

  signatureDialog() {
    // If No signature or failed, display signing pad.
    let dialogRef;
    if (this.action.ac_status !== 'DONE') {
      dialogRef = this.dialog.open(EletronicSignatureModalComponent, {
        data: {
          contract_id: this.action.contract_id,
          claim_id: this.action.claim_id,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
        this.changed.emit(true);
      });
    } else if ((this.action.ac_status as any) === 'SIGNED') {
      // If there is a signature and it's fine, display popup with download option
      dialogRef = this.dialog.open(DownloadDocumentComponent, {
        data: {
          signed_document_id: this.action.done_action_id,
        },
      });
    } else {
      // If there is a signature, but its pending, display please wait.
      dialogRef = this.dialog.open(PendingSignatureComponent, {
        data: {
          signed_document_id: this.action.done_action_id,
        },
      });
    }
  }

  checkDependsOn() {
    console.log('CAIO8 - checkDependsOn', this.action.prstatus);

    return (this.action.prstatus as any) === 'DONE';
  }

  checkStatus() {
    let status = 'OPEN';

    // se o ac_status for igual a 'SIGNED', está concluido
    if ((this.action.ac_status as any) === 'SIGNED') {
      return 'DONE';
    }

    if (this.action.ac_status === 'PENDING') {
      status = 'PENDING';
    }

    // se o ac_status for diferente de 'DONE' mas o prstatus for igual a 'SUBMITTED' esta em processamento
    if (
      (this.action.ac_status as any) !== 'DONE' &&
      (this.action.prstatus as any) === 'OPEN'
    ) {
      status = 'BLOCKED';
    }

    console.log('CAIO9 - checkStatus', status);

    return status;
  }
}
