import { Component } from '@angular/core';

@Component({
  selector: 'app-input-toggle-slide',
  templateUrl: './input-toggle-slide.component.html',
  styleUrls: ['./input-toggle-slide.component.scss']
})
export class InputToggleSlideComponent {

}
