import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Question } from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';

@Component({
  selector: 'app-question-link',
  templateUrl: './question-link.component.html',
  styleUrls: ['./question-link.component.scss'],
})
export class QuestionLinkComponent {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.change();
  }

  change() {
    setTimeout(() => {
      this.question.isValid = true;
      this.changeAnswer.next({
        answer: { value: '' },
        question: this.question,
      });
    }, 10);
  }
}
