import { Injectable } from '@angular/core';
import { Case } from '../models/case';

import { DatabaseService } from './base/database.service';
import { Questionnaire } from '../models/questionnaire.model';
import { Faq } from '../models/faq';
import { Contract } from '../models/contract';
import { News } from '../mock/news';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CaseService extends DatabaseService<Case> {
  constructor(private http: HttpClient, private us: UserService) {
    super('cp_case');
  }

  async getByCode(code: string | null) {
    return (
      await this.supabase
        .from(this.table)
        .select('*')
        .filter('code', 'eq', code)
    ).data![0] as Case;
  }

  override getAll(limit: number, page: number): Promise<Case[]> {
    return super.getAll(limit, page) as Promise<Case[]>;
  }

  async createCase(newcase: FullNewCase, image: Buffer) {
    
    const token = (await this.supabase.auth.getSession()).data.session?.access_token;
    console.log('TOKEN',token);

   
    const ret = await firstValueFrom(this.http.post(
      environment.lambdaUrl + '/generate_case',
      newcase,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )) as any


    const createdCase = ret.message.case.data[0];
    if (!createdCase.configuration) {
      createdCase.configuration = {};
    }
    await this.uploadImageToCase(image, `${createdCase.id}/coverImage.jpg`);

    const link = (await this.getCaseImage(createdCase.id) ).data?.signedUrl;

    createdCase.configuration.images = {
      high_res: link
    };

    console.log('CaseService -> createdCase',createdCase);
    
    const update = this.update(createdCase);
  }

  uploadImageToCase(image: Buffer,path: string) {
    return this.supabase.storage
      .from(environment.CASE_FILES_BUCKET || 'cases')
      .upload(path, image, { contentType: 'image/jpeg' });
  }

  getCaseImage(id:string) {
    return this.supabase.storage
      .from(environment.CASE_FILES_BUCKET || 'cases')
      .createSignedUrl(`${id}/coverImage.jpg`,1000*60*60*24*365*10,{download:true});
  }


}

interface FullNewCase {
  case: Partial<Case>;
  questionnaire?: Partial<Questionnaire>;
  faq?: Partial<Faq>;
  contract?: Partial<Contract>;
  news?: Partial<News>;
}
