import { Component, Input } from '@angular/core';
import { Claim } from 'src/app/core/models/claim';
import { ClaimWithCase } from 'src/app/core/services/claims.service';

@Component({
  selector: 'app-claim-status',
  templateUrl: './claim-status.component.html',
  styleUrls: ['./claim-status.component.scss'],
})
export class ClaimStatusComponent {
  @Input() public claim?: ClaimWithCase;
}
