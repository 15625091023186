import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QuestionnaireAnswer } from '../models/questionnaire.model';
import { DatabaseService } from './base/database.service';

@Injectable({
  providedIn: 'root',
})
export class Survey_answersService extends DatabaseService<any> {
  constructor(private http: HttpClient) {
    super('cp_questionnaire_answer');
  }

  async submit(qa: QuestionnaireAnswer) {
    const token = (await this.supabase.auth.getSession()).data.session
      ?.access_token;

    const ret = (await firstValueFrom(
      this.http.post(
        environment.lambdaUrl + '/submit_questionnaire_answer',
        qa,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    )) as any;

    return ret;
  }

  async deleteQa(qaId: string) {
    const token = (await this.supabase.auth.getSession()).data.session
      ?.access_token;

    try {
      const ret = (await firstValueFrom(
        this.http.delete(
          environment.lambdaUrl + '/submit_questionnaire_answer/' + qaId,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
      )) as any;

      return ret;
    } catch (error) {
      console.error('Error deleting QA', error);
      return null;
    }
  }
}
