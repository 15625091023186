import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Question,
  QuestionWithContext,
} from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';
import { MatDialog } from '@angular/material/dialog';
import { DocumentUploadModalComponent } from '../document-upload-modal/document-upload-modal.component';

@Component({
  selector: 'app-question-multi-file',
  templateUrl: './question-multi-file.component.html',
  styleUrls: ['./question-multi-file.component.scss'],
})
export class QuestionMultiFileComponent {
  @Input('question') question!: Question;
  @Input('context') context!: QuestionContext;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  dialogData?: {
    bucket: string;
    name: string;
    type: 'PERSONAL' | 'CLAIM';
    multiple: boolean;
    ref: string;
  };

  constructor(public dialog: MatDialog) {}

  change(ev: QuestionAnswer) {
    this.changeAnswer.next(ev);
  }

  openModal() {
    this.dialogData = {
      bucket: this.question.configuration.bucket,
      name: this.question.slug,
      multiple: true,
      ref: this.context.claim_id!,
      type: 'CLAIM',
    };

    const dialogRef = this.dialog.open(DocumentUploadModalComponent, {
      data: this.dialogData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      const parsed = JSON.parse(result);
      console.log(`Parsed result: ${parsed.value}`);
      if (parsed.status == 'COMPLETED') {
        this.question.isValid = this.checkValid(parsed.value);

        this.change({ answer: parsed.value, question: this.question });
      }
    });
  }

  checkValid(value?: string): boolean {
    if (!this.question.configuration?.required && !value) {
      return true;
    }

    if (this.question.configuration.required && !value) {
      return false;
    }

    return true;
  }
}

export interface QuestionContext {
  claim_id: string;
  index?: number;
}
