import { Injectable } from '@angular/core';

import { DatabaseService } from './base/database.service';
import { Base } from '../models/base.model';

@Injectable({
  providedIn: 'root',
})
export class MessageService extends DatabaseService<Message> {
  constructor() {
    super('cp_message');
  }
}

export class Message extends Base {
  thread_id?: string;
  author_user_id?: string;
  author_display_name?: string;
  value?: string;
  user_reads?: ReadEntry[];
  attachments: any;
  metadata: any;
  user_agent: any;
  text_search: any;
}

export interface ReadEntry {
  userId: string;
  device: string;
  timestamp: Date;
}
