import { CommonModule } from '@angular/common';
import { Component, Inject, type OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { first } from 'rxjs';
import { Kyc_Check } from 'src/app/core/models/kyc_check.model';
import { ClaimDocumentService } from 'src/app/core/services/claim_document.service';
import { KycService } from 'src/app/core/services/kyc.service';
import { PersonalDocumentService } from 'src/app/core/services/personal_document.service';
import { UserService } from 'src/app/core/services/user.service';

enum DocumentType {
  PASSPORT = 'passport',
  NATIONAL_IDENTITY_CARD = 'national_identity_card',
  RESIDENCE_PERMIT = 'residence_permit',
  OTHER_IDENTITY_DOCUMENT = 'other_identity_document',
  DRIVER_LICENSE = 'driving_licence',
}
enum ProcessingStatus {
  PROCESSING = 'PROCESSING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

interface KycCheck extends Kyc_Check {
  requested_attributes: {
    [key: string]: {
      first: string;
      last: string;
      images: {
        front: string;
        back?: string;
      };
    };
  };
}

@Component({
  selector: 'document-kyc-upload-manual-modal',
  templateUrl: './document-kyc-upload-manual-modal.component.html',
  styleUrls: ['./document-kyc-upload-manual-modal.component.scss'],
})
export class DocumentKycUploadManualModalComponent implements OnInit {
  steps: 'TYPE-DOC' | 'UPLOAD' | 'PROCESSING' | 'SUCCESS' | 'ERROR' =
    'TYPE-DOC';
  result: any = {};

  // TYPEDOC Controllers
  DocumentType = DocumentType;
  documentType: DocumentType = DocumentType.NATIONAL_IDENTITY_CARD;
  options = [
    {
      value: DocumentType.PASSPORT,
      label: 'Passport',
    },
    {
      value: DocumentType.NATIONAL_IDENTITY_CARD,
      label: 'National Identity Card',
    },
    {
      value: DocumentType.RESIDENCE_PERMIT,
      label: 'Residence Permit',
    },
    {
      value: DocumentType.DRIVER_LICENSE,
      label: 'Driver License',
    },
  ];

  // Upload Controllers
  frontImageFile: File | null = null;
  backImageFile: File | null = null;
  frontImage: string | null = '';
  backImage: string | null = '';

  // Processing Controllers
  kyc: {
    provider: string;
    check_id?: string;
    requested_attributes: {
      [key: string]: {
        first: string;
        last: string;
        images: {
          front: string;
          back?: string;
        };
      };
    };
  } = {
    provider: 'manual',
    check_id: '',
    requested_attributes: {},
  };

  checkId: string = '';

  // Processing Controllers

  processing: ProcessingStatus = ProcessingStatus.PROCESSING;
  progress: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      bucket: string;
      name: string;
      type: 'PERSONAL' | 'CLAIM';
      multiple: boolean;
      ref: string;
      provider?: string;
      user?: any;
    },
    public dialog: MatDialog,
    private pds: PersonalDocumentService,
    private cds: ClaimDocumentService,
    private ks: KycService,
    private us: UserService,
    private dialogRef: MatDialogRef<DocumentKycUploadManualModalComponent>
  ) {
    console.log('DocumentUploadModal -> this', this);
    this.result = {
      status: 'CANCELLED',
      value: null,
    };

    if (!data.provider) data.provider = 'thirdfort';
    this.kyc.provider = data.provider;

    const sub = this.dialogRef
      .backdropClick()
      .pipe(first())
      .subscribe((e) => this.close());
  }

  async ngOnInit() {
    // const user = this.data.user || (await this.us.getCurrentUser());
    // console.log('user', user);
  }

  changeImage(event: File | null, side: 'front' | 'back') {
    console.log('change image', event);

    if (!event) {
      if (side === 'front') {
        this.frontImage = null;
        this.frontImageFile = null;
      } else {
        this.backImage = null;
        this.backImageFile = null;
      }
      return;
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (side === 'front') {
          this.frontImage = e.target?.result as string;
          this.frontImageFile = event;
        } else {
          this.backImage = e.target?.result as string;
          this.backImageFile = event;
        }
      };

      reader.readAsDataURL(event);
    }
  }

  getDocumentTypeLabel(type: DocumentType) {
    return this.options.find((opt) => opt.value === type)?.label;
  }

  private processDocumentType() {
    if (true) {
      this.frontImage = '';
      this.backImage = '';

      this.kyc.requested_attributes = {
        [this.documentType]: {
          first: '',
          last: '',
          images: {
            front: this.frontImage!,
            back: this.backImage!,
          },
        },
      };
      this.steps = 'UPLOAD';
    }
  }
  private async processUpload() {
    if (true) {
      // Get client name
      const { display_name } =
        this.data.user || (await this.us.getCurrentUser())!;
      const [firstName, ...rest] = display_name!.split(' ');
      const restName = rest.join(' ');

      this.kyc.requested_attributes[this.documentType].first = firstName;
      this.kyc.requested_attributes[this.documentType].last = restName;

      if (!!this.checkId) this.kyc.check_id = this.checkId;
      else delete this.kyc.check_id;

      this.kyc.requested_attributes[this.documentType].images.front =
        this.frontImage!;
      if (this.documentType !== DocumentType.PASSPORT && !this.backImage)
        return;
      if (this.documentType !== DocumentType.PASSPORT)
        this.kyc.requested_attributes[this.documentType].images.back =
          this.backImage!;

      // Send to server
      this.steps = 'PROCESSING';
      this.processing = ProcessingStatus.PROCESSING;
      this.progress = 0;

      const idSetInterval = setInterval(() => {
        if (this.progress < 80) this.progress += 10;
      }, 1000);

      try {
        const newKyc: Kyc_Check = {
          stage: 'DONE',
          date_requested: new Date(),
          provider: 'MANUAL',
          passed: true,
          requested_attributes: this.kyc.requested_attributes,
          checked_user_id: this.data.ref,
        };

        const res = await this.ks.create(newKyc);
        console.log('res', res);

        // Save doc on ClaimDocument/PersonalDocument
        const uploadStatus: any[] = [];
        let uls: ClaimDocumentService | PersonalDocumentService;
        if (this.data.type == 'CLAIM') {
          uls = this.cds;
        } else {
          uls = this.pds;
        }

        const uploads: any = [];
        if (this.frontImageFile) uploads.push(this.frontImageFile);
        if (this.backImageFile) uploads.push(this.backImageFile);

        if (uploads.length === 0) {
          throw new Error('No files to upload');
        }

        const p$ = await Promise.all(
          uploads.map((f: File, i: number) => {
            return uls.upload(
              f,
              this.data.ref,
              this.data.name,
              this.data.bucket,
              i
            );
          })
        );

        console.log('p$', p$);

        this.processing = ProcessingStatus.APPROVED;
      } catch (error) {
        console.log('error process kyc', error);
        // Get checkId
        // this.checkId = error.check_id;
        this.processing = ProcessingStatus.REJECTED;
      } finally {
        this.progress = 100;
        clearInterval(idSetInterval);

        setTimeout(() => {
          this.steps =
            this.processing === ProcessingStatus.APPROVED ? 'SUCCESS' : 'ERROR';
        }, 2000);
      }
    }
  }
  private processError() {
    this.steps = 'UPLOAD';

    this.frontImage = '';
    this.backImage = '';

    this.kyc.requested_attributes = {
      [this.documentType]: {
        first: '',
        last: '',
        images: {
          front: this.frontImage!,
          back: this.backImage!,
        },
      },
    };
  }

  nextStep() {
    switch (this.steps) {
      case 'TYPE-DOC':
        this.processDocumentType();
        break;
      case 'UPLOAD':
        this.processUpload();
        break;
      case 'ERROR':
        this.processError();
        break;
      default:
        break;
    }
  }

  backStep() {
    switch (this.steps) {
      case 'UPLOAD':
        this.steps = 'TYPE-DOC';
        break;
      case 'PROCESSING':
        this.steps = 'UPLOAD';
        break;
      default:
        break;
    }
  }

  close() {
    this.dialogRef.close(JSON.stringify(this.result));
  }

  finish() {}
}
