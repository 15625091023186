import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Question } from '../../../../core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';

@Component({
  selector: 'app-question-checkbox',
  templateUrl: './question-checkbox.component.html',
  styleUrls: ['./question-checkbox.component.scss'],
})
export class QuestionCheckboxComponent implements OnInit {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();
  options: string[] = [];

  ngOnInit(): void {
    if (this.question?.configuration && this.question.configuration.options) {
      this.options = this.question.configuration.options;
    }
    this.change();
  }

  change() {
    this.question.isValid = !!this.question.answer!.value;
    this.changeAnswer.next({
      answer: this.question.answer!,
      question: this.question,
    });
  }
}
