import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ClaimsComponent } from './pages/claims/claims.component';

import { ProfileComponent } from './modules/client/pages/profile/profile.component';
import { AuthGuardService } from './core/services/auth/auth.guard';
import { QuestionnaireAnswerComponent } from './shared/components/smart/questionnaire-answer/questionnaire-answer.component';
import { VerifyDocComponent } from './pages/verify-doc/verify-doc.component';
import { ClientGuard } from './core/guards/client.guard';
import { FirmGuard } from './core/guards/firm.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/root/root.module').then((m) => m.RootModule),
  },

  // {
  //   path: 'dashboard',
  //   component: DashboardComponent,
  //   children: [
  //     { path: 'claim/new/:id', component: NewClaimComponent },
  //     { path: 'cases', component: CasesComponent },
  //     { path: 'case/:id', component: CaseComponent },
  //     { path: 'questionnaire/:id', component: QuestionnaireComponent },
  //     { path: 'correspondence/:id', component: CorrespondenceComponent },
  //     { path: 'news', component: NewsComponent },
  //     { path: 'news/:id', component: NewsOneComponent },
  //     { path: 'cc/home', component: CcDashboardComponent },
  //   ],
  // },
  {
    path: 'client',
    loadChildren: () =>
      import('./modules/client/client.module').then((m) => m.ClientModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'firm',
    loadChildren: () =>
      import('./modules/firm/firm.module').then((m) => m.FirmModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'verifyqr',
    component: VerifyDocComponent,
    // canActivate: [AuthGuardService],
  },
  // {
  //   path: 'customization',
  //   children: [
  //     { path: 'grid', component: GridComponent },
  //     { path: 'typograph', component: TypographComponent },
  //     { path: 'icon', component: IconComponent },
  //     { path: 'card', component: CardComponent },
  //     { path: 'color', component: ColorComponent },
  //     { path: 'list', component: ListComponent },
  //     { path: 'button', component: ButtonComponent },
  //     { path: 'input', component: InputComponent },
  //     { path: 'image', component: ImageComponent },
  //     { path: 'accordion', component: AccordionComponent },
  //     { path: 'block', component: BlockComponent },
  //     { path: 'modal', component: ModalComponent },
  //     { path: 'navigation', component: NavigationComponent },
  //     { path: 'notification', component: NotificationComponent },
  //   ],
  // },
  {
    path: 'claims',
    children: [{ path: 'list', component: ClaimsComponent }],
    canActivate: [AuthGuardService],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'profile/:tab',
    component: ProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'examples/qa',
    component: QuestionnaireAnswerComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: '**',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
