import { Component, OnChanges, OnInit } from '@angular/core';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-test-storage',
  templateUrl: './test-storage.component.html',
  styleUrls: ['./test-storage.component.scss'],
})
export class TestStorageComponent implements OnInit {
  constructor(private storageService: StorageService) {}
  bucketList: any = [];
  url: any;
  inputFile: any;

  async ngOnInit() {
    this.bucketList = await this.storageService.getAllBucket().then((data) => {
      return data.data;
    });
  }
  onFileSelected(event: any) {
    this.inputFile = event.target.files[0];
  }

  async createBucket(bucketName: string) {
    await this.storageService.createBucket(bucketName);
    this.getAllBucket();
  }

  async signedUrl(bucket: string, folder: string) {
    this.url = await this.storageService.signedUrl(bucket, folder);
  }

  async uploadBucket(bucket: string, path: string) {
    return await this.storageService.upload(bucket, path, this.inputFile);
  }

  async getAllBucket() {
    const buckets = await this.storageService.getAllBucket();
    this.bucketList = buckets.data;
  }
}
