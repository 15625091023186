import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DatabaseService } from './base/database.service';
import { Contract } from '../models/contract';

@Injectable({
  providedIn: 'root',
})
export class ContractService extends DatabaseService<Contract> {
  constructor() {
    super('cp_contract');
  }

  async getContractDocumentURLById(id: string) {
    const query = await this.supabase
      .from('cp_contract')
      .select('url')
      .eq('id', id);
    if (!query || !query.data || !query.data[0] || !query.data[0].url) {
      throw new Error('Couldnt find document ' + JSON.stringify(query));
    }
    console.log('getContractQuery', query);

    const contractURL = id + '/' + query.data[0].url;

    return (
      await this.supabase.storage
        .from('contract_document')
        .createSignedUrl(contractURL, 3600, { download: true })
    ).data?.signedUrl;
  }
}
