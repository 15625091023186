import { Injectable, inject } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from './base/supabase.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public supabaseClient: SupabaseClient;
  private sbs: SupabaseService = inject(SupabaseService);

  constructor() {
    this.supabaseClient = this.sbs.supabase;
  }

  /**
   *
   * @param bucket buckName
   * @param path  walks with filename
   * @param file file
   * @returns
   */
  async upload(bucket: string, path: string, file: File) {
    const { data, error } = await this.supabaseClient.storage
      .from(bucket)
      .upload(path, file);

    return { data, error };
  }

  /**
   *
   * @param bucket  buckName
   * @param path walks with filename
   */
  async download(bucket: string, path: string) {
    const { data, error } = await this.supabaseClient.storage
      .from(bucket)
      .download(path);

    return { data, error };
  }

  /**
   *
   * @param newBucket  buckName
   * @returns bucket creation data
   */
  async createBucket(newBucket: string) {
    const { data, error } = await this.supabaseClient.storage.createBucket(
      newBucket
    );
    return { data, error };
  }

  /**
   *
   * @param bucket buckName
   * @param folder walks with filename
   * @returns json with signed url
   */
  async signedUrl(bucket: string, folder: string) {
    const { data, error } = await this.supabaseClient.storage
      .from(bucket)
      .createSignedUrl(folder, 60);

    return { data, error };
  }

  async getBucket(nameBucket: string) {
    const { data, error } = await this.supabaseClient.storage.getBucket(
      nameBucket
    );
    return { data, error };
  }

  async getAllBucket() {
    const { data, error } = await this.supabaseClient.storage.listBuckets();

    return { data, error };
  }

  async deleteBucket(bucketName: string) {
    const { data, error } = await this.supabaseClient.storage.deleteBucket(
      bucketName
    );
    return { data, error };
  }

  async emptyBucket(bucketName: string) {
    const { data, error } = await this.supabaseClient.storage.emptyBucket(
      bucketName
    );
    return { data, error };
  }

  async listAllFilesBucket(bucketName: string, folder: string) {
    const { data, error } = await this.supabaseClient.storage
      .from(bucketName)
      .list(folder, {
        limit: 100,
        offset: 0,
        sortBy: { column: 'name', order: 'asc' },
      });
    return { data, error };
  }

  async replaceExistingFile(bucketName: string, path: string, file: File) {
    const { data, error } = await this.supabaseClient.storage
      .from(bucketName)
      .update(path, file, {
        cacheControl: '3600',
        // Overwrite file if it exis
        upsert: true,
      });

    return { data, error };
  }

  async DeleteFilesInBucket(bucketName: string, path: string[]) {
    const { data, error } = await this.supabaseClient.storage
      .from(bucketName)
      .remove(path);

    return { data, error };
  }

  async CreateMultipleSignedUrls(bucket: string, paths: string[]) {
    const { data, error } = await this.supabaseClient.storage
      .from(bucket)
      .createSignedUrls(paths, 60);
    return { data, error };
  }
}
