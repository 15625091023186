import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClaimsService } from 'src/app/core/services/claims.service';
import {
  CommunicationService,
  ThreadWithMessages,
} from 'src/app/core/services/communication.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-thread',
  templateUrl: './thread.component.html',
  styleUrls: ['./thread.component.scss'],
})
export class ThreadComponent implements OnInit, OnDestroy {
  thread$!: Promise<ThreadWithMessages>;
  thread?: ThreadWithMessages;

  sending: boolean = false;

  reply: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // toggled buttons

      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

      ['clean'], // remove formatting button

      ['link', 'image', 'video'], // link and image, video
    ],
  };

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private cs: ClaimsService,
    private cms: CommunicationService,
    private us: UserService
  ) {}

  async ngOnInit() {
    let thread_id = this.route.snapshot.paramMap.get('id');
    this.thread$ = this.cms.getCommunicationsWithMessagesForThread(thread_id!);
    this.thread = await this.thread$;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe);
  }

  async send() {
    this.sending = true;
    setTimeout(async () => {
      await this.cms.sendMessage({
        attachments: {},
        metadata: {},
        user_agent: this.us.getFingerprint(),
        author_display_name: (await this.us.getCurrentUser())?.display_name,
        author_user_id: (await this.us.getCurrentUser())?.id,
        thread_id: this.thread?.id,
        value: this.reply,
        user_reads: [],
      });
      this.thread$ = this.cms.getCommunicationsWithMessagesForThread(
        this.thread?.id!
      );
      this.thread = await this.thread$;
      this.sending = false;
    }, 1000);
  }
}
