import { Component } from '@angular/core';

@Component({
  selector: 'app-choice-modal',
  templateUrl: './choice-modal.component.html',
  styleUrls: ['./choice-modal.component.scss']
})
export class ChoiceModalComponent {

}
