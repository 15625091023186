import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  Answer,
  Question,
  QuestionInPage,
  QuestionnaireAnswer,
  QuestionType,
} from 'src/app/core/models/questionnaire.model';
import { QuestionContext } from '../question-multi-file/question-multi-file.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit {
  @Input('context') context!: QuestionContext;
  @Input() isHidden = false;
  @Input('question') question!: QuestionInPage;
  @Input('questionnaireAnswer') qa$!: Observable<QuestionnaireAnswer | null>;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (this.question.cp_question.response_type == QuestionType.TABLE) {
      console.log('this.question', this.question);
    }
  }

  change(ev: QuestionAnswer) {
    console.log('CAIO7 - change', ev);

    this.changeAnswer.next(ev);
  }
}

export interface QuestionAnswer {
  question: Question;
  answer: Answer;
}
