import { Component, Input, OnInit } from '@angular/core';
import { Case } from 'src/app/core/models/case';
import { Claim } from 'src/app/core/models/claim';
import { CaseService } from 'src/app/core/services/case.service';
import { ClaimWithCase } from 'src/app/core/services/claims.service';

@Component({
  selector: 'app-block-claim',
  templateUrl: './block-claim.component.html',
  styleUrls: ['./block-claim.component.scss'],
})
export class BlockClaimComponent implements OnInit {
  @Input() claim!: ClaimWithCase;
  case!: Case;

  random = Math.round(Math.random() * 300);

  constructor(private czs: CaseService) {}

  async ngOnInit() {
    console.log('*** CLAIM', this.claim);
    // this.case = cases.filter((data) => (data.uuid = this.claim.case_uuid))[0];
  }
}
