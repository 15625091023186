import { Injectable } from '@angular/core';
import { Base } from '../../models/base.model';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicService<T extends Base> extends DatabaseService<T> {
  constructor() {
    super('Not Loaded');
  }

  load(entity: string) {
    this.table = entity;
  }

  async autofillQuery(
    from: string,
    select: string,
    filters: {
      op: string;
      col: string;
      val: string;
    }[]
  ) {
    let query = this.supabase.from(from).select(select);
    query = this.doFilters(query, filters);

    const { data, error } = await query;
    if (error) throw new Error(error.message);

    return data;
  }
  async updatefillQuery(
    from: string,
    select: string,
    filters: {
      op: string;
      col: string;
      val: string;
    }[],
    val: any,
    updateType: string,
    jsonKey?: string
  ) {
    let query: any = this.supabase.from(from);

    let save = val;
    if (updateType === 'jsonb') {
      // pegar o valor atual
      const atualval = await this.autofillQuery(from, select, filters);
      // console.log('CAIO4 atualval', atualval);
      // atualizar as entradas com o que eu tenho
      if (atualval.length > 0) {
        const oldval: any = atualval[0][select as any]
          ? atualval[0][select as any]
          : {};

        save = jsonKey ? { ...oldval, [jsonKey]: val } : { ...oldval, ...val };
        // se não tiver nada, cria um novo
      }
    }
    // Se não for jsonb, usa o update normal
    // console.log('Caio4 val', save);
    query = query.update({ [select]: save });
    query = this.doFilters(query, filters);

    const { data, error } = await (query as any);
    if (error) {
      // console.error('CAAIO updatefillQuery error', error);
      throw new Error(error.message);
    }

    return data;
  }

  private doFilters(
    query: any,
    filters: { op: string; col: string; val: string }[]
  ) {
    filters.forEach((filter) => {
      switch (filter.op) {
        case 'eq':
          query = query.eq(filter.col, filter.val);
          break;
      }
    });

    return query;
  }
}
