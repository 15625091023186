import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentPreviewModalComponent } from '../document-preview-modal/document-preview-modal.component';

@Component({
  selector: 'app-document-dropdown',
  templateUrl: './document-dropdown.component.html',
  styleUrls: ['./document-dropdown.component.scss'],
})
export class DocumentDropdownComponent {
  @Input() URL!: string;
  @Input() extension!: string;

  constructor(private dialog: MatDialog) {}

  openDocumentPreview() {
    console.log('oi');
    this.dialog.open(DocumentPreviewModalComponent, {
      data: {
        URL: this.URL,
        extension: this.extension,
      },
    });
  }
}
