import { Component, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
interface Item {
  title: string;
  content: string;
}
@Component({
  selector: 'app-accordion-single',
  templateUrl: './accordion-single.component.html',
  styleUrls: ['./accordion-single.component.scss'],
})
export class AccordionSingleComponent {
  @Input()
  item!: Item;
}
