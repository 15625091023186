import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnInit,
} from '@angular/core';
import { Message } from 'src/app/core/models/comms.model';
import {
  CommunicationService,
  ThreadWithMessagesAndClaimCase,
} from 'src/app/core/services/communication.service';

@Component({
  selector: 'app-communication-chat',
  templateUrl: './communication-chat.component.html',
  styleUrls: ['./communication-chat.component.scss'],
})
export class CommunicationChatComponent implements OnInit {
  @Input() thread: any;
  @Input() user: any;

  @Input() sendField: 'simple' | 'advanced' = 'simple';
  @Input() breadcrumbType: 'client' | 'firm' = 'client';

  message = '';
  loadingSendMessage = false;

  constructor(private cs: CommunicationService) {}
  ngOnInit(): void {}

  getNewestMessageFromThread(thread: ThreadWithMessagesAndClaimCase) {
    // Sort messages and get the most recent
    return thread.cp_message?.sort((a, b) => {
      return (
        new Date(b.created_at!).getTime() - new Date(a.created_at!).getTime()
      );
    })[0];
  }

  getSortedMessages(message: Message[]) {
    return message.sort((a, b) => {
      return (
        new Date(a.created_at!).getTime() - new Date(b.created_at!).getTime()
      );
    });
  }

  async closeThread() {
    try {
      const res = await this.cs.closeThread(this.thread.id);
      console.log('Thread closed', res);
    } catch (error) {
      console.error;
    }
  }

  async sendMessage() {
    this.loadingSendMessage = true;
    try {
      const newMessage: Message = {
        value: this.message,
        thread_id: this.thread.id,
        author_user_id: this.user?.id ?? '-',

        type_message: 'INTERNAL_MESSAGE',
        author_display_name: '',
        attachments: {},
        user_agent: {},
        metadata: {},
        user_reads: [],
      };

      const { data } = await this.cs.sendMessage(newMessage);
      console.log('Message saved received', data);
      this.thread.cp_message?.push(data);
      this.message = '';
    } catch (error) {
      console.error(error);
    } finally {
      console.log('Message sent');
      this.loadingSendMessage = false;
    }
  }
}
