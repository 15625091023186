import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-welcome-popup',
  templateUrl: './welcome-popup.component.html',
  styleUrls: ['./welcome-popup.component.scss'],
})
export class WelcomePopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { contract_id: string; claim_id: string },
    public dialog: MatDialogRef<WelcomePopupComponent>
  ) {}
}
