import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Case } from 'src/app/core/models/case';
import { CaseService } from 'src/app/core/services/case.service';

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss'],
})
export class CasesComponent implements OnInit {
  cases?: any;

  selectedIndex = 0;
  constructor(private route: ActivatedRoute, private cs: CaseService) {}

  async ngOnInit() {
    const cases = (await this.cs.getAll(20, 0)) as Case[];
    this.cases = cases;

    console.log('*** CASES', cases);
    this.route.queryParams.subscribe((params) => {
      console.log(params); // { orderby: "price" }
      const a = params['open'];
      console.log('CASES ONINIT open', a);
      if (a === 'true') {
        this.selectedIndex = 1;
      } else {
        this.selectedIndex = 0;
      }
    });
  }
}
