import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  type OnInit,
} from '@angular/core';
import {
  QuestionInPage,
  QuestionnaireAnswer,
  QuestionnairePage,
} from 'src/app/core/models/questionnaire.model';
import { PageErrors } from '../questionnaire-answer.component';
import {
  BehaviorSubject,
  debounceTime,
  filter,
  map,
  Observable,
  Subscription,
  tap,
} from 'rxjs';
import { runConditions } from 'src/app/shared/utils/hideif';
import { QuestionAnswer } from '../../../simple/question/question.component';

@Component({
  selector: 'questionnaire-answer-page',
  templateUrl: './questionnaire-answer-page.component.html',
  styleUrls: ['./questionnaire-answer-page.component.scss'],
})
export class QuestionnaireAnswerPageComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() page!: QuestionnairePage;
  @Input() questionnaireAnswer$!: Observable<QuestionnaireAnswer | null>;
  questionnaireAnswer!: QuestionnaireAnswer | null;

  changeAnswer$ = new BehaviorSubject<void>(undefined);

  @Output() onChangeQuestionnaireAnswer =
    new EventEmitter<QuestionnaireAnswer>();
  @Output() onChangePageValid = new EventEmitter<PageErrors>();
  @Output() onLoadingPage = new EventEmitter<boolean>(false);

  questionsMap: Record<string, QuestionInPage> = {};
  hideMap: Record<string, boolean> = {};

  pageLoading$ = new BehaviorSubject<boolean>(true);
  loading = true;
  subscriptions: Subscription[] = [];

  qa$!: Observable<QuestionnaireAnswer>;

  supressNextChange = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this._initObservables();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['page']) {
      console.log('__CAIO page', this.page);
      if (this.page) {
        this.setAnswersOnQuestionsMap();
        this.page?.cp_question_in_page.sort((question1, question2) => {
          const priority1 = question1.priority || 0;
          const priority2 = question2.priority || 0;
          return priority2 - priority1;
        });
      }
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private _initObservables() {
    const answers$ = this.questionnaireAnswer$
      .pipe(
        filter((e) => !!e),
        debounceTime(100)
      )
      .subscribe({
        next: (questionnaireAnswer) => {
          console.log('CAIO7 questionnaireAnswer', questionnaireAnswer);

          if (this.supressNextChange) {
            console.log('Supressing next change');
            this.supressNextChange = false;
            return;
          }

          this.questionnaireAnswer = questionnaireAnswer;
          if (Object.keys(this.questionsMap).length === 0)
            this.setQuestionMap();
          this.setAnswersOnQuestionsMap();
          this.updateQuestionHideMap();
          this.checkValidatePage();

          this.page?.cp_question_in_page.sort((question1, question2) => {
            const priority1 = question1.priority || 0;
            const priority2 = question2.priority || 0;
            return priority2 - priority1;
          });

          this.cdr.markForCheck();
          this.cdr.detectChanges();

          this.loading = false;
          this.pageLoading$.next(false);
        },
      });

    const changeAnswerSub$ = this.changeAnswer$
      .pipe(
        debounceTime(150),
        tap(() => {
          if (!this.questionnaireAnswer) this.pageLoading$.next(false);
        })
      )
      .subscribe({
        next: () => {
          console.log('CAIO7 changeAnswerSub$');
          this.questionnaireAnswer &&
            this.onChangeQuestionnaireAnswer.emit(this.questionnaireAnswer);
        },
      });

    const pageLoadingSub$ = this.pageLoading$.subscribe({
      next: (loading) => {
        // console.log('Page Loading => ', loading);
        this.onLoadingPage.emit(loading);
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      },
    });

    this.qa$ = this.questionnaireAnswer$.pipe(
      filter((e) => !!e),
      map((e) => e as QuestionnaireAnswer)
    );

    this.subscriptions.push(answers$, changeAnswerSub$, pageLoadingSub$);
  }
  private setQuestionMap() {
    if (this.page.cp_question_in_page) {
      this.page.cp_question_in_page.forEach((q) => {
        this.questionsMap[q.cp_question.slug] = {
          ...q,
          cp_question: { ...q.cp_question, answer: { value: null } },
        };
      });
    } else {
      this.questionsMap = {};
    }
    this.hideMap = {};
    Object.keys(this.questionsMap).forEach((key) => {
      this.hideMap[key] = false;
    });

    console.log('qap -> setQuestionMap', { t: this.questionsMap });
  }
  private setAnswersOnQuestionsMap() {
    if (this.questionnaireAnswer) {
      const answers = this.questionnaireAnswer.answers;

      console.log('qap -> setAnswersOnQuestionsMap -> Pre', {
        t: this.questionsMap,
      });

      Object.entries(this.questionsMap).forEach(([key, value]) => {
        const answer = answers[value.cp_question.slug] || { value: null };
        this.questionsMap[key] = {
          ...this.questionsMap[key],
          cp_question: {
            ...this.questionsMap[key].cp_question,
            answer: answer,
          },
        };
      });

      console.log('qap -> setAnswersOnQuestionsMap -> post', {
        t: this.questionsMap,
      });
    }

    console.log('Page Question/Answer Map changed => ', this.questionsMap);
  }
  private updateQuestionHideMap() {
    this.page.cp_question_in_page.forEach((qs) => {
      const questionLogic = qs.question_logic;

      if (!questionLogic || !questionLogic['hide_if']) {
        return;
      }

      const hideIf = questionLogic['hide_if'];

      if (qs && qs.question_logic) {
        // console.log('hideIf', hideIf);

        const evaluateCondition = runConditions(
          hideIf,
          this.questionnaireAnswer
        );

        if (evaluateCondition) {
          this.hideMap[qs.cp_question.slug] = true;
          // Remove answer from questionnaire
          this.supressNextChange = true;
          this.onChangeAnswer({
            question: qs.cp_question,
            answer: { value: null },
          });
        } else {
          this.hideMap[qs.cp_question.slug] = false;
        }
      } else {
        this.hideMap[qs.cp_question.slug] = false;
      }
    });

    console.log('Hide Map => ', this.hideMap);
  }
  private checkValidatePage() {
    const errors: PageErrors = {
      page: this.page.id,
      errors: {},
      isValid: true,
    };

    this.page.cp_question_in_page.forEach((q) => {
      if (
        this.hideMap[q.cp_question.slug] ||
        !q.question_logic ||
        q.cp_question.response_type == 'EXPLANATION'
      )
        return;

      console.log('Checking page -> question', {
        slug: q.cp_question.slug,
        logic: q.question_logic,
        val: this.questionnaireAnswer?.answers[q.cp_question.slug],
      });

      if (
        !q.question_logic['required'] &&
        !this.questionnaireAnswer?.answers[q.cp_question.slug]
      )
        return;

      if (
        q.cp_question.isValid === undefined &&
        q.question_logic['required'] &&
        !this.questionnaireAnswer?.answers[q.cp_question.slug]
      ) {
        errors.isValid = false;
        errors.errors[
          q.cp_question.slug
        ] = `Question "${q.cp_question.name}" is required`;
      } else if (!q.cp_question.isValid) {
        console.log('Question is invalid => ', q.cp_question);

        errors.isValid = false;
        errors.errors[q.cp_question.slug] = q.cp_question.messageError
          ? `Question "${q.cp_question.name}" ${q.cp_question.messageError}`
          : `Question "${q.cp_question.name}" is invalid`;
      }
    });

    // console.log('Page Errors => ', errors);
    this.supressNextChange = true;
    this.onChangePageValid.emit(errors);
  }

  public onChangeAnswer(answer: QuestionAnswer) {
    this.pageLoading$.next(true);
    if (!this.questionnaireAnswer) {
      this.pageLoading$.next(false);
      return;
    }

    const index = this.page.cp_question_in_page.findIndex(
      (q) => q.cp_question.slug === answer.question.slug
    );
    if (index === -1) {
      this.pageLoading$.next(false);
      return;
    }
    this.page.cp_question_in_page[index].cp_question = answer.question;

    this.questionnaireAnswer.answers[answer.question.slug] = answer.answer;

    // console.log('Emitting new change answer => ', this.questionnaireAnswer);
    // console.log('CAIO7 onChangeAnswer');

    this.changeAnswer$.next();
  }
}
