import { Injectable } from '@angular/core';

import {
  DatabaseService,
  Filter,
  QueryResult,
  Sort,
} from './base/database.service';
import { Faq } from '../models/faq';

import { TextFilterConfig } from 'src/app/shared/components/smart/table/table.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FaqService extends DatabaseService<Faq> {
  constructor() {
    super('cp_faq');
  }

  getAllGroupedByCase() {
    return this.supabase.from('cp_case').select('*, cp_faq(*)');
  }

  override list(
    pageIndex?: number,
    pageSize?: number,
    filters?: Filter[],
    sort?: Sort[],
    search?: TextFilterConfig
  ): Observable<QueryResult<Faq>> {
    const newSort = sort || [];
    newSort.push({
      column: 'priority',
      direction: 'asc',
    });
    return super.list(pageIndex, pageSize, filters, newSort, search);
  }
}
