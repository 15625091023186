import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
  type OnInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-html',

  templateUrl: './input-html.component.html',
  styleUrls: ['./input-html.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputHtmlComponent),
      multi: true,
    },
  ],
})
export class InputHtmlComponent {
  @Input() value: any;
  @Input() id: string = 'id';
  @Input() name: string = 'id';
  @Input() label: string = 'Input';

  @Input() height: string = '200px';

  @Input() error: string = '';

  visibleValue: boolean = false;

  @Output() onChange$: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFocus$: EventEmitter<void> = new EventEmitter<void>();

  @Output() onEditorCreated$: EventEmitter<any> = new EventEmitter<any>();
  @Output() onContentChanged$: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectionChanged$: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditorChanged$: EventEmitter<any> = new EventEmitter<any>();

  onChange: any = () => {};
  onTouched: any = () => {};

  @Input() modules: any;

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  emitChange(value: any): void {
    this.value = value;
    this.onChange(value);
    this.onChange$.emit(value);
  }

  emitFocus(): void {
    this.onTouched();
    this.onFocus$.emit();
  }
}
