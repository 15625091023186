import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-clear',
  templateUrl: './button-clear.component.html',
  styleUrls: ['./button-clear.component.scss'],
})
export class ButtonClearComponent {
  @Input() type!: string;
  @Input() size!: string;
}
