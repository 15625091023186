import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  type OnInit,
} from '@angular/core';
import { Column } from '../../table.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Filter } from 'src/app/core/services/base/database.service';
import { isDate } from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-filter-date',

  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss'],
})
export class FilterDateComponent implements OnInit, OnChanges {
  @Input('column') column!: Column;
  @Output('columnChanges') columnChanges: EventEmitter<Column> =
    new EventEmitter();

  currentColumn!: Column;

  operator: 'gt' | 'lt' | 'bt' = 'gt';

  from: Date = new Date();
  to: Date = new Date();

  operators = [
    {
      label: 'After',
      value: 'gt',
    },
    {
      label: 'Before',
      value: 'lt',
    },
    {
      label: 'Between',
      value: 'bt',
    },
  ];

  filters: Filter[] = [];
  constructor() {}

  ngOnInit(): void {
    console.log('Filter-Text', this);
    this.from = new Date();
    this.to = new Date();
    if (this.column.filters[0]) {
      this.currentColumn = this.column;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('Filter-Text changes', changes);
    if (changes['column'] && changes['column'].currentValue) {
      this.currentColumn = changes['column'].currentValue;
      // Atualizar filtros com a nova coluna, se necessário
      if (this.currentColumn.filters) {
        this.filters = this.currentColumn.filters;

        const f: Filter | undefined = this.filters.find(
          (f) => f.operator === 'gte'
        );
        const t: Filter | undefined = this.filters.find(
          (f) => f.operator === 'lte'
        );

        this.from =
          f && f.value && isDate(new Date(f.value as string))
            ? new Date(f.value as string)
            : new Date();
        this.to =
          t && t.value && isDate(new Date(t.value as string))
            ? new Date(t.value as string)
            : new Date();

        this.operator = f && t ? 'bt' : f ? 'gt' : 'lt';
      }
    }
  }

  getOperatorLabel(op: string): string {
    return this.operators.find((o) => o.value === op)?.label || '';
  }

  clearFilter() {
    this.filters = [];
    this.operator = 'gt';
    this.from = new Date();
    this.to = new Date();

    this.columnChanges.emit({ ...this.currentColumn, filters: this.filters });
  }
  addFilter() {
    console.log('From Date', this.from);
    console.log('To Date', this.to);

    const newFilter: Filter[] = [];
    if (this.operator === 'bt') {
      if (this.from && this.to) {
        newFilter.push({
          column: this.column.id,
          operator: 'gte',
          value: this.from.toISOString(),
          visible: false,
        });
        newFilter.push({
          column: this.column.id,
          operator: 'lte',
          value: this.to.toISOString(),
          visible: false,
        });
      }
    } else if (this.operator === 'gt') {
      newFilter.push({
        column: this.column.id,
        operator: 'gte',
        value: this.from.toISOString(),
        visible: false,
      });
    } else if (this.operator === 'lt') {
      newFilter.push({
        column: this.column.id,
        operator: 'lte',
        value: this.from.toISOString(),
        visible: false,
      });
    }
    console.log('newFilter', newFilter);

    this.filters = [...newFilter];

    this.columnChanges.emit({ ...this.currentColumn, filters: this.filters });
  }
  removeFilter(filter: number) {
    if ((this.filters[filter].operator = 'gte')) {
      this.operator = 'lt';
    }
    if ((this.filters[filter].operator = 'lte')) {
      this.operator = 'gt';

      this.filters.splice(filter, 1);
      if (this.filters.length === 0) {
        this.operator = 'gt';
        this.from = new Date();
        this.to = new Date();
      }

      this.columnChanges.emit({ ...this.currentColumn, filters: this.filters });
    }
  }

  getFormattedData(data: any) {
    return moment(new Date(data)).format('DD/MM/YYYY');
  }
}
