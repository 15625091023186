import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@supabase/supabase-js';
import { ToastService } from 'angular-toastify';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  email!: string;
  user: User | null = null;
  session: any = null;
  token?: string;

  step: 'email' | 'code' | 'password' | 'success' = 'email';
  subs: Subscription[] = [];
  constructor(
    private router: Router,
    private actRoute: ActivatedRoute,
    private us: UserService,
    private ts: ToastService
  ) {}

  ngOnInit() {
    this.subs.push(
      this.actRoute.queryParams.subscribe(async (params) => {
        if (params['email']) {
          this.email = params['email'];
          this.step = 'code';
        }
        if (params['token']) {
          try {
            this.token = params['token'];
            console.log('Token Found');
            await this.us.getSessionWithPasswordResetToken(this.token!);
            console.log('AfterToken');
            this.step = 'password';
          } catch (error: any) {
            this.ts.error(error.toString());
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  stepBack() {
    switch (this.step) {
      case 'email':
        this.router.navigateByUrl('/auth/login');
        break;
      case 'code':
        this.step = 'email';
        break;
      case 'password':
        this.step = 'code';
        break;
    }
  }

  async emailSended(email: string) {
    this.email = email;
    this.step = 'code';
  }

  async codeVerified(user: any, session: any) {
    this.user = user;
    this.session = session;
    this.step = 'password';
  }

  async passwordChanged() {
    this.step = 'success';
  }
}
