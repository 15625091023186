import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select } from '@ngneat/elf';
import { UserResponse } from '@supabase/supabase-js';
import { Observable, filter, from, map, switchMap } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import {
  CommunicationService,
  ThreadWithMessages,
} from 'src/app/core/services/communication.service';
import { UserService } from 'src/app/core/services/user.service';
import { WelcomePopupComponent } from './shared/smart/welcome-popup/welcome-popup.component';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent {
  user$!: Observable<User | null>;
  notifications$?: Observable<ThreadWithMessages[]>;
  constructor(
    private cs: CommunicationService,
    private us: UserService,
    public dialog: MatDialog
  ) {
    this.user$ = from(this.us.getCurrentUser());

    this.notifications$ = this.user$.pipe(
      filter((u) => !!u),
      map((u) => u!),
      switchMap((u) => {
        return this.cs.listCommunicationsWithMessagesForUser(u.id!);
      }),
      map((ev) => {
        // console.log('listCommunicationsWithMessagesForUser', ev);

        return ev;
      })
    );
  }

  async ngOnInit() {
    const ll = localStorage.getItem('lastLogin');
    if (!ll) {
      const dialogRef = this.dialog.open(WelcomePopupComponent, {
        data: {},
        width: '90vw',
        maxWidth: '600px',
        height: 'auto',
        minHeight: '400px',
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        localStorage.setItem('lastLogin', 'true');
      });
    }

    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }
}
