import { BehaviorSubject, switchMap } from 'rxjs';
import {
  ClaimRequirement,
  ClaimRequirementStatus,
  ClaimWithCase,
} from './claims.service';
import {
  Message,
  MessageService,
  ReadEntry,
} from './communication_message.service';

import { Base } from '../models/base.model';
import { Claim } from '../models/claim';
import { DatabaseService } from './base/database.service';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService extends DatabaseService<Thread> {
  notifier: BehaviorSubject<boolean> = new BehaviorSubject(true);
  constructor(private us: UserService, private ms: MessageService) {
    super('cp_thread');
  }

  notifyChanged() {
    this.notifier.next(true);
  }

  listCommunicationsWithMessagesForUser(fromUserId: string) {
    return this.notifier.pipe(
      switchMap(async (e) => {
        const query = this.supabase
          .from('cp_thread')
          .select('*, cp_message (*)')
          .eq('started_by_user_id', fromUserId);
        const { data, error } = await query;

        if (error) throw new Error(error.message);

        const procData = data as unknown as ThreadWithMessages[];
        // console.log('COMM SERVICE', data);

        return procData.filter((t) => checkIfIsUnread(t));
      })
    );
  }

  async sendMessage(m: Partial<Message>) {
    const ur: ReadEntry = {
      device: this.us.getFingerprint(),
      timestamp: new Date(),
      userId: (await this.us.getCurrentUser())?.id!,
    };
    m.user_reads = [ur];
    //@ts-ignore
    return this.supabase.from('cp_message').insert(m).select().single();
  }

  async addReadToThread(thread_id: string) {
    const messages = (
      await this.supabase
        .from('cp_message')
        .select('*')
        .eq('thread_id', thread_id)
    ).data as unknown as Message[];

    if (!messages) {
      throw new Error('Thread does not contain messages');
    }

    const newReadEntry: ReadEntry = {
      device: this.us.getFingerprint(),
      timestamp: new Date(),
      userId: (await this.us.getCurrentUser())!.id!,
    };

    const proms = messages.map((m) => {
      delete m.text_search;
      if (!m.user_reads || !Array.isArray(m.user_reads)) {
        m.user_reads = [];
      }
      m.user_reads.push(newReadEntry);
      return this.ms.update(m);
    });

    Promise.all(proms).then((e) => this.notifyChanged());
  }

  async getCommunicationsWithMessagesForThread(id: string) {
    const query = this.supabase
      .from('cp_thread')
      .select('*, cp_message (*))')
      .eq('id', id);
    const { data, error } = await query;

    if (error) throw new Error(error.message);

    console.log('COMM SERVICE', data);

    return data[0] as unknown as ThreadWithMessages;
  }

  async listCommunicationsWithMessagesForClaim(claimId: string) {
    const query = this.supabase
      .from('cp_thread')
      .select('*, cp_message (*))')
      .eq('related_claim_id', claimId);
    const { data, error } = await query;

    console.log(
      'Comm Service -> listCommunicationsWithMessagesForClaim',
      claimId,
      data
    );

    if (error) throw new Error(error.message);

    return data as unknown as ThreadWithMessages[];
  }
  async getCommunicationsWithMessagesAndCaseForThread(
    id: string
  ): Promise<ThreadWithMessagesAndClaimCase> {
    try {
      const query = this.supabase
        .from('cp_thread')
        .select(
          `
          *,
          cp_message (*, cp_user!cp_message_author_user_id_fkey(*)),
          cp_claim (*, cp_case(*))
        `
        )
        .eq('id', id);

      const { data, error } = await query;

      if (error) {
        throw new Error(error.message);
      }

      console.log('COMM SERVICE', data);

      this.addReadToThread(id);
      return data[0] as unknown as ThreadWithMessagesAndClaimCase;
    } catch (error) {
      console.error(
        'Error fetching communications with messages and case for thread:',
        error
      );
      throw error;
    }
  }

  async listCommunicationsWithMessagesAndCaseForClaim(claimId: string) {
    const query = this.supabase
      .from('cp_thread')
      .select('*, cp_message (*), cp_claim(*, cp_case(*))')
      .eq('related_claim_id', claimId);
    const { data, error } = await query;

    console.log(
      'Comm Service -> listCommunicationsWithMessagesForClaim',
      claimId,
      data
    );

    if (error) throw new Error(error.message);

    return data as unknown as ThreadWithMessagesAndClaimCase[];
  }
  async listCommunicationsWithMessagesAndCaseForClaimList(claims: string[]) {
    const query = this.supabase
      .from('cp_thread')
      .select('*, cp_message (*), cp_claim(*, cp_case(*))')
      .in('related_claim_id', claims);
    const { data, error } = await query;

    console.log(
      'Comm Service -> listCommunicationsWithMessagesForClaim',
      claims,
      data
    );

    if (error) throw new Error(error.message);

    return data as unknown as ThreadWithMessagesAndClaimCase[];
  }

  async userRelatedCommunications(
    claims: string[],
    userID: string,
    pagination: { page: number; limit: number } = {
      page: 0,
      limit: 10,
    }
  ) {
    console.log('Comm Service -> userRelatedCommunications', claims, userID);

    const query = this.supabase
      .from('cp_thread')
      .select('*, cp_message (*), cp_claim(*, cp_case(*))', {
        count: 'estimated',
      })
      .in('related_claim_id', claims)
      .range(
        pagination.page * pagination.limit,
        (pagination.page + 1) * pagination.limit - 1
      );
    // .eq('started_by_user_id', userID);
    // .eq(`cp_message.author_user_id`, userID);

    // .or(
    //   `started_by_user_id.eq.${userID},cp_message.author_user_id.eq.${userID}`
    // );

    const { data, count, error } = await query;
    if (error) throw new Error(error.message);

    return { data: data as unknown as ThreadWithMessagesAndClaimCase[], count };
  }

  closeThread(id: string) {
    return this.supabase
      .from('cp_thread')
      .update({ open_for_replies: false })
      .eq('id', id)
      .select()
      .single();
  }

  CWMToRequirements(cm: ThreadWithMessages[]): ClaimRequirement[] {
    if (!cm || cm.length == 0) {
      return [];
    }
    const thereq: ClaimRequirement = {
      claimId: cm[0].related_claim_id!,
      status: ClaimRequirementStatus.UNMET,
      requirement: {
        description: 'Unread message',
        chaseCycle: [],
        completionCriteria: 'Read message',
        config: {
          count: 0,
        },
        deadline: new Date(),
        headline: 'Unread message',
        lastUpdated: new Date(cm[0].created_at!),
        priority: 'MEDIUM',
        status: 'ACTIVE',
        type: 'MESSAGE',
      },
    };

    console.log('Comm service -> CWMToRequirements', cm);

    for (let i = 0; i < cm.length; i++) {
      const c = cm[i];

      const ur = c.cp_message!.reduce((prev, curr) => {
        let val = 0;
        if (curr.user_reads && curr.user_reads.length) {
          val = curr.user_reads.length;
        }
        return val + prev;
      }, 0);

      if (ur > 0) {
        thereq.requirement.config.count++;
      }
    }

    if (thereq.requirement.config.count > 0) {
      return [thereq];
    } else {
      return [];
    }
  }
}

class Thread extends Base {
  subject?: string;
  medium?: 'CHAT' | 'INTERNAL_MESSAGE' | 'EMAIL';
  related_claim_id?: string;
  started_by_user_id?: string;
  open_for_replies?: boolean;
  attributes?: any;
  language?: string;
  started_at?: Date;
}

export class ThreadWithMessages extends Thread {
  cp_message?: Message[];
}
export class ThreadWithMessagesAndClaimCase extends Thread {
  cp_message?: Message[];
  cp_claim?: ClaimWithCase;
}

export function checkIfIsUnread(t: ThreadWithMessagesAndClaimCase): boolean {
  // console.log('Checking if unread', t);

  if (!t.cp_message) {
    return true;
  }

  // console.log('Checking if unread > has messages', t.cp_message);

  return (
    t.cp_message!.filter((m) => !m.user_reads || !m.user_reads.length).length >
    0
  );
}
