import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/core/services/base/database.service';
import { DocumentService } from 'src/app/core/services/base/document.service';
import { CaseService } from 'src/app/core/services/case.service';
import { ClaimsService } from 'src/app/core/services/claims.service';
import { ContractService } from 'src/app/core/services/contract.service';
import { SignedContractService } from 'src/app/core/services/signed_contract.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-verify-doc',
  templateUrl: 'verify-doc.component.html',
  styleUrls: ['verify-doc.component.scss'],
})
export class VerifyDocComponent implements OnInit {
  data: any | null = null;

  showNotAuthorized = false;
  constructor(
    private actRoute: ActivatedRoute,
    private scs: SignedContractService,
    private cts: ContractService,
    private cls: ClaimsService,
    private cs: CaseService,
    private us: UserService
  ) {}

  async ngOnInit() {
    try {
      const docId = this.actRoute.snapshot.queryParams['id'];
      if (!docId) {
        console.error('No id');
        return;
      }
      // console.log('docId', docId);
      // const testDocs = await this.scs.getAll(1, 0);
      // console.log('testDocs', testDocs);
      let doc: any;
      try {
        doc = await this.scs.getByIdWithRelations(docId);
        console.log('doc', doc);

        if (!doc) {
          console.error('No doc');
          throw new Error('No doc');
        }
      } catch (error) {
        // Not found or unauthorized

        this.showNotAuthorized = true;
      }

      if (!doc) {
        //TODO: Error log
        return;
      }

      // Get other data
      // const docLink = await this.scs.doDownload(doc.id!);
      const docLink = await this.scs.doDownloadToSignedContract(
        'claim-documents',
        doc.url ?? `${doc.cp_claim.id}/${doc.id}/${doc.cp_contract.id}.pdf`
      );
      const caseData = await this.cs.get(doc.cp_claim.case_id);

      this.data = {
        ...doc,
        claim: { ...doc.cp_claim, case: caseData },
        contract: doc.cp_contract,
        signer: { ...doc.cp_user, cp_pii: doc.cp_user.cp_pii[0] },
        downloadLink: docLink.data?.signedUrl,
      };

      console.log(this.data);
    } catch (error) {
      this.showNotAuthorized = true;
    }
  }
}
