import { Component, Input, OnInit } from '@angular/core';
import { News } from 'src/app/core/models/news';
import { NewsService } from 'src/app/core/services/news.service';

@Component({
  selector: 'app-case-teaser-list',
  templateUrl: './case-teaser-list.component.html',
  styleUrls: ['./case-teaser-list.component.scss'],
})
export class CaseTeaserListComponent implements OnInit {
  news?: News[];

  @Input() multiClaims?: boolean;

  constructor(private ns: NewsService) {}

  async ngOnInit() {
    setTimeout(async () => {
      this.news = await this.ns.getByHighlightPriority();
      console.log(this.news);
    }, 200);
  }
}
