import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimsService } from 'src/app/core/services/claims.service';
import {
  CommunicationService,
  ThreadWithMessagesAndClaimCase,
} from 'src/app/core/services/communication.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss'],
})
export class CommunicationsComponent implements OnInit {
  claimId: string | null = null;
  threads!: { data: ThreadWithMessagesAndClaimCase[]; count: number | null };
  claim: any;

  loading: boolean = true;
  constructor(
    private cs: CommunicationService,
    private cls: ClaimsService,
    private us: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}
  async ngOnInit(): Promise<void> {
    console.log('Communications Component =< Hello again');
    this.claimId = this.activatedRoute.snapshot.paramMap.get('claim');

    if (this.claimId) {
      this.claim = await this.cls.getClaimWithCase(this.claimId);
    }

    await this.getData();
  }

  async getData() {
    try {
      if (!this.claimId) {
        const user = await this.us.getCurrentUser();
        const claims = await this.cls.getAllClaimsForUserId(user!.id!);
        const claimsId: string[] = claims ? claims.map((c) => c.id!) : [];

        console.log('CLAIMS', claimsId);

        const threads = await this.cs.userRelatedCommunications(
          claimsId,
          user!.id!
        );

        console.log('THREADS', threads);
        this.threads = threads;
      } else {
        this.threads = {
          data: await this.cs.listCommunicationsWithMessagesAndCaseForClaim(
            this.claimId!
          ),
          count: 0,
        };
      }
    } catch (error) {
      console.error(error);
      this.router.navigate(['client']);
    } finally {
      this.loading = false;
    }
  }

  goToNewMessage() {
    console.log('Go to new message! =)');
    this.router.navigate(['client/messages/new/new']);
  }

  goToMessage() {
    this.router.navigate(['/client/messages']);
  }

  goToMessageWithClaim() {
    this.router.navigate(['/client/messages', this.claimId ?? '']);
  }
}
