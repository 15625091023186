import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Action } from 'src/app/core/models/action.model';

@Component({
  selector: 'app-oa-questionnaire',
  templateUrl: './oa-questionnaire.component.html',
  styleUrls: ['./oa-questionnaire.component.scss', '../oa-styles.scss'],
})
export class OaQuestionnaireComponent {
  @Input() action!: Action;
  @Output() changed = new EventEmitter<boolean>();

  checkDependsOn() {
    return !(
      (this.action.prstatus as any) === 'DONE' ||
      (this.action.prstatus as any) === null
    );
  }
}
