import {Component} from '@angular/core';
import {MatDialogRef, MatDialog} from '@angular/material/dialog';
import {TermsOfUseModalComponent} from '../terms-of-use-modal/terms-of-use-modal.component';

@Component({
  selector: 'app-privacy-notice-modal',
  templateUrl: './privacy-notice-modal.component.html',
  styleUrls: ['./privacy-notice-modal.component.scss'],
})
export class PrivacyNoticeModalComponent {
  constructor(
    public dialogRef: MatDialogRef<PrivacyNoticeModalComponent>,
    public dialog: MatDialog
  ) {
  }

  close(): void {
    this.dialogRef.close();
  }

  openTerms(): void {
    this.dialogRef.close();
    this.dialog.open(TermsOfUseModalComponent);
  }
}
