import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pending-signature',
  templateUrl: './pending-signature.component.html',
  styleUrls: ['./pending-signature.component.scss'],
})
export class PendingSignatureComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { contract_id: string; claim_id: string }
  ) {}
}
