import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caseStatus',
})
export class CaseStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'in_progress':
        return 'In progress';

      case 'pending':
        return 'On hold';

      case 'closed':
        return 'Closed';

      default:
        return 'In Progress';
    }
  }
}
