import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ClaimRequirement,
  ClaimRequirementStatus,
} from 'src/app/core/services/claims.service';
import { UserService } from 'src/app/core/services/user.service';
import { EletronicSignatureModalComponent } from 'src/app/modules/client/shared/smart/electronic-signature/components/eletronic-signature-modal/eletronic-signature-modal.component';
import { DocumentUploadModalComponent } from 'src/app/shared/components/simple/document-upload-modal/document-upload-modal.component';
import { DownloadDocumentComponent } from './modals/download-document/download-document.component';
import { PendingSignatureComponent } from './modals/pending-signature/pending-signature.component';

@Component({
  selector: 'app-os-action-item',
  templateUrl: './os-action-item.component.html',
  styleUrls: ['./os-action-item.component.scss'],
})
export class OsActionItemComponent {
  @Input() public requirement?: ClaimRequirement;
  @Output() changed = new EventEmitter<boolean>();

  constructor(public dialog: MatDialog, private us: UserService) {}

  async uploadDialog() {
    const uid = (await this.us.getCurrentUser())?.id;

    if (!this.requirement) {
      throw new Error('No requirement loaded');
    }

    if (!uid) {
      throw new Error('No user logged in');
    }

    // @TODO: Improve and make dynamic
    const dialogData: {
      bucket: string;
      name: string;
      type: 'PERSONAL' | 'CLAIM';
      multiple: boolean;
      ref: string;
    } = {
      bucket: this.requirement.requirement.config.bucket,
      name: this.requirement.requirement.config.document_title,
      multiple: false,
      ref: uid,
      type: 'PERSONAL',
    };

    const dialogRef = this.dialog.open(DocumentUploadModalComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.changed.emit(true);
    });
  }

  signatureDialog() {
    // If No signature or failed, display signing pad.
    let dialogRef;
    if (this.requirement?.status == ClaimRequirementStatus.UNMET) {
      dialogRef = this.dialog.open(EletronicSignatureModalComponent, {
        data: {
          contract_id: this.requirement?.requirement.config.contract_id,
          claim_id: this.requirement?.claimId,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
        this.changed.emit(true);
      });
    } else if (this.requirement?.data.status === 'SIGNED') {
      // If there is a signature and it's fine, display popup with download option
      dialogRef = this.dialog.open(DownloadDocumentComponent, {
        data: {
          signed_document_id: this.requirement?.data.ref,
        },
      });
    } else {
      // If there is a signature, but its pending, display please wait.
      dialogRef = this.dialog.open(PendingSignatureComponent, {
        data: {
          signed_document_id: this.requirement?.data.ref,
        },
      });
    }
  }
}
