import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/core/models/user.model';
import { DocumentDeleteDocumentComponent } from '../../dialog/document-delete-document/document-delete-document.component';
import { DocumentUploadErrorComponent } from '../../dialog/document-upload-error/document-upload-error.component';
import { DocumentUploadPhotoComponent } from '../../dialog/document-upload-photo/document-upload-photo.component';
import { DocumentPreviewComponent } from '../../dialog/document-preview/document-preview.component';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent {
  user: User | null = null;

  documents = [
    {
      id: '1',
      name: 'National ID Card',
      file: {
        name: 'Id-mariju004-final.pdf',
        src: 'assets/doc.jpg',
      },
    },
    {
      id: '2',
      name: `Driver's License`,
      file: {
        name: 'Id-mariju004-final.pdf',
        src: 'assets/doc.jpg',
      },
    },
    {
      id: '3',
      name: 'National Insurance Number (NIN)',
      file: null,
    },
  ];
  constructor(private matDialog: MatDialog) {}

  openDeleteDocumentDialog(id: string) {
    const dialogRef = this.matDialog.open(DocumentDeleteDocumentComponent, {
      data: { id },
    });
    dialogRef.afterClosed().subscribe((e) => {
      console.log('after document delete dialog closed', e);

      if (e === 'y') {
        const findIndex = this.documents.findIndex((doc) => doc.id === id);
        this.documents[findIndex].file = null;
      }
    });
  }
  openUploadError() {
    this.matDialog.open(DocumentUploadErrorComponent);
  }
  openDocumentPreview(id: string) {
    this.matDialog.open(DocumentPreviewComponent, {
      data: {
        id,
        imageData: this.documents.find((doc) => doc.id === id)?.file,
      },
    });
  }
  openUploadPhoto(id: string) {
    const dialogRef = this.matDialog.open(DocumentUploadPhotoComponent, {
      data: { id },
    });

    dialogRef.afterClosed().subscribe((e) => {
      console.log('after document upload dialog closed', e);
      if (e !== null) {
        const findIndex = this.documents.findIndex((doc) => doc.id === id);
        this.documents[findIndex].file = {
          name: e.name,
          src: e.src,
        };
      }
    });
  }
}
