import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';

// Load the docx file as binary content

export function applyTemplate(
  buffer: ArrayBuffer,
  data: any,
  filename: string
) {
  const zip = new PizZip(buffer, { base64: false });

  // Load the docx file as binary content
  const doc = new Docxtemplater(zip);

  // Define the data to replace the placeholders
  // const data = {
  //   title: "Mr.",
  //   address: "123 Main St",
  //   date: new Date().toLocaleDateString(),
  //   firstName: "Test",
  //   lastName: "Testerson",
  //   addressLine1: "Address Line 1",
  //   addressLine2: "Address Line 2",
  //   city: "Testerville",
  //   postcode: "ABC123",
  //   country: "United Kingdom",
  // };

  doc.setData(data);

  doc.render();

  // Generate the output file
  const output = doc.getZip().generate({ type: 'base64' });
  console.log('output', output);

  const blob = b64toBlob(output);
  saveData(blob, filename);
}

const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  console.log('blob', blob);
  console.log('bytes', byteArrays);
  return blob;
};

export var saveData = (function () {
  var a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  return function (blob: any, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();
