import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'src/app/core/models/comms.model';
import {
  CommunicationService,
  ThreadWithMessagesAndClaimCase,
} from 'src/app/core/services/communication.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-details-communication',
  templateUrl: './details-communication.component.html',
  styleUrls: ['./details-communication.component.scss'],
})
export class DetailsCommunicationComponent implements OnInit {
  thread: ThreadWithMessagesAndClaimCase | null = null;
  user: any;

  loading: boolean = true;
  loadingMessages: boolean = true;
  loadingSend: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cs: CommunicationService,
    private us: UserService
  ) {}
  async ngOnInit(): Promise<void> {
    this.user = await this.us.getCurrentUser();
    this.activatedRoute.params.subscribe(async (params) => {
      console.log('PARAMS', params);
      const id = params['thread'] ?? null;
      console.log('ID 2', id);

      if (id === null) {
        // this.router.navigate(['/client/messages']);
      } else {
        this.thread =
          await this.cs.getCommunicationsWithMessagesAndCaseForThread(id);

        console.log('THREAD', this.thread);
      }
    });
  }

  async sendMessage(message: string) {
    console.log('SEND MESSAGE', message);
    const newMessage: Message = {
      value: message,
      thread_id: '',
      author_user_id: this.user?.id ?? '-',

      type_message: 'INTERNAL_MESSAGE',
      author_display_name: '',
      attachments: {},
      user_agent: {},
      metadata: {},
      user_reads: [],
    };

    try {
      await this.cs.sendMessage(newMessage);
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingSend = false;
    }
  }

  getNewestMessageFromThread(thread: ThreadWithMessagesAndClaimCase) {
    // Sort messages and get the most recent
    return thread.cp_message?.sort((a, b) => {
      return (
        new Date(b.created_at!).getTime() - new Date(a.created_at!).getTime()
      );
    })[0];
  }

  goToMessage() {
    this.router.navigate(['/client/messages']);
  }

  goToMessageWithClaim() {
    this.router.navigate(['/client/messages', this.thread?.cp_claim!.id ?? '']);
  }
}
