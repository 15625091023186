import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  email!: string;
  password!: string;
  message!: string;
  form!: FormGroup;
  isLoading!: boolean;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private toastService: ToastService
  ) {}

  passwordMatchingValidatior: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
    return password?.value === confirmPassword?.value
      ? null
      : { notmatched: true };
  };

  ngOnInit() {
    this.form = this.fb.group(
      {
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: this.passwordMatchingValidatior }
    );
  }

  async onSubmit() {
    try {
      this.isLoading = true;

      const password = this.form.get('password')?.value;

      if (!this.form.valid) {
        throw Error('Invalid Password Match');
      }
      const { data, error } = await this.userService.updatePassword(password);

      if (error) throw Error(error.message);

      this.toastService.success('Your password has been changed!');

      this.router.navigateByUrl('/auth/login');
    } catch (error: any) {
      this.toastService.error(error.message);
    } finally {
      this.isLoading = false;
    }
  }
}
