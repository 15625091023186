import { Injectable } from '@angular/core';
import {
  QuestionInPage,
  Questionnaire,
  QuestionnaireAnswer,
  QuestionnairePage,
  QuestionnaireWithQuestions,
} from '../models/questionnaire.model';
import { DatabaseService } from './base/database.service';
import { ClaimWithCase } from './claims.service';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

export interface QuestionnaireWithQuestionsWithContext {
  questionnaire: Questionnaire;
  questions_map: { [id: string]: QuestionInPage };
}

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService extends DatabaseService<Questionnaire> {
  constructor(private http: HttpClient) {
    super('cp_questionnaire');
  }

  getQuestionnaireWithQuestionsBySlug(
    slug: string
  ): Promise<QuestionnaireWithQuestions> {
    return new Promise((res, rej) => {
      this.supabase
        .from(`cp_questionnaire`)
        .select(
          `*,cp_questionnaire_page!cp_questionnaire_page_questionnaire_id_fkey(*, cp_question_in_page(*,cp_question(*)))`
        )
        .filter('slug', 'eq', slug)
        .then((e) => {
          if (e.error) {
            rej(e);
          } else {
            const data: Questionnaire = e.data[0] as unknown as Questionnaire;
            const questions_map: { [id: string]: QuestionInPage } = {};

            for (const page of data.cp_questionnaire_page) {
              for (const questionInPage of page.cp_question_in_page) {
                const question = questionInPage;
                questions_map[question.question_id] =
                  question as unknown as QuestionInPage;
              }
            }

            const qwq: QuestionnaireWithQuestionsWithContext = {
              questionnaire: data as unknown as Questionnaire,
              questions_map,
            };
            res(qwq);
          }
        });
    });
  }
  getQuestionnaireWithQuestionsById(
    id: string
  ): Promise<QuestionnaireWithQuestions> {
    return new Promise((res, rej) => {
      this.supabase
        .from(`cp_questionnaire`)
        .select(
          `*,cp_questionnaire_page!cp_questionnaire_page_questionnaire_id_fkey(*, cp_question_in_page(*,cp_question(*)))`
        )
        .eq('id', id)
        .single()
        .then((e) => {
          if (e.error) {
            rej(e);
          } else {
            const data: Questionnaire = e.data as unknown as Questionnaire;
            const questions_map: { [id: string]: QuestionInPage } = {};

            for (const page of data.cp_questionnaire_page) {
              for (const questionInPage of page.cp_question_in_page) {
                const question = questionInPage;
                questions_map[question.question_id] =
                  question as unknown as QuestionInPage;
              }
            }

            const qwq: QuestionnaireWithQuestionsWithContext = {
              questionnaire: data as unknown as Questionnaire,
              questions_map,
            };
            res(qwq);
          }
        });
    });
  }

  getSurveyByCase(caseId: string): Promise<Questionnaire[]> {
    return new Promise((res, rej) => {
      this.supabase
        .from(`cp_questionnaire`)
        .select(`*,pages:survey_pages(*,questions:survey_questions(*))`)
        .filter('case_id', 'eq', caseId)
        .then((e) => {
          if (e.error) {
            rej(e);
          } else {
            res(e.data as unknown as Questionnaire[]);
          }
        });
    });
  }

  getQuestionnaireWithQuestionsByCase(
    case_id: string
  ): Promise<QuestionnaireWithQuestions[]> {
    return new Promise((res, rej) => {
      this.supabase
        .from(`cp_questionnaire`)
        .select(`*,questions:cp_question(*)`)
        .filter('case_id', 'eq', case_id)
        .then((e) => {
          if (e.error) {
            rej(e);
          } else {
            const ret = [];
            for (let i = 0; i < e.data.length; i++) {
              const val = e.data[i];
              const { questions, ...q } = val;
              const map: { [id: string]: QuestionInPage } = {};
              (questions as unknown as QuestionInPage[]).forEach((q) => {
                map[q.cp_question.slug] = q;
              });
              const qwq: QuestionnaireWithQuestions = {
                questionnaire: q as unknown as Questionnaire,
                questions_map: map,
              };
              ret.push(qwq);
            }

            res(ret);
          }
        });
    });
  }

  getSurveysWithAnswersByClaim(
    claim: ClaimWithCase
  ): Promise<SurveyWithAnswers[]> {
    return new Promise((res, rej) => {
      this.supabase
        .from('cp_questionnaire')
        .select(`*,answers:cp_questionnaire_answer(*)`)
        .filter('case_id', 'eq', claim.case_id)
        .filter('cp_questionnaire_answer.claim_id', 'eq', claim.id)
        .then((e) => {
          if (e.error) {
            rej(e);
          } else {
            res(e.data as unknown as SurveyWithAnswers[]);
          }
        });
    });
  }

  async getQuestionnairePages(q: string) {
    const { data, error } = await this.supabase
      .from(`cp_questionnaire`)
      .select(
        `cp_questionnaire_page!cp_questionnaire_page_questionnaire_id_fkey(*, cp_question_in_page(*,cp_question(*)))`
      )
      .eq('id', q);

    if (error) {
      throw error;
    }

    return data as unknown as QuestionnairePage[];
  }

  async getAllQuestionnairesByQuestion(
    questionId: string
  ): Promise<Questionnaire[]> {
    const { data, error } = await this.supabase
      .from('cp_question_in_page')
      .select(
        `id, cp_questionnaire_page(questionnaire_id, cp_questionnaire!cp_questionnaire_page_questionnaire_id_fkey(name))`
      )
      .eq('question_id', questionId);

    if (error) {
      throw error;
    }

    return data as unknown as Questionnaire[];
  }

  async insertOrUpdatePages(pages: any) {
    // Execute o upsert
    const { data: upsertData, error } = await this.supabase
      .from('cp_questionnaire_page')
      .upsert(pages, { onConflict: 'id' });

    if (error) {
      console.error('Error:', error);
      throw error;
    } else {
      console.log('Upserted data:', upsertData);
      return upsertData as unknown as QuestionnairePage[];
    }
  }
  async deletePage(id: any) {
    // Execute o upsert
    const { data: deleteData, error } = await this.supabase
      .from('cp_questionnaire_page')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error:', error);
      throw error;
    } else {
      console.log('Upserted data:', deleteData);
      return deleteData as unknown as QuestionnairePage[];
    }
  }

  async insertOrUpdateQuestionInPages(qpages: any) {
    // Execute o upsert
    const { data: upsertData, error } = await this.supabase
      .from('cp_question_in_page')
      .upsert(qpages, { onConflict: 'id' });

    if (error) {
      console.error('Error:', error);
      throw error;
    } else {
      console.log('Upserted data:', upsertData);
      return upsertData as unknown as QuestionInPage[];
    }
  }
  async deleteQuestionInPage(id: any) {
    // Execute o upsert
    const { data: deleteData, error } = await this.supabase
      .from('cp_question_in_page')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error:', error);
      throw error;
    } else {
      console.log('Upserted data:', deleteData);
      return deleteData as unknown as QuestionInPage[];
    }
  }

  getSurveyWithAnswersByClaimAndQuestionnaire(
    claimId: string,
    questionnaireId: string
  ): Promise<SurveyWithAnswers[]> {
    return new Promise((res, rej) => {
      this.supabase
        .from('cp_questionnaire')
        .select(`*,answers:cp_questionnaire_answer(*)`)
        .filter('id', 'eq', questionnaireId)
        .filter('answers.claim_id', 'eq', claimId)
        .then((e) => {
          if (e.error) {
            rej(e);
          } else {
            res(e.data as unknown as SurveyWithAnswers[]);
          }
        });
    });
  }

  async reopenQuestionnaire(q: QuestionnaireAnswer) {
    const token = (await this.supabase.auth.getSession()).data.session
      ?.access_token;

    const ret = (await firstValueFrom(
      this.http.post(
        environment.lambdaUrl + '/reopen-questionnaire',
        {
          id: q.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    )) as any;

    return ret.message.claim;
  }
}

export interface SurveyWithAnswers extends Questionnaire {
  answers: QuestionnaireAnswer[];
}
