import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Filter } from 'src/app/core/services/base/database.service';
import { Column, TableSettings } from '../../table.component';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  @Input('column') column!: Column;
  @Input('settings') settings!: TableSettings;
  @Output('columnChanges') columnChanges: EventEmitter<Column> =
    new EventEmitter();

  constructor() {
    console.log('filter -> constructor', this.column);
  }

  editFilter(index: number) {
    // this.editedFilter = index;
    // this.tempFilter = { ...this.currentSettings.filters[index] };
  }

  saveFilter(index: number, filter: Filter) {
    // const newSettings = this.currentSettings;
    // newSettings.filters[index] = filter;
    // this.settings$.next(newSettings);
  }

  deleteFilter(index: number) {
    // const newSettings = { ...this.currentSettings };
    // newSettings.filters.splice(index, 1);
    // this.settings$.next(newSettings);
  }

  addFilter() {
    // const newSettings = { ...this.currentSettings };
    // newSettings.filters.push({
    //   column: this.settings.columns[0].id,
    //   operator: 'neq',
    //   value: '',
    //   visible: true,
    // });
    // this.settings$.next(newSettings);
  }

  onFiltersChange(ev: Column) {
    console.log('filter -> change', ev);
    this.column = ev;
    this.columnChanges.next(ev);
  }

  toggleSortAsc() {
    if (this.column.sort == 'asc') {
      this.column.sort = undefined;
    } else {
      this.column.sort = 'asc';
    }

    this.columnChanges.next(this.column);
  }

  toggleSortDesc() {
    if (this.column.sort == 'desc') {
      this.column.sort = undefined;
    } else {
      this.column.sort = 'desc';
    }

    this.columnChanges.next(this.column);
  }
}
