import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PrivacyNoticeModalComponent} from './privacy-notice-modal/privacy-notice-modal.component';
import {TermsOfUseModalComponent} from './terms-of-use-modal/terms-of-use-modal.component';

@Component({
  selector: 'app-legal-information',
  templateUrl: './legal-information.component.html',
  styleUrls: ['./legal-information.component.scss'],
})
export class LegalInformationComponent {
  constructor(public dialog: MatDialog) {
  }

  openPrivacy(): void {
    this.dialog.open(PrivacyNoticeModalComponent);
  }

  openTerms(): void {
    this.dialog.open(TermsOfUseModalComponent);
  }
}
