import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mfa-unenroll',
  templateUrl: './mfa-unenroll.component.html',
  styleUrls: ['./mfa-unenroll.component.scss'],
})
export class MfaUnenrollComponent {
  confirmed: boolean = false;

  constructor(public matDialogRef: MatDialogRef<MfaUnenrollComponent>) {}
}
