import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SignedContract } from 'src/app/core/models/contract';
import { DocumentService } from 'src/app/core/services/base/document.service';
import { SignedContractService } from 'src/app/core/services/signed_contract.service';

@Component({
  selector: 'app-download-document',
  templateUrl: './download-document.component.html',
  styleUrls: ['./download-document.component.scss'],
})
export class DownloadDocumentComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { signed_document_id: string },
    public dialogRef: MatDialogRef<DownloadDocumentComponent>,
    public scs: SignedContractService
  ) {}

  async download() {
    const link = await this.scs.doDownload(this.data.signed_document_id);
    window.open(link.data?.signedUrl, '_blank');
  }
}
