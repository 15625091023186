import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-button-toggle-menu',
  templateUrl: './button-toggle-menu.component.html',
  styleUrls: ['./button-toggle-menu.component.scss']
})
export class ButtonToggleMenuComponent {
  @Output() toggleSideNavEvent = new EventEmitter();
  @Input() isSideNavOpen: boolean = false;
  @Input() color: string = "#ffffff";

  constructor() {
  }

  toggleSideNav() {
    this.toggleSideNavEvent.emit();
  }
}
