import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Question } from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';

@Component({
  selector: 'app-question-explanation',
  templateUrl: './question-explanation.component.html',
  styleUrls: ['./question-explanation.component.scss'],
})
export class QuestionExplanationComponent implements OnInit {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();
  ngOnInit(): void {
    console.log('Explanation question emitting');
    this.emit();
  }

  emit() {
    console.log('CAIO6 - change exp', this.question);

    this.changeAnswer.emit({
      question: { ...this.question, isValid: true },
      answer: this.question.answer!,
    });
  }
}
