import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/user.service';
import { MfaEnrollComponent } from '../../dialog/mfa-enroll/mfa-enroll.component';
import { MfaUnenrollComponent } from '../../dialog/mfa-unenroll/mfa-unenroll.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subscription, debounceTime } from 'rxjs';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss'],
})
export class SecurityComponent implements OnInit, OnDestroy {
  mfaStatus = false;
  currentPassword = '';
  newPassword = '';
  confirmPassword = '';

  form: FormGroup<any> = new FormGroup({});
  change$ = new BehaviorSubject('');

  subscriptions: Subscription[] = [];

  confirmPasswordValidator = (control: any): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value !== this.newPassword) {
        return { confirmPassword: true };
      }
      return null;
    };
  };

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.createChangePasswordForm();
    this.subscriptions.push(
      this.change$.pipe(debounceTime(200)).subscribe((input) => {
        ['newPassword', 'confirmPassword', 'currentPassword'].forEach(
          (field) => {
            this.form.get(field)?.setValue((this as any)[field]);
            this.form.get(field)?.updateValueAndValidity();

            if (this.form.get(field)?.value !== '') {
              this.form.get(field)?.markAsTouched();
            }
          }
        );

        this.form.get('confirmPassword')?.setValue(this.confirmPassword);
        this.form.get('currentPassword')?.setValue(this.currentPassword);
        this.form.get('newPassword')?.updateValueAndValidity();
        this.form.get('confirmPassword')?.updateValueAndValidity();
        this.form.get('currentPassword')?.updateValueAndValidity();

        // console.log('__CH form', this.form);
        // console.log('__CH errors', this.form.errors);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  // MFA functions Start

  async changeMfaStatus() {
    const hasMFA = await this.checkMfaStatus();

    if (hasMFA) {
      this.openMfaUnenrollDialog();
    } else {
      this.openMfaEnrollDialog();
    }
  }

  openMfaEnrollDialog() {
    const ref = this.dialog.open(MfaEnrollComponent);
    ref.afterClosed().subscribe(async (result) => {
      // Do something

      const hasMFA = await this.checkMfaStatus();

      if (hasMFA) {
        this.mfaStatus = true;
        this._snackBar.open('MFA has been added', 'Close', {
          duration: 3000,
        });
      } else {
        this.mfaStatus = false;
        await this.clearUnverifiedMFA();
      }
    });
  }
  openMfaUnenrollDialog() {
    const ref = this.dialog.open(MfaUnenrollComponent);
    ref.afterClosed().subscribe(async (result) => {
      // Do something

      if (result.remove) {
        await this.clearAllMFA();
        this._snackBar.open('MFA has been removed', 'Close', {
          duration: 3000,
        });

        this.mfaStatus = false;
      }
    });
  }

  private async checkMfaStatus() {
    const { data, error } = await this.userService.listMFAEnrolls();
    if (error) return;
    if (!data) return;

    const hasMFA =
      data.all.filter((item) => item.status === 'verified').length > 0;

    return hasMFA;
  }

  private async clearUnverifiedMFA() {
    const { data, error } = await this.userService.listMFAEnrolls();

    if (error) throw new Error(error.message);
    if (!data) throw new Error('No data returned');

    for (const enroll of data.all.filter(
      (item) => item.status === 'unverified'
    )) {
      await this.userService.removeFactorMFAEnroll(enroll.id);
    }
  }
  private async clearAllMFA() {
    const { data, error } = await this.userService.listMFAEnrolls();

    if (error) throw new Error(error.message);
    if (!data) throw new Error('No data returned');

    for (const enroll of data.all) {
      await this.userService.removeFactorMFAEnroll(enroll.id);
    }
  }

  // MFA functions End

  // Change Password functions Start

  createChangePasswordForm() {
    this.form = this.fb.group(
      {
        currentPassword: ['', Validators.required],
        newPassword: ['', [Validators.required, passwordMatchPattern()]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validators: passwordMatchValidator(),
      }
    );
  }
  onChangeInput(input: string) {
    this.change$.next(input);
  }

  updateMessageError(input: string) {
    const control = this.form.get(input);
    let message = '';

    if (this.form.invalid && control && control.errors && control.touched) {
      if (control.errors['required']) {
        message = 'This field is required.';
      } else if (control.errors['minLength']) {
        message = 'Password must be at least 12 characters long.';
      } else if (control.errors['lowercase']) {
        message = 'Password must contain at least one lowercase letter.';
      } else if (control.errors['uppercase']) {
        message = 'Password must contain at least one uppercase letter.';
      } else if (control.errors['number']) {
        message = 'Password must contain at least one number.';
      } else if (control.errors['specialChar']) {
        message = 'Password must contain at least one special character.';
      }
    } else {
      message = '';
    }
    if (
      this.form.invalid &&
      control &&
      control.touched &&
      input === 'confirmPassword' &&
      this.form!.errors &&
      this.form!.errors!['passwordMismatch']
    ) {
      message = 'Passwords do not match.';
    }

    return message;
  }

  async handleSubmit() {
    // Check if current password is OK
    console.log('trying in');

    const email = (await this.userService.getCurrentUser())!.primary_email;
    if (!email) return;

    console.log('trying 2');

    const { error } = await this.userService.signIn(
      email,
      this.currentPassword
    );

    console.log('Signed in', error);

    if (error) {
      this._snackBar.open('Current password is incorrect', 'Close', {
        duration: 3000,
      });
      return;
    }

    // Update Password
    const { data, error: updateError } = await this.userService.updatePassword(
      this.newPassword
    );

    console.log('Update password', { data, error });

    if (updateError) {
      console.error('Password update error', updateError);
      this._snackBar.open(updateError.message, 'Close', {
        duration: 3000,
      });
      return;
    }

    this._snackBar.open('Password updated', 'Close', {
      duration: 3000,
    });
    console.log('Password updated', data);
  }
}

export function passwordMatchValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const newPassword = formGroup.get('newPassword')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;

    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      // Se as senhas não combinam, adiciona um erro ao campo confirmPassword
      return { passwordMismatch: true };
    }
    // Se as senhas combinam, retorna null (sem erro)
    return null;
  };
}

export function passwordMatchPattern(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    const errors: ValidationErrors = {};

    if (!value) {
      return null; // Early return if no value
    }

    // Check for at least 8 characters
    if (value.length < 12) {
      errors['minLength'] = true;
    }

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(value)) {
      errors['lowercase'] = true;
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(value)) {
      errors['uppercase'] = true;
    }

    // Check for at least one number
    if (!/[0-9]/.test(value)) {
      errors['number'] = true;
    }

    // Check for at least one special character
    if (!/[^a-zA-Z0-9]/.test(value)) {
      errors['specialChar'] = true;
    }

    // If there are any errors, return them
    if (Object.keys(errors).length > 0) {
      return errors;
    }

    return null; // Return null if no errors
  };
}
