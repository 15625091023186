import { Component, Input, OnInit } from '@angular/core';
import { Action } from 'src/app/core/models/action.model';
import {
  CaseConfiguration,
  ClaimRequirement,
  ClaimRequirementStatus,
  ClaimsService,
  ClaimWithCase,
} from 'src/app/core/services/claims.service';
import { CommunicationService } from 'src/app/core/services/communication.service';

@Component({
  selector: 'app-os-actions-case',
  templateUrl: './os-actions-case.component.html',
  styleUrls: ['./os-actions-case.component.scss'],
})
export class OsActionsCaseComponent implements OnInit {
  @Input() public claim?: ClaimWithCase;
  @Input() public showImage: boolean = true;
  @Input() public showDetails: boolean = false;
  pendingNumber = 0;

  constructor() {}

  async ngOnInit() {}

  async refresh() {}

  changeActions(actions: Action[]) {
    this.pendingNumber = actions.filter((f) => !f.ac_status).length;
  }
}
