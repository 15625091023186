import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Question } from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';

@Component({
  selector: 'app-question-email',
  templateUrl: './question-email.component.html',
  styleUrls: ['./question-email.component.scss'],
})
export class QuestionEmailComponent implements OnInit {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();
  regex = '';
  isLongText = false;

  email = new FormControl('', [Validators.required, Validators.email]);

  ngOnInit(): void {
    // if (this.question.configuration) {
    //   this.handleConfiguration();
    // }

    if (this.question.answer!.value) {
      this.email.setValue(this.question.answer!.value.toString());
    }
    this.change();
  }

  change() {
    this.question.answer!.value = this.email.value;
    this.question.isValid = this.email.valid;

    if (this.question.answer!.value && this.regex) {
      const isValid = this.checkValidation();
      this.question.isValid = isValid;
    }

    console.log(`${this.question.name} valid: `, this.question.isValid);
    this.changeAnswer.next({
      answer: this.question.answer!,
      question: this.question,
    });
  }

  handleConfiguration() {
    if (this.question.configuration.validate) {
      this.handleValidators();
    }
  }

  handleValidators() {
    const configuration = this.question.configuration;
    const regex = configuration.validate.regex;
    if (regex) {
      this.regex = regex;

      this.question.isValid = this.checkValidation();
    }
  }

  checkValidation(): boolean {
    let valid = true;
    valid = this.checkRegex();
    return valid;
  }

  checkRegex() {
    const regexTest = new RegExp(this.regex);
    let result = false;
    if (regexTest) {
      result = regexTest.test(this.question.answer!.value?.toString() || '');
    }
    return result;
  }
}
