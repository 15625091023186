import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { EventService } from 'src/app/core/services/event.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  email!: string;
  password!: string;
  form!: FormGroup;
  isLoading!: boolean;

  // MFA
  mfaNeeded: boolean = false;
  mfaForm!: FormGroup;
  factorId!: string;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private toastService: ToastService,
    private es: EventService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      // TODO: remove when finish
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      name: ['', [Validators.required]],
      repeat: ['', [Validators.required]],
    });

    this.mfaForm = this.fb.group({
      code: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
    });
  }

  async onSubmit() {
    this.isLoading = true;
    try {
      if (!this.form.valid) {
        throw Error('Invalid Email and password');
      }

      const name = this.form.get('name')?.value;
      const email = this.form.get('email')?.value;
      const password = this.form.get('password')?.value;
      const repeat = this.form.get('repeat')?.value;

      const signuporet = await this.userService.signUp(email, password, {
        display_name: name,
        primary_email: email,
      });

      console.log('signuporet', signuporet);
    } catch (error: any) {
      this.toastService.error(error.message);
    } finally {
      this.isLoading = false;
    }
  }

  async submitMfa() {
    try {
      this.isLoading = true;

      if (!this.mfaForm.valid) {
        throw Error('Invalid MFA code');
      }

      const code = this.mfaForm.get('code')?.value;
      if (!this.factorId) throw Error('No factorId found');
      if (!code) throw Error('No code found');

      const data = await this.userService.verifyMFACode(this.factorId, code);

      console.log('Verify MFA Data: ', data);

      this.router.navigateByUrl('/client');
    } catch (error: any) {
      this.toastService.error(error.message || error.msg || 'Error');
    } finally {
      this.isLoading = false;
    }
  }
}
