import { Component, Input } from '@angular/core';
import { Case } from 'src/app/core/models/case';
import { Claim } from 'src/app/core/models/claim';

@Component({
  selector: 'app-case-description',
  templateUrl: './case-description.component.html',
  styleUrls: ['./case-description.component.scss'],
})
export class CaseDescriptionComponent {
  @Input() public case?: Case;
}
