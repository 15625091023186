import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, debounceTime, filter, Subscription, tap } from 'rxjs';
import { Filter } from 'src/app/core/services/base/database.service';
import { Column } from '../../table.component';

@Component({
  selector: 'app-filter-text',
  templateUrl: './filter-text.component.html',
  styleUrls: ['./filter-text.component.scss'],
})
export class FilterTextComponent implements OnInit, OnDestroy, OnChanges {
  @Input('column') column!: Column;
  @Output('columnChanges') columnChanges: EventEmitter<Column> =
    new EventEmitter();

  currentColumn!: Column;

  operator = 'eq';
  text: string = '';

  operators = [
    {
      value: 'eq',
      label: 'Is',
    },
    {
      value: 'neq',
      label: 'Is not',
    },
    {
      value: 'contains',
      label: 'Contains',
    },
    {
      value: 'ncontains',
      label: 'Does not contain',
    },
    {
      value: 'startswith',
      label: 'Starts with',
    },
    {
      value: 'endswith',
      label: 'Ends with',
    },
    {
      value: 'empty',
      label: 'Is empty',
    },
    {
      value: 'nempty',
      label: 'Is not empty',
    },
  ];

  debouncer$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  subscriptions: Subscription[] = [];

  filters: Filter[] = [];
  constructor() {}

  ngOnInit(): void {
    console.log('Filter-Text', this);
    if (this.column.filters[0]) {
      this.currentColumn = this.column;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('Filter-Text changes', changes);
    if (changes['column'] && changes['column'].currentValue) {
      this.currentColumn = changes['column'].currentValue;
      // Atualizar filtros com a nova coluna, se necessário
      if (this.currentColumn.filters) {
        this.filters = this.currentColumn.filters;
      } else {
        this.filters = [];
      }
    }
  }

  getOperatorLabel(op: string): string {
    return this.operators.find((o) => o.value === op)?.label || '';
  }

  clearFilter() {
    this.filters = [];
    this.columnChanges.emit({ ...this.currentColumn, filters: this.filters });
  }
  addFilter() {
    this.filters.push({
      column: this.column.id,
      operator: this.operator as any,
      value: this.text,
      visible: false,
    });

    this.operator = 'eq';
    this.text = '';

    this.columnChanges.emit({ ...this.currentColumn, filters: this.filters });
  }
  removeFilter(filter: number) {
    this.filters.splice(filter, 1);
    this.columnChanges.emit({ ...this.currentColumn, filters: this.filters });
  }

  ngOnDestroy(): void {}
}
