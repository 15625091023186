import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  NgSignaturePadOptions,
  SignaturePadComponent,
} from '@almothafar/angular-signature-pad';

import { Observable, Subscription, map } from 'rxjs';

import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContractService } from 'src/app/core/services/contract.service';
import { Contract } from 'src/app/core/models/contract';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit {
  @ViewChild('signature')
  public signaturePad?: SignaturePadComponent;

  dtbs$?: Observable<Contract[]>;

  dtbs: Contract[] = [];

  subscriptions: Subscription[] = [];

  activeTabIndex: number = 0;

  pagenbr = 1;
  signaturePadOptions: NgSignaturePadOptions = {
    // passed through to szimek/signature_pad constructor
    minWidth: 2,
    canvasWidth: 500,
    canvasHeight: 100,
    backgroundColor: 'white',
    dotSize: 2,
  };

  ngOnInit(): void {
    this.dtbs$ = this.cs.list().pipe(map((qr) => qr.data));

    this.subscriptions.push(this.dtbs$.subscribe((d) => (this.dtbs = d)));
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SignatureComponent>,
    private cs: ContractService,
    private router: Router
  ) {}

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad!.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad!.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onEnd event
    console.log('Completed drawing', event);
    console.log(this.signaturePad!.toDataURL());
  }

  drawStart(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('Start drawing', event);
  }

  nextPage() {
    this.pagenbr += 1;
  }

  previousPage() {
    this.pagenbr -= 1;
  }

  clearSig() {
    this.signaturePad?.clear();
  }

  doSign() {
    console.log(this.activeTabIndex, this.dtbs);
    if (this.activeTabIndex >= this.dtbs.length - 1) {
      this.dialogRef.close();
    } else {
      this.activeTabIndex += 1;
    }
  }
}
