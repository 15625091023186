import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-profile-success-change',
  templateUrl: './edit-profile-success-change.component.html',
  styleUrls: ['./edit-profile-success-change.component.scss'],
})
export class EditProfileSuccessChangeComponent {
  constructor(
    public dialogRef: MatDialogRef<EditProfileSuccessChangeComponent>
  ) {}
}
