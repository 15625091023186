import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  Question,
  QuestionnaireAnswer,
  QuestionType,
} from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';
import { DynamicService } from 'src/app/core/services/base/dynamic.service';
import { UserService } from 'src/app/core/services/user.service';
import {
  debounceTime,
  distinct,
  filter,
  map,
  Observable,
  Subscription,
  tap,
} from 'rxjs';

export interface ConcatQuestionConfig {
  items: string[];
  separator: string;
}

@Component({
  selector: 'app-question-concat',
  templateUrl: './question-concat.component.html',
  styleUrls: ['./question-concat.component.scss'],
})
export class QuestionConcatComponent implements OnInit, OnDestroy {
  @Input('questionnaireAnswer') qa$!: Observable<QuestionnaireAnswer | null>;
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();
  regex = '';
  isLongText = false;

  autofillMode = false;
  autofillEditable = false;
  user: string = '';

  subs: Subscription[] = [];

  constructor(private ds: DynamicService<any>, private us: UserService) {}

  async ngOnInit() {
    this.qa$
      .pipe(
        debounceTime(200),
        map((e) => {
          if (!e) return '';

          const config = this.question.configuration as ConcatQuestionConfig;

          // Utilizando reduce para concatenar os valores
          const valVec = config.items.reduce((acc, item) => {
            const valAns = e.answers[item];

            if (valAns && valAns.value) {
              // Concatena o valor no acumulador, usando o separador definido
              return acc + (valAns.value as string) + config.separator;
            }
            return acc;
          }, '');

          // Remove espaços duplicados e o separador final
          return valVec
            .trim()
            .replace(/\s\s+/g, ' ')
            .replace(new RegExp(`${config.separator}$`), '');
        })
      )
      .subscribe((e) => {
        if (e !== this.question.answer?.value) {
          if (!this.question.answer) {
            this.question.answer = {
              value: e,
            };
          }
          this.question.answer.value = e;
          this.question.isValid = true;

          this.change();
        }
      });

    // const sChangeAnswer = this.qa$
    //   .pipe(
    //     tap((e) => console.log('question-concat tap test', e)),
    //     filter((e) => !!e),
    //     map((e) => {
    //       if (!e) return '';

    //       console.log('question-concat', e);
    //       let valVec: string[] = [];
    //       const config = this.question.configuration as ConcatQuestionConfig;
    //       for (let i = 0; i < config.items.length; i++) {
    //         const answerKey = config.items[i];
    //         valVec.push(
    //           e.answers[answerKey] ? (e.answers[answerKey].value as string) : ''
    //         );
    //       }
    //       return valVec.join(config.separator).replace(/\s\s+/g, ' ');
    //     })
    //   )
    //   .subscribe((e) => {
    //     if (!this.question.answer) {
    //       this.question.answer = {
    //         value: e,
    //       };
    //     }
    //     this.question.answer.value = e;
    //     this.question.isValid = true;
    //   });

    // this.subs.push(sChangeAnswer);

    this.change();

    const responseEnumIndex = QuestionType[
      this.question.response_type
    ] as unknown as number;
    const enumIndex = QuestionType.LONG_TEXT as unknown as number;
    this.isLongText = responseEnumIndex === enumIndex;
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  change() {
    this.question.isValid = true;
    this.changeAnswer.next({
      answer: this.question.answer!,
      question: this.question,
    });
  }
}
