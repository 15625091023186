import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-title-large',
  templateUrl: './title-large.component.html',
  styleUrls: ['./title-large.component.scss']
})
export class TitleLargeComponent {
  @Input() color: string = 'var(--color-neutral-10)';
  @Input() paddingMobile: boolean = false;
}
