import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  type OnInit,
} from '@angular/core';
import { Column } from '../../table.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Filter } from 'src/app/core/services/base/database.service';

@Component({
  selector: 'app-filter-boolean',

  templateUrl: './filter-boolean.component.html',
  styleUrls: ['./filter-boolean.component.scss'],
})
export class FilterBooleanComponent implements OnInit, OnDestroy, OnChanges {
  @Input('column') column!: Column;
  @Output('columnChanges') columnChanges: EventEmitter<Column> =
    new EventEmitter();

  currentColumn!: Column;

  operator: string | null = 'eq';
  text: string = '';

  operators = [
    {
      value: null,
      label: '-',
    },
    {
      value: 'true',
      label: 'True',
    },
    {
      value: 'false',
      label: 'False',
    },
  ];

  debouncer$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  subscriptions: Subscription[] = [];

  filters: Filter[] = [];
  constructor() {}

  ngOnInit(): void {
    console.log('Filter-Text', this);
    if (this.column.filters[0]) {
      this.currentColumn = this.column;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('Filter-Text changes', changes);
    if (changes['column'] && changes['column'].currentValue) {
      this.currentColumn = changes['column'].currentValue;
      // Atualizar filtros com a nova coluna, se necessário
      if (this.currentColumn.filters) {
        this.filters = this.currentColumn.filters;
      } else {
        this.filters = [];
      }
    }
  }

  getOperatorLabel(op: string): string {
    return this.operators.find((o) => o.value === op)?.label || '';
  }

  clearFilter() {
    this.filters = [];
    this.operator = null;
    this.columnChanges.emit({ ...this.currentColumn, filters: this.filters });
  }
  addFilter() {
    this.filters = [
      {
        column: this.column.id,
        operator: 'eq',
        value: this.operator === 'true',
        visible: false,
      },
    ];

    this.columnChanges.emit({ ...this.currentColumn, filters: this.filters });
  }
  removeFilter(filter: number) {
    this.filters = [];
    this.operator = null;

    this.columnChanges.emit({ ...this.currentColumn, filters: this.filters });
  }

  ngOnDestroy(): void {}
}
