import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { Question } from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';

@Component({
  selector: 'app-question-date-time',
  templateUrl: './question-date-time.component.html',
  styleUrls: ['./question-date-time.component.scss'],
})
export class QuestionDateTimeComponent implements OnInit {
  @ViewChild('picker') picker: any;
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  date!: moment.Moment;

  min!: Date;
  max!: Date;

  ngOnInit(): void {
    if (this.question.answer!.value) {
      this.date = moment(this.question.answer!.value);
    }
    this.change();

    if (this.question.configuration) {
      const config = this.question.configuration;

      if (config.min) {
        this.min = new Date(config.min);
      }

      if (config.max) {
        this.max = new Date(config.max);
      }
    }
  }

  change() {
    this.question.answer!.value = this.date?.toDate().toString();
    this.question.isValid = this.date?.isValid();
    this.changeAnswer.next({
      answer: this.question.answer!,
      question: this.question,
    });
  }
}
