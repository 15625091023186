import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { News } from 'src/app/core/models/news';

import {
  ClaimsService,
  ClaimWithCase,
} from 'src/app/core/services/claims.service';
import {
  CommunicationService,
  ThreadWithMessages,
  ThreadWithMessagesAndClaimCase,
} from 'src/app/core/services/communication.service';
import { NewsService } from 'src/app/core/services/news.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ConfirmModalComponent } from 'src/app/shared/components/simple/confirm-modal/confirm-modal.component';
import { InfoModalComponent } from 'src/app/shared/components/simple/info-modal/info-modal.component';

@Component({
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss'],
})
export class ClaimComponent implements OnInit {
  claim!: ClaimWithCase;

  comms$: BehaviorSubject<ThreadWithMessages[]> = new BehaviorSubject<
    ThreadWithMessages[]
  >([]);

  relatedNews: News | undefined = undefined;
  messages: ThreadWithMessagesAndClaimCase[] = [];

  constructor(
    private route: ActivatedRoute,
    private cs: ClaimsService,
    private cms: CommunicationService,
    private ns: NewsService,
    private router: Router,
    private dialog: MatDialog,
    private nts: NotificationService
  ) {}

  async ngOnInit() {
    let idClaim = this.route.snapshot.paramMap.get('id');

    this.claim = await this.cs.getClaimWithCase(idClaim!);

    await this.getNews(idClaim!);

    const c = await this.cms.listCommunicationsWithMessagesAndCaseForClaim(
      idClaim!
    );
    this.messages = c;

    this.comms$.next(c);
  }

  async getNews(idCase: string) {
    const news = await this.ns.getNewsByRelatedCase(idCase, 1);

    this.relatedNews = news[0] ?? undefined;
  }

  getNewestMessageFromThread(thread: ThreadWithMessages) {
    // Sort messages and get the most recent
    return thread.cp_message?.sort((a, b) => {
      return (
        new Date(b.created_at!).getTime() - new Date(a.created_at!).getTime()
      );
    })[0];
  }

  goToNewMessage() {
    this.router.navigate(['client/messages/new', this.claim.id]);
  }

  goToMessage(thread: string) {
    this.router.navigate(['client/messages/chat', thread]);
  }

  goAllMessages() {
    this.router.navigate(['client/messages']);
  }

  withdrawClaim() {
    const ref = this.dialog.open(ConfirmModalComponent, {
      data: {
        titleIcon: 'delete',
        title: 'Withdraw Claim',
        message:
          'Are you sure you want to withdraw the complaint in this case?',
        confirmText: 'Yes, remove',
        cancelButton: 'Go back',
      },

      width: '400px',
    });

    ref.afterClosed().subscribe(async (result) => {
      if (result) {
        // Do lambda action

        console.log('Withdraw claim => Success');
        await this.cs.withdrawClaim(this.claim.id);

        const a = await this.dialog.open(InfoModalComponent, {
          data: {
            // titleIcon: 'delete',
            title: 'Withdrawal requested',
            message: 'Your withdrawal is being processed.',
            confirmText: 'Go to Homepage',
          },
        });

        a.afterClosed().subscribe(() => {
          this.router.navigate(['client']);
        });

        // this.nts.
        return;
      }
      console.log('Withdraw claim => Fail');
    });
  }
}
