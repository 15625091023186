/// <reference types="@types/google.maps" />

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';
import { Question } from 'src/app/core/models/questionnaire.model';
import { MaterialModule } from '../../../modules/material/material-module.module';
import { QuestionAnswer } from '../../simple/question/question.component';

@Component({
  selector: 'app-question-world-address',
  templateUrl: './question-world-address.component.html',
  styleUrls: ['./question-world-address.component.scss'],
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule],
})
export class QuestionWorldAddressComponent implements OnInit, OnDestroy {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  addressList: any = [];
  addressForm = new FormGroup({
    address: new FormControl<string>(''),
    addtional_information: new FormControl(),
  });

  show_additional_info: boolean = false;
  additional_info: PlaceAdditionalInfo = {
    streetNumber: '',
    streetName: '',
    city: '',
    neighborhood: '',
    state: '',
    county: '',
    country: '',
    postalCode: '',
    buildingName: '',
    unitNumber: '',
  };

  callAPI: Subject<any> = new Subject();

  loading = false;

  googleAutocomplete = new google.maps.places.AutocompleteService();

  placesService = new google.maps.places.PlacesService(
    document.createElement('div')
  );

  showAdd: boolean = false;

  constructor(public zone: NgZone) {}

  ngOnInit(): void {
    if (this.question.answer && this.question.answer.value) {
      this.addressForm.controls.address.setValue(
        this.question.answer.value.toString()
      );
      this.addressForm.controls.addtional_information.setValue(
        this.question.answer.addtional_information
      );
    }
    this.change();

    if (!this.question.elective) {
      this.addressForm.controls.address.setValidators(Validators.required);
      this.addressForm.controls.addtional_information.setValidators(
        Validators.required
      );
    }

    this.callAPI.pipe(debounceTime(1000)).subscribe(() => {
      if (this.addressForm.controls.address.value) {
        this.addressForm.controls.addtional_information.reset();
        this.updateSearchResults(
          this.addressForm.controls.address.value.trim()
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.callAPI.unsubscribe();
  }

  doSearch() {
    this.loading = true;
    this.callAPI.next(null);
  }

  updateSearchResults(searchValue: string) {
    this.loading = true;

    if (!searchValue) {
      this.addressList = [];
      this.loading = false;
      return;
    }

    try {
      const optionsPredictions: google.maps.places.AutocompletionRequest = {
        input: searchValue,
        // types: ['address_components'],
      };

      this.googleAutocomplete.getPlacePredictions(
        optionsPredictions,
        (predictions: any) => {
          this.addressList = [];
          if (predictions && predictions.length > 0) {
            this.zone.run(() => {
              this.change();
              this.loading = false;
              predictions.forEach((prediction: any) => {
                console.log(prediction);
                this.addressList.push(prediction);
              });
            });
          } else {
            this.change();
            this.loading = false;
            this.addressList = [];
          }
        }
      );
    } catch (error) {
      console.log('Question World Address -> Google Maps -> Error', error);
      this.loading = false;
      this.addressList = [];
    }
  }

  async selectAddress(addressSelected: any) {
    console.log('GOOGLE ADDRESS', addressSelected);
    const comps = await this.getAddressFromPlace(addressSelected.place_id);
    this.addressForm.controls.addtional_information.setValue(comps);
    this.showAdd = true;
    this.additional_info = comps;
    this.change();
  }

  change() {
    this.question.isValid = this.addressForm.valid;

    this.question.answer!.value = this.addressForm.controls.address.value;
    this.question.answer!.addtional_information =
      this.addressForm.controls.addtional_information.value;

    console.log(`${this.question.name} valid: `, this.question.isValid);
    this.changeAnswer.next({
      answer: this.question.answer!,
      question: this.question,
    });
  }

  getAddressFromPlace(googlePlace: string): Promise<PlaceAdditionalInfo> {
    return new Promise((resolve, rej) => {
      this.placesService.getDetails(
        { placeId: googlePlace, language: 'en' },
        (res) => {
          const comps = mapAddressComponents(res?.address_components as any[]);
          console.log('GOOGLE PLACES ADDRESS');
          resolve(comps);
        }
      );
    });
  }
}

function mapAddressComponents(addressComponents: any[]): PlaceAdditionalInfo {
  const mappedComponents: any = {};

  addressComponents.forEach((component) => {
    const types = component.types;

    types.forEach((t: string) => {
      switch (t) {
        case 'street_number':
          mappedComponents.streetNumber = component.long_name;
          break;
        case 'route':
          mappedComponents.streetName = component.long_name;
          break;
        case 'locality':
        case 'administrative_area_level_2':
          mappedComponents.city = component.long_name;
          break;
        case 'sublocality':
        case 'sublocality_level_1':
        case 'sublocality_level_2':
        case 'sublocality_level_3':
        case 'sublocality_level_4':
        case 'sublocality_level_5':
          mappedComponents.neighborhood = component.long_name;
          break;
        case 'administrative_area_level_1':
          mappedComponents.state = component.long_name;
          break;
        case 'country':
          mappedComponents.country = component.long_name;
          break;
        case 'postal_code':
          mappedComponents.postalCode = component.long_name;
          break;
        case 'premise':
          mappedComponents.buildingName = component.long_name;
          break;
        case 'subpremise':
          mappedComponents.unitNumber = component.long_name;
          break;
        default:
          // Other types can be handled here if needed
          break;
      }
    });
  });

  return mappedComponents;
}

export interface PlaceAdditionalInfo {
  streetNumber: string;
  streetName: string;
  city: string;
  neighborhood: string;
  state: string;
  county: string;
  country: string;
  postalCode: string;
  buildingName: string;
  unitNumber: string;
}
