import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements AfterViewInit {
  @ViewChild('pending', { static: true }) pending!: ElementRef;
  @ViewChild('requested', { static: true }) requested!: ElementRef;
  @ViewChild('granted', { static: true }) granted!: ElementRef;

  constructor(private elementRef: ElementRef) {}
  ngAfterViewInit(): void {
    this.setIconInMainTab();
  }

  setIconInMainTab() {
    if (!this.elementRef || !this.elementRef.nativeElement) return;
    var element =
      this.elementRef.nativeElement.querySelector('.mdc-tab__content');
    element.insertAdjacentHTML(
      'beforeend',
      '<i _ngcontent-nty-c262="" class="ic-sm ic-bell-blue-rd"></i>'
    );
  }

  changeColorAllSectionNotification() {
    if (!this.elementRef || !this.elementRef.nativeElement) return;

    var elements = this.elementRef.nativeElement.querySelectorAll(
      '.notification-not-read'
    ) as HTMLElement[];
    elements.forEach((element) => {
      element.classList.remove('notification-not-read');
      element.classList.add('notification-readed');
    });
  }
}
