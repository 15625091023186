import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-recovery-code',
  templateUrl: './recovery-code.component.html',
  styleUrls: ['./recovery-code.component.scss'],
})
export class RecoveryCodeComponent implements OnInit, OnDestroy {
  @Input() email: string = '';

  isLoading = false;

  lastSendTime: number | null = null;
  sendCodePermission = false;

  form: FormGroup<any> = new FormGroup({});
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  subscriptions: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private actRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      code: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
    });

    setInterval(() => {
      const now = new Date().getTime();
      if (!this.lastSendTime) {
        this.sendCodePermission = true;
        return;
      }

      if (this.lastSendTime && now - this.lastSendTime > 1000 * 60) {
        this.sendCodePermission = true;
      } else {
        this.sendCodePermission = false;
      }
    }, 1000);

    this.subscriptions.push(
      this.actRoute.queryParams.subscribe((params) => {
        if (params['email']) this.email = params['email'];
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getDiffLastTimeAndNowInSec() {
    if (!this.lastSendTime) return 0;
    return 60 - Math.floor((new Date().getTime() - this.lastSendTime) / 1000);
  }

  async resendCode() {
    try {
      this.isLoading = true;

      if (!this.email) throw Error('Email is required');
      const email = this.email;
      const { error } = await this.userService.sendResetPasswordEmail(email);

      if (error) throw Error('Issue sending email');
      this.lastSendTime = new Date().getTime();
      this.sendCodePermission = false;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async submit() {
    try {
      this.isLoading = true;

      if (!this.email) throw Error('Email is required');
      const email = this.email;

      const code = this.form.get('code')?.value;
      if (!code) throw Error('Code is required');

      const { user, session } = await this.userService.verifyPasswordResetToken(
        email,
        code
      );

      this.onSubmit.emit({ user, session });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
