import { Injectable } from '@angular/core';
import { DatabaseService } from './base/database.service';
import { Pii } from '../models/pii.model';

@Injectable({
  providedIn: 'root',
})
export class PiiService extends DatabaseService<Pii> {
  constructor() {
    super('cp_pii');
  }

  async getFromUserId(uid: string): Promise<Pii> {
    const ret = await this.supabase
      .from('cp_pii')
      .select('*')
      .eq('user_id', uid)
      .single();

    return ret.data as unknown as Pii;
  }
}
