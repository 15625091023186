import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { Faq } from 'src/app/core/models/faq';
import { FaqService } from 'src/app/core/services/faq.service';

export type CategoryListItem = {
  label: string;
  icon: string;
};

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  faqs: Faq[] = [];
  categoryList: CategoryListItem[] = [
    {
      label: 'News',
      icon: 'newspaper',
    },
    {
      label: 'Profile',
      icon: 'person_outline',
    },
    {
      label: 'Clients',
      icon: 'group',
    },
    {
      label: 'Security',
      icon: 'security',
    },
    {
      label: 'Notifications',
      icon: 'notifications_outline',
    },
    {
      label: 'Pricing',
      icon: 'paid_outline',
    },
    {
      label: 'Claims',
      icon: 'campaign_outline',
    },
  ];

  selectedCategory?: CategoryListItem;

  faqsToShow: Faq[] = [];

  constructor(private faqService: FaqService) {}

  async ngOnInit() {
    this.faqs = await this.getFaqs();
    this.faqsToShow = await this.getFaqs();
  }

  async getFaqs(): Promise<Faq[]> {
    return (await firstValueFrom(this.faqService.list(0, 100))).data;
  }

  async selectCategory(category: CategoryListItem) {
    this.selectedCategory = category;

    // this.faqsToShow = this.faqs.filter(
    //   (faq) => faq.metadata.group == this.selectedCategory?.label
    // );
    this.faqsToShow = await this.getFaqs();
  }
}
