import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.scss'],
})
export class InputDropdownComponent {
  @Input() title!: string;
  @ViewChild('arrow', { static: true }) arrow!: ElementRef;
  private wasInside = false;

  rotateArrow() {
    this.arrow.nativeElement.classList.remove('ic-arrow-down');
    this.arrow.nativeElement.classList.add('ic-arrow-up');
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.arrow.nativeElement.classList.remove('ic-arrow-up');
      this.arrow.nativeElement.classList.add('ic-arrow-down');
    }
    this.wasInside = false;
  }
}
