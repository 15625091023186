import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Question } from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';

@Component({
  selector: 'app-question-multi-select',
  templateUrl: './question-multi-select.component.html',
  styleUrls: ['./question-multi-select.component.scss'],
})
export class QuestionMultiSelectComponent implements OnInit {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  value: string[] = [];

  constructor() {
    console.log('QUESTION', this);
  }

  ngOnInit(): void {
    this.change();
  }

  change() {
    setTimeout(() => {
      const val = this.checkValid();

      this.question.isValid = val;

      console.log('change', this.value);
      this.changeAnswer.next({
        answer: {
          value: this.value.join(', '),
          addtional_information: { array: this.value },
        },
        question: this.question,
      });
    }, 10);
  }

  checkValid(): boolean {
    if (this.question.configuration.required) {
      return this.value.length > 0;
    } else {
      return true;
    }
  }
}
