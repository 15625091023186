import {Component, Input, OnInit} from '@angular/core';

export interface Breadcrumb {
  label: string;
  url?: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() items: Breadcrumb[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }
}
