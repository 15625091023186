import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Question } from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';

@Component({
  selector: 'app-question-number',
  templateUrl: './question-number.component.html',
  styleUrls: ['./question-number.component.scss'],
})
export class QuestionNumberComponent implements OnInit {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  numberForm = new FormGroup({ number: new FormControl<number>(0) });

  ngOnInit(): void {
    if (this.question.answer && this.question.answer.value) {
      this.numberForm.controls.number.setValue(
        Number.parseFloat(this.question.answer.value + '')
      );
    }
    this.change();

    if (!this.question.elective) {
      this.numberForm.controls.number.setValidators(Validators.required);
    }
  }

  change() {
    const answer = this.numberForm.controls.number.value;
    this.question.answer!.value = answer;
    this.question.isValid = this.numberForm.controls.number.valid;
    this.changeAnswer.next({
      answer: { value: answer! },
      question: this.question,
    });
  }
}
