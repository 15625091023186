import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-input-upload-file',
  templateUrl: './input-upload-file.component.html',
  styleUrls: ['./input-upload-file.component.scss'],
})
export class InputUploadFileComponent implements AfterViewInit {
  @Input() fileSrc: string = '';
  @Input() maxHeight: string = 'auto';
  @Input() hasTitleDeleteImg: boolean = true;
  @Input() deleteButtonDisposition: 'vertical' | 'horizontal' = 'horizontal';
  @Output() file: EventEmitter<File | null> = new EventEmitter<File | null>();

  @ViewChild('inputfile', { static: false })
  input!: ElementRef<HTMLInputElement>;

  ngAfterViewInit(): void {
    console.log('input', this.input);
  }

  onFileChange(event: any) {
    console.log('onFileChange', this.input);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.file.emit(file);

      // Generate a URL for the file to img tag
      const reader = new FileReader();
      reader.onload = (e) => {
        this.fileSrc = e.target?.result as string;
      };

      reader.readAsDataURL(file);
    }
  }

  removeFile() {
    console.log('remove file', this.input);
    console.log('remove file', this.input.nativeElement);
    console.log('remove file', this.input.nativeElement.value);
    console.log('remove file', this.input.nativeElement.files);
    this.input.nativeElement.value = '';
    this.input.nativeElement.files = null;
    this.fileSrc = '';
    this.file.emit(null);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer!.dropEffect = 'copy';
    // Adicione uma classe para estilizar a área de drop (opcional)
    const noUploadDiv = event.currentTarget as HTMLElement;
    noUploadDiv.classList.add('dragging');
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    // Remova a classe de estilização quando o arrasto sair da área de drop (opcional)
    const noUploadDiv = event.currentTarget as HTMLElement;
    noUploadDiv.classList.remove('dragging');
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    // Remova a classe de estilização quando o arquivo for solto (opcional)
    const noUploadDiv = event.currentTarget as HTMLElement;
    noUploadDiv.classList.remove('dragging');

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.handleFileInput(event.dataTransfer.files);
      event.dataTransfer.clearData();
    }
  }

  handleFileInput(files: FileList) {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.fileSrc = reader.result as string;
    };
    reader.readAsDataURL(file);
    this.file.emit(file);
  }
}
