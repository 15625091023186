import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChoiceModalComponent } from 'src/app/shared/components/simple/choice-modal/choice-modal.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { EletronicSignatureModalComponent } from '../../../../modules/client/shared/smart/electronic-signature/components/eletronic-signature-modal/eletronic-signature-modal.component';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { SucessModalComponent } from '../sucess-modal/sucess-modal.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  constructor(public dialog: MatDialog) {}

  callSucessModal() {
    const dialogRef = this.dialog.open(SucessModalComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  callErrorModal() {
    const dialogRef = this.dialog.open(ErrorModalComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  callChoiceModal() {
    const dialogRef = this.dialog.open(ChoiceModalComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  callConfirmModal() {
    const dialogRef = this.dialog.open(ConfirmModalComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  callEletronicSignatureModal() {
    const dialogRef = this.dialog.open(EletronicSignatureModalComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
