import { Injectable } from '@angular/core';

import { DatabaseService } from './base/database.service';
import { Event, EventTypes } from '../models/events.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { UAParser } from 'ua-parser-js';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService extends DatabaseService<Event> {
  constructor(
    private http: HttpClient,
    private user: UserService,
    private router: Router
  ) {
    super('cp_event');
  }

  async pushNew(type: EventTypes, details: any) {
    const usr = await this.user.getCurrentUser();
    const token = (await this.supabase.auth.getSession()).data.session
      ?.access_token;

    let parser = new UAParser();
    const ua = parser.getResult();

    const ev: Event = {
      context: {
        path: this.router.url,
        ...details,
      },
      type: type,
      user_agent: ua,
    };
    let res;
    console.log('RECORDING EVENT', ev);

    if (usr) {
      res = await firstValueFrom(
        this.http.post(
          environment.lambdaUrl + '/event_push',
          JSON.stringify(ev),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
      );
    } else {
      res = await firstValueFrom(
        this.http.post(
          environment.lambdaUrl + '/event_push',
          JSON.stringify(ev)
        )
      );
    }

    console.log('EVENT RECORDED', ev);
  }
}
