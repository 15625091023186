import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Question } from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';

@Component({
  selector: 'app-question-time',
  templateUrl: './question-time.component.html',
  styleUrls: ['./question-time.component.scss'],
})
export class QuestionTimeComponent implements OnInit {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();
  regex = '';
  isLongText = false;

  min!: number;
  max!: number;

  ngOnInit(): void {
    if (this.question.configuration) {
      this.handleConfiguration();
    }
  }

  handleConfiguration() {}

  change(event: any) {
    this.question.isValid = this.checkValidation(event);
    console.log(`${this.question.name} valid: `, this.question.isValid);

    this.changeAnswer.next({
      answer: this.question.answer!,
      question: this.question,
    });
  }

  checkValidation(event: any) {
    let result = true;
    result = Array.from(event?.classList)?.includes('ng-valid');

    return result;
  }
}
