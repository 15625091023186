import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-recovery-email',
  templateUrl: './recovery-email.component.html',
  styleUrls: ['./recovery-email.component.scss'],
})
export class RecoveryEmailComponent implements OnInit {
  email = '';
  isLoading = false;

  lastSendTime: number | null = null;
  sendCodePermission = false;

  form: FormGroup<any> = new FormGroup({});
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder, private userService: UserService) {}

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    setInterval(() => {
      const now = new Date().getTime();
      if (!this.lastSendTime) {
        this.sendCodePermission = true;
        return;
      }

      if (this.lastSendTime && now - this.lastSendTime > 1000 * 60) {
        this.sendCodePermission = true;
      } else {
        this.sendCodePermission = false;
      }
    }, 1000);
  }

  getDiffLastTimeAndNowInSec() {
    if (!this.lastSendTime) return 0;
    return 60 - Math.floor((new Date().getTime() - this.lastSendTime) / 1000);
  }

  async submit() {
    try {
      this.isLoading = true;

      const email = this.form.get('email')?.value;
      if (!email) throw Error('Invalid Email');

      const { error } = await this.userService.sendResetPasswordEmail(email);

      if (error) throw Error('Issue sending email');
      this.lastSendTime = new Date().getTime();
      this.sendCodePermission = false;
      this.onSubmit.emit(email);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
