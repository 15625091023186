import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contract } from 'src/app/core/models/contract';
import { ContractService } from 'src/app/core/services/contract.service';

@Component({
  selector: 'app-pdf-viewer-container-electronic-assignature',
  templateUrl: './pdf-viewer-container-electronic-assignature.component.html',
  styleUrls: ['./pdf-viewer-container-electronic-assignature.component.scss'],
})
export class PdfViewerContainerElectronicAssignatureComponent
  implements OnInit
{
  zoom: number = 0.5;
  originalSize: boolean = true;
  checkbox = false;
  docURL?: string;
  @Output() next = new EventEmitter();

  @Input()
  contract?: Contract;
  constructor(public cs: ContractService) {}

  async ngOnInit() {
    if (!this.contract) {
      console.error('Component requires contract');
      throw new Error('Component requires contract');
    }

    const url = await this.cs.getContractDocumentURLById(this.contract.id!);

    this.docURL = url;

    console.log('URL', url);
  }

  incrementZoom(amount: number) {
    this.zoom += amount;
    console.log(this.zoom);
  }

  emitEvent() {
    this.next.emit();
  }
}
