import {Component, Input} from '@angular/core';
import {News} from 'src/app/core/models/news';

@Component({
  selector: 'app-news-teaser-card',
  templateUrl: './news-teaser-card.component.html',
  styleUrls: ['./news-teaser-card.component.scss'],
})
export class NewsTeaserCardComponent {
  random = Math.round(Math.random() * 300);
  @Input() public news?: News;
  @Input() multiClaims?: boolean;

  ngOnInit() {
  }
}
