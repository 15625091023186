import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ClaimsService} from 'src/app/core/services/claims.service';

//TODO: remove unused component

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss'],
})
export class ClaimsComponent implements OnInit {
  isLoading$: Observable<boolean> | undefined;
  errors$: Observable<any> | undefined;
  claims$: Observable<any> | undefined;

  constructor(private claimsService: ClaimsService) {
  }

  ngOnInit() {
  }
}
