import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, filter, map, tap } from 'rxjs';
import { ThreadWithMessages } from 'src/app/core/services/communication.service';
import { gsap } from 'gsap';
import { UserService } from '../../../../../core/services/user.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Input()
  public notifications$?: Observable<ThreadWithMessages[]>;

  @ViewChild('drawer', { static: false })
  drawer!: MatDrawer;
  isToShow = false;

  constructor(private router: Router, private us: UserService) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        map((ev) => ev as any),
        filter((ev) => {
          return true;
        }),
        tap((ev) => {
          // console.log('ROUTER', this.router.url);
          const a = this.router.url.split('/');
        })
      )
      .subscribe();

    const savedState = localStorage.getItem('sidenavOpened');
    if (savedState !== null && !this.isMobile()) {
      this.isToShow = savedState === 'true';
      this.isToShow && this.animateLinks();
    }
  }

  isMobile(): boolean {
    return window.innerWidth < 990;
  }

  closeSideNav() {
    if (this.isMobile()) this.isToShow = false;
  }

  toggleSidenav() {
    this.isToShow = !this.isToShow;
    localStorage.setItem('sidenavOpened', this.isToShow.toString());
    this.animateLinks();
  }

  animateLinks() {
    const links = document.querySelectorAll('.animatable-menu-span');
    if (this.isToShow) {
      gsap.fromTo(
        links,
        { opacity: 0, x: -10 },
        { opacity: 1, x: 0, duration: 0.5, stagger: 0.1 }
      );
    } else {
      gsap.to(links, { opacity: 0, duration: 0.2 });
    }
  }

  logout() {
    localStorage.removeItem('userRole');
    this.us.signOut();
  }
}
