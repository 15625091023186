import { Component, Input, OnInit } from '@angular/core';
import {
  combineLatest,
  delay,
  distinctUntilChanged,
  first,
  forkJoin,
  Subscription,
  tap,
} from 'rxjs';
import { Base } from 'src/app/core/models/base.model';
import {
  DatabaseService,
  Filter,
} from 'src/app/core/services/base/database.service';
import { DynamicService } from 'src/app/core/services/base/dynamic.service';
var approx = require('approximate-number');

import { isArray } from 'lodash';

@Component({
  selector: 'app-big-number',
  templateUrl: './big-number.component.html',
  styleUrls: ['./big-number.component.scss'],
})
export class BigNumberComponent<T extends Base> implements OnInit {
  dbi: DynamicService<T>;
  loading: boolean = true;
  subscriptions: Subscription[] = [];
  constructor() {
    this.dbi = new DynamicService<T>();
  }
  @Input('settings') settings!: BigNumberSettings | BigNumberSettingsList[];

  @Input('version') version: number = 1;

  // Version 1 (Single)
  settingsV1: BigNumberSettings | undefined;
  value: { value: number; settings: BigNumberSettings } | undefined;

  // Version 2 (List)
  settingsv2: BigNumberSettingsList[] = [];
  loadingv2: boolean = true;
  values: { value: number; settings: BigNumberSettingsList }[] = [];

  ngOnInit(): void {
    if (this.version === 1) this.initV1();
    else if (this.version === 2) this.initV2();
    else throw new Error('Version not supported');
  }

  private initV1() {
    if (!this.settings) throw new Error('Settings not provided');
    this.settingsV1 = this.settings as BigNumberSettings;

    if (!this.settingsV1) throw new Error('Entity not provided');

    this.dbi.load(this.settingsV1.entity);
    const res$ = this.dbi.count(this.settingsV1.filters).pipe(
      // delay(Math.random() * 1000 + 500),
      tap((ret) => {
        if (!this.settingsV1!.shorten) {
          this.value = {
            value: ret.count,
            settings: this.settingsV1!,
          };
        } else {
          this.value = {
            value: approx(ret.count, {
              precision: this.settingsV1!.shorten,
            }),
            settings: this.settingsV1!,
          };
        }
      }),
      tap((ret) => (this.loading = false))
    );
    this.subscriptions.push(res$.subscribe());
  }

  initV2(): void {
    if (!this.settings) throw new Error('Settings not provided');
    if (!isArray(this.settings)) throw new Error('Settings must be an array');
    this.settingsv2 = this.settings as BigNumberSettingsList[];

    const observables = this.settingsv2.map((setting) => {
      this.dbi.load(setting.entity);

      return this.dbi.count(setting.filters).pipe(
        first(),
        distinctUntilChanged((prev, curr) => {
          return prev.count === curr.count;
        }),
        tap((ret) => {
          if (!setting.shorten) {
            this.updateValues(setting.id, {
              value: ret.count ?? 0,
              settings: setting,
            });
          } else {
            this.updateValues(setting.id, {
              value: approx(ret.count ?? 0, {
                precision: setting.shorten,
              }),
              settings: setting,
            });
          }

          this.values.sort((a, b) => a.settings.priority - b.settings.priority);
        })
      );
    });

    const combined$ = forkJoin(observables).pipe(
      tap(() => (this.loading = false))
    );

    this.subscriptions.push(combined$.subscribe());
  }

  private updateValues(
    id: string,
    newValue: {
      value: number;
      settings: BigNumberSettingsList;
    }
  ): void {
    const setting = this.values.findIndex((e) => e.settings.id === id);
    if (setting === -1) {
      this.values.push(newValue);
    } else {
      this.values[setting] = newValue;
    }
  }
}

export interface BigNumberSettingsList extends BigNumberSettings {
  id: string;
  priority: number;
}

export interface BigNumberSettings {
  entity: string;
  filters: Filter[];
  shorten?: number;
  content?: {
    title: string;
    icon: string;
    color: string;
  };
}
