import { Component } from '@angular/core';

@Component({
  selector: 'app-recovery-success',
  templateUrl: './recovery-success.component.html',
  styleUrls: ['./recovery-success.component.scss']
})
export class RecoverySuccessComponent {

}
