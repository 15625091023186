import { Component, OnInit } from '@angular/core';

import {
  ClaimsService,
  ClaimWithCase,
} from 'src/app/core/services/claims.service';

@Component({
  selector: 'app-your-list-claim',
  templateUrl: './your-list-claim.component.html',
  styleUrls: ['./your-list-claim.component.scss'],
})
export class YourListClaimComponent implements OnInit {
  claims?: ClaimWithCase[];
  claimsToShow?: ClaimWithCase[];
  status!: string;

  constructor(private cs: ClaimsService) {}

  async ngOnInit() {
    this.claims = (await this.cs.getMyClaimsWithCase(10, 0)) as ClaimWithCase[];
    // this.claims = [...claims];
    if (this.claims) {
      this.claimsToShow = this.claims;
    }
  }

  changeStatus() {
    if (!this.claims) {
      return;
    }
    if (this.status == null) {
      this.claimsToShow = [...this.claims];
    } else {
      this.claimsToShow = this.claims.filter(
        (data) => data.status == this.status
      );
    }
  }
}
