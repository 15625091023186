import * as _ from 'lodash';

export type AggregatorType = 'any' | 'none' | 'all';

// Define comparison functions for various conditions
const comparisonFunctions: { [operator: string]: (a: any, b: any) => boolean } =
  {
    // Equals: Checks if 'a' is strictly equal to 'b'
    $eq: (a, b) => a === b,

    // Different: Checks if 'a' is not strictly equal to 'b'
    $dif: (a, b) => a !== b,

    // Not Equals: Checks if 'a' is not equal to 'b'
    $neq: (a, b) => a != b,

    // Includes: Checks if 'a' includes 'b'
    $includes: (a, b) => (_.isArray(a) ? a.includes(b) : _.includes(a, b)),

    // Contains: Checks if 'a' contains 'b' as a substring
    $contains: (a, b) => _.isString(a) && a.includes(b),

    // Not Contains: Checks if 'a' does not contain 'b' as a substring
    $notContains: (a, b) => !(_.isString(a) && a.includes(b)),

    // Exists: Checks if 'a' exists (is truthy) and matches 'b'
    $exists: (a, b) => !!a === b,

    // Greater Than: Checks if 'a' is greater than 'b'
    $gt: (a, b) => _.isNumber(a) && _.isNumber(b) && a > b,

    // Less Than: Checks if 'a' is less than 'b'
    $lt: (a, b) => _.isNumber(a) && _.isNumber(b) && a < b,

    // Greater Than or Equal: Checks if 'a' is greater than or equal to 'b'
    $gte: (a, b) => _.isNumber(a) && _.isNumber(b) && a >= b,

    // Less Than or Equal: Checks if 'a' is less than or equal to 'b'
    $lte: (a, b) => _.isNumber(a) && _.isNumber(b) && a <= b,

    // Starts With: Checks if 'a' starts with 'b'
    $startsWith: (a, b) => _.isString(a) && a.startsWith(b),

    // Ends With: Checks if 'a' ends with 'b'
    $endsWith: (a, b) => _.isString(a) && a.endsWith(b),

    // Is Empty: Checks if 'a' is an empty string or null/undefined
    $isEmpty: (a, b) => (_.isNil(a) || a === '') === b,

    // Is Not Empty: Checks if 'a' is not an empty string or null/undefined
    $isNotEmpty: (a, b) => (!_.isNil(a) && a !== '') === b,
  };

// Function to get a value from nested survey answers data
function getValueFromAnswers(data: any, parts: string[]): any {
  const entity = parts[0];
  const key = parts[1];

  // console.log('skip => eval conds get val', { data, entity, key, parts });

  // console.log('skip => eval conds get entity', data[entity]);
  // console.log('skip => eval conds get key', data[entity]?.[key]);

  if (!data[entity]) {
    console.log('skip => eval conds bug');
  }

  let currentValue = data[entity]?.[key]?.value ?? null;

  if (currentValue?.isIgnored) {
    return '';
  }

  return currentValue;
}

/**
 * Retrieve a value from the data object based on a dot-notated key string.
 *
 * @param data - The data object to retrieve the value from.
 * @param key - A dot-notated string representing the key path in the data object.
 * @returns The value at the specified key path in the data object, or null if not found.
 */
function getValue(data: any, key: string): any {
  const parts = key.split('.');
  const entity = parts[0];

  switch (entity) {
    case 'answers':
      return getValueFromAnswers(data, parts);
    default:
      throw new Error('Entity not implemented yet');
  }
}

/**
 * Run a single condition check.
 *
 * @param key - The key path to the value in the data object.
 * @param condition - The condition to check against.
 * @param value - The value to compare.
 * @param data - The data object containing values to compare.
 * @returns A boolean indicating if the condition was met.
 */
function evaluateCondition(
  key: string,
  condition: string,
  value: string | boolean,
  data: any
): boolean {
  // console.log('skip if getting val => HM => eval cond res:', {
  //   condition,
  //   data,
  //   key,
  // });
  let comparedValue = getValue(data, key);

  if (comparedValue === 'true') comparedValue = true;
  if (comparedValue === 'false') comparedValue = false;

  let expectedValue = value;
  if (typeof expectedValue === 'string') {
    if (expectedValue === 'true') expectedValue = true;
    if (expectedValue === 'false') expectedValue = false;
  }

  // console.log(
  //   'skip if cond => HM => eval cond res:',
  //   condition,
  //   comparedValue,
  //   expectedValue
  // );

  return comparisonFunctions[condition](comparedValue, expectedValue);
}

/**
 * Run multiple conditions and aggregate their results.
 *
 * @param conditioner - The conditioner object containing aggregation type and conditions.
 * @param data - The data object to check conditions against.
 * @returns A boolean indicating if the aggregated conditions were met.
 */
export function runConditions(
  conditioner: {
    aggregator: AggregatorType;
    conditions: { key: string; condition: string; value: string | boolean }[];
  },
  data: any
): boolean {
  const results: boolean[] = conditioner.conditions.map((cond) =>
    evaluateCondition(cond.key, cond.condition, cond.value, data)
  );

  // console.log('skip if => HM => running conds', { conditioner, data, results });

  switch (conditioner.aggregator) {
    case 'all':
      return !results.includes(false);
    case 'any':
      return results.includes(true);
    case 'none':
      return !results.includes(true);
    default:
      return false;
  }
}

/**
 * Run multiple condition groups and aggregate their results.
 *
 * @param conditioners - An array of conditioner objects, each containing aggregation type and conditions.
 * @param data - The data object to check conditions against.
 * @returns A boolean indicating if all sets of aggregated conditions were met.
 */
export function runConditionsArray(
  conditioners: {
    aggregator: AggregatorType;
    conditions: { key: string; condition: string; value: string | boolean }[];
  }[],
  data: any
): boolean {
  for (let i = 0; i < conditioners.length; i++) {
    const conditioner = conditioners[i];
    const result = runConditions(conditioner, data);
    if (!result) {
      console.log(`Conditioner at index ${i} failed`, conditioner);
      return false;
    }
  }
  return true;
}
