import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Case } from 'src/app/core/models/case';
import { Claim } from 'src/app/core/models/claim';
import { CaseService } from 'src/app/core/services/case.service';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss'],
})
export class CaseComponent {
  case!: Case;

  constructor(private route: ActivatedRoute, private czs: CaseService) {}

  async ngOnInit() {
    let idCase = this.route.snapshot.paramMap.get('id');
    this.case = await this.czs.getByCode(idCase);
    // this.case = cases.filter((data) => data.uuid == idCase)[0];
  }
}
