import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Action } from 'src/app/core/models/action.model';
import { Kyc_Check } from 'src/app/core/models/kyc_check.model';
import { ClaimsService } from 'src/app/core/services/claims.service';
import { KycService } from 'src/app/core/services/kyc.service';

@Component({
  selector: 'app-outstanding-actions',
  templateUrl: './outstanding-actions.component.html',
  styleUrls: [
    './outstanding-actions.component.scss',
    './components/oa-styles.scss',
  ],
})
export class OutstandingActionsComponent implements OnInit {
  @Input() claimId!: string;
  @Output() outstandingActions = new EventEmitter<Action[]>();

  loading: boolean = true;
  error: string = '';
  actions?: Action[] | null;

  constructor(private cs: ClaimsService, private ks: KycService) {}
  async ngOnInit() {
    await this.loadActions();
  }

  async loadActions() {
    this.loading = true;
    const a = await this.cs.getRequirementsListForClaimV2(this.claimId);
    console.log('[OA] loadActions', a);

    if (!a) {
      this.error = 'Could not load outstanding actions. Try again later.';
      return;
    }

    const actionTypes: { [type: string]: Action } = {};

    const kycs = a.filter((e) => e.action_type == 'KYC');
    console.log('[OA] Kycs', kycs);
    const fKycs: (Kyc_Check | null)[] = [];

    for (let i = 0; i < kycs.length; i++) {
      const kyc = kycs[i];
      if (kyc.done_action_id) {
        const fKyc = await this.ks.get(kyc.done_action_id);
        fKycs.push(fKyc);
      } else {
        fKycs.push(null);
      }
    }

    const finalList = a.filter((e) => e.action_type !== 'KYC');

    console.log('[OA] Selected index', this.selectKycFromList(fKycs));

    if (kycs && kycs.length > 0) {
      finalList.push(kycs[this.selectKycFromList(fKycs)]);
    }

    this.loading = false;
    this.actions = finalList;
    console.log('[OA] - Load', this.actions);

    this.outstandingActions.emit(this.actions);
  }

  async onChange() {
    await this.loadActions();
  }

  selectKycFromList(list: (Kyc_Check | null)[]): number {
    for (let i = 0; i < list.length; i++) {
      const kyc = list[i];
      if (kyc?.passed) {
        return i;
      }
    }
    console.log('[OA] No KYC passed.');

    if (list.length <= 1) {
      return 0;
    }

    let nl = list.filter((e) => !!e);

    nl = nl.sort((a, b) => {
      const val =
        new Date(b!.created_at!).getTime() - new Date(a!.created_at!).getTime();
      return val;
    });

    console.log('[OA] NL', nl);
    console.log('[OA] NL0', nl[0]!.id);
    console.log('[OA] list', list);

    const index = list.findIndex((v) => {
      return nl[0]!.id == v?.id;
    });

    console.log('[OA] Index', index);
    if (index == -1) return 0;

    return index;
  }
}
