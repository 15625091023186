import { Injectable } from '@angular/core';

import { Personal_Document } from '../models/personal_document.model';

import { v4 } from 'uuid';
import { DocumentService, UploadReturn } from './base/document.service';

@Injectable({
  providedIn: 'root',
})
export class PersonalDocumentService extends DocumentService<Personal_Document> {
  constructor() {
    super('cp_personal_document');
  }

  public async upload(
    f: File,
    user_id: string,
    name: string,
    bucket: string,
    seq?: number
  ): Promise<UploadReturn> {
    const ext = f.name.split('.').pop();
    const id = v4();
    const url =
      seq === undefined
        ? `${user_id}/${id}.${ext}`
        : `${user_id}/${id}/${seq}.${ext}`;
    const pd: Personal_Document = {
      bucket,
      doc_type: name,
      kv_extract: {},
      ocr_extract: {},
      status: 'PRE_UPLOAD',
      url,
      user_id: user_id,
      created_at: new Date(),
      id,
    };

    const ulret = await this.uploadDocument(pd, await f.arrayBuffer());

    if (ulret.error || !ulret.data) {
      return { data: null, error: ulret.error };
    }

    return {
      data: {
        doc: pd,
        path: ulret.data.path,
      },
      error: null,
    };
  }
  public async uploadV2(
    f: File,
    user_id: string,
    name: string,
    bucket: string,
    options: {
      seq?: number;
      check_id?: string;
    } = {}
  ): Promise<UploadReturn> {
    const ext = f.name.split('.').pop();
    const id = v4();
    const url =
      options.seq === undefined
        ? `${user_id}/${id}.${ext}`
        : `${user_id}/${id}/${options.seq}.${ext}`;
    const pd: Personal_Document = {
      bucket,
      doc_type: name,
      kv_extract: {},
      ocr_extract: {},
      status: 'PRE_UPLOAD',
      url,
      user_id: user_id,
      created_at: new Date(),
      check_id: options.check_id,
      id,
    };

    console.log('uploadV2 bef', pd);
    const ulret = await this.uploadDocumentV2(pd, f);
    console.log('uploadV2 af', ulret);

    if (ulret.error || !ulret.data) {
      return { data: null, error: ulret.error };
    }

    return {
      data: {
        doc: pd,
        path: ulret.data.path,
      },
      error: null,
    };
  }

  async getAllDocumentsFromCheckID(
    check_id: string
  ): Promise<Personal_Document[]> {
    const items = await this.supabase
      .from(this.table)
      .select()
      .eq('check_id', check_id);
    return items.data || [];
  }
}
