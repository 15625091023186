import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-extra',
  templateUrl: './button-extra.component.html',
  styleUrls: ['./button-extra.component.scss'],
})
export class ButtonExtraComponent {
  @Input() type!: string;
}
