import { SupabaseClient } from '@supabase/supabase-js';
import { Observable, Subject } from 'rxjs';
import { v4 } from 'uuid';

import { Base } from '../../models/base.model';

import { ICrud } from './crud.interface';
import { Database } from '../../models/types';
import { SupabaseService } from './supabase.service';
import { inject } from '@angular/core';
import { TextFilterConfig } from 'src/app/shared/components/smart/table/table.component';

export class DatabaseService<T extends Base> implements ICrud<T> {
  protected table: string;
  // private supabaseUrl: string;
  // private supabaseKey: string;
  private sbs: SupabaseService = inject(SupabaseService);
  public supabase: SupabaseClient<Database>;

  public _getPagination = (page: number, size: number) => {
    const limit = size ? +size : 3;
    const from = page ? page * limit : 0;
    const to = page ? from + size - 1 : size - 1;
    return { from, to };
  };

  constructor(table: string) {
    this.table = table;
    this.supabase = this.sbs.supabase;
  }

  async get(id: string): Promise<T> {
    const { data, error } = await this.supabase
      .from(this.table)
      .select()
      .eq('id', id)
      .single();

    if (error) throw new Error(error.message);

    return data;
  }

  async getAll(limit: number, page: number): Promise<T[]> {
    const query = this.supabase.from(this.table).select();
    if (limit) query.limit(limit);
    if (page) {
      const { from, to } = this._getPagination(page, limit);
      query.range(from, to);
    }

    const { data, error } = await query;

    if (error) throw new Error(error.message);

    return data;
  }

  // async getAllDistinct(column: string): Promise<any[]> {
  //   const { data, error } = await this.supabase
  //     .rpc('distinct_values', { schema: 'public', table: this.table, column })
  //     .select('distinct_values');

  //   if (error) {
  //     throw new Error(error.message);
  //   }

  //   return data?.map((item: any) => item.distinct_values);
  // }

  async create(t: Partial<T>) {
    const { data, error } = await this.supabase
      .from(this.table)
      .insert(t)
      .select();

    if (error) {
      throw new Error(error.message);
    }

    return data;
  }

  async update(t: Partial<T>) {
    const { data, error } = await this.supabase
      .from(this.table)
      .update(t)
      .eq('id', t.id)
      .select();
    if (error) {
      console.error('UPDATE ERROR', error);
      throw new Error(error.message);
    }
    return data;
  }

  async delete(t: T) {
    console.log('DELETE', t);
    console.log('DELETE', this.table);
    const { data, error } = await this.supabase
      .from(this.table)
      .delete()
      .eq('id', t.id);
    if (error) {
      throw new Error(error.message);
    }
    return data;
  }

  list(
    pageIndex: number = 0,
    pageSize: number = 5,
    filters: Filter[] = [],
    sort: Sort[] = [],
    search?: TextFilterConfig
  ): Observable<QueryResult<T>> {
    const subj = new Subject<QueryResult<T>>();

    let a = this.supabase.from(this.table).select('*', { count: 'estimated' });

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      if (filter.value !== null) {
        switch (filter.operator) {
          case 'contains':
            a = a.ilike(filter.column, `%${filter.value}%`);
            break;
          case 'startswith':
            a = a.ilike(filter.column, `${filter.value}%`);
            break;
          case 'endswith':
            a = a.ilike(filter.column, `%${filter.value}`);
            break;

          case 'empty':
            a = a.is(filter.column, null);
            break;

          case 'eq':
            a = a.eq(filter.column, filter.value);
            break;

          case 'neq':
            a = a.neq(filter.column, filter.value);
            break;
          case 'gte':
            a = a.gte(filter.column, filter.value);
            break;
          case 'lte':
            a = a.lte(filter.column, filter.value);
            break;
          case 'gt':
            a = a.gt(filter.column, filter.value);
            break;
          case 'lt':
            a = a.lt(filter.column, filter.value);
            break;

          // case 'ncontains':
          //   a = a.ilike(filter.column, `%${filter.value}%`);
          //   break;

          default:
            // TODO: REDO
            // a = a[filter.operator](filter.column, filter.value);
            break;
        }
      }
    }

    console.log('range', [
      pageIndex * pageSize,
      (pageIndex + 1) * pageSize - 1,
    ]);
    a = a.range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1);

    for (let i = 0; i < sort.length; i++) {
      const cs = sort[i];
      a.order(cs.column, { ascending: cs.direction == 'asc' });
    }

    if (search) {
      a = a.textSearch(search.column, search.value);
    }

    a.then((ev) => {
      subj.next({ data: ev.data as T[], count: ev.count! });
    });

    return subj.asObservable();
  }

  count(filters: Filter[] = []): Observable<QueryResult<T>> {
    const subj = new Subject<QueryResult<T>>();

    let a = this.supabase
      .from(this.table)
      .select('*', { count: 'exact', head: true });

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      applyFilter(a, filter);
    }

    a.then((ev) => {
      subj.next({ data: ev.data as T[], count: ev.count! });
    });

    return subj.asObservable();
  }

  async getAllDistinct(column: string) {
    const data = await this.supabase
      .from(this.table)
      .select(`${column}.count()`, { count: 'exact' });

    if (data.error) {
      throw new Error(data.error.message);
    }

    return data;
  }
}

export interface QueryResult<T> {
  data: T[];
  count: number;
}
export interface Sort {
  column: string;
  direction: 'asc' | 'desc';
}
export interface Filter {
  column: string;
  operator:
    | 'gte'
    | 'gt'
    | 'eq'
    | 'lt'
    | 'lte'
    | 'ilike'
    | 'neq'
    | 'contains'
    | 'empty'
    // | 'ncontains'
    | 'startswith'
    | 'endswith';
  value: string | number | boolean | any[] | null;
  visible: boolean;
}

function applyFilter(current: any, filter: Filter) {
  if (filter.value !== null) {
    switch (filter.operator) {
      case 'contains':
        current = current.ilike(filter.column, `%${filter.value}%`);
        break;
      case 'startswith':
        current = current.ilike(filter.column, `${filter.value}%`);
        break;
      case 'endswith':
        current = current.ilike(filter.column, `%${filter.value}`);
        break;

      case 'empty':
        current = current.is(filter.column, null);
        break;
      case 'gte':
        current = current.is(filter.column, null);
        break;
      case 'lte':
        current = current.is(filter.column, null);
        break;

      default:
        current = current[filter.operator](filter.column, filter.value);
        break;
    }

    // current = current[filter.operator](filter.column, filter.value);
  }
}
