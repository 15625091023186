import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Question } from 'src/app/core/models/questionnaire.model';
import { QuestionAnswer } from '../question/question.component';
import { AutofillFilter } from '../question-text/question-text.component';
import { DynamicService } from 'src/app/core/services/base/dynamic.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-question-phone',
  templateUrl: './question-phone.component.html',
  styleUrls: ['./question-phone.component.scss'],
})
export class QuestionPhoneComponent implements OnInit, OnDestroy {
  @Input('question') question!: Question;
  @Output('changeAnswer') changeAnswer: EventEmitter<QuestionAnswer> =
    new EventEmitter();

  phone = new FormGroup({ number: new FormControl('', Validators.required) });

  sub$!: Subscription;
  preferredCountries = ['gb', 'us'];

  autofillMode: boolean = false;
  autofillEditable: boolean = false;
  userId: string = '';

  constructor(
    private dynamicService: DynamicService<any>,
    private userService: UserService
  ) {}

  async ngOnInit() {
    console.log('CAIO4 - QuestionPhoneComponent - ngOnInit', this.question);
    if (this.question.configuration.autofill && !this.question.answer?.value) {
      await this.processAutofill(this.question.configuration.autofill);
      this.change();
    }
    if (this.question.answer && this.question.answer.value) {
      this.phone.controls.number.setValue(
        this.question.answer.value.toString()
      );
      this.change();
    }

    if (this.question.configuration && this.question.configuration.prefer) {
      this.preferredCountries = this.question.configuration.prefer;
    }

    this.sub$ = this.phone.controls.number.valueChanges.subscribe((_) => {
      this.change();
    });
  }
  ngOnDestroy(): void {
    if (this.sub$) {
      this.sub$.unsubscribe();
    }
  }
  private async processAutofill(autofillConfig: any) {
    this.autofillMode = true;
    this.autofillEditable = autofillConfig.editable ?? false;
    await this.fetchCurrentUserId();

    console.log('CAIO4 - QuestionPhoneComponent - processAutofill', {
      autofillConfig,
      userId: this.userId,
    });

    const filters = this.replacePlaceholdersInFilters(autofillConfig.filters);
    const autofillValue = await this.fetchAutofillValue(
      autofillConfig.from,
      autofillConfig.select,
      filters
    );

    console.log('CAIO4 - QuestionPhoneComponent - processAutofill', {
      autofillValue,
    });

    if (autofillValue) {
      this.question.answer = { value: autofillValue };
      this.phone.controls.number.setValue(
        this.question.answer.value!.toString()
      );

      this.change();
    }
  }

  private async fetchCurrentUserId() {
    const currentUser = await this.userService.getCurrentUser();
    this.userId = currentUser?.id || '';
  }

  private replacePlaceholdersInFilters(
    filters: AutofillFilter[]
  ): AutofillFilter[] {
    return filters.map((filter) => ({
      ...filter,
      val: filter.val.replace('$userId', this.userId),
    }));
  }

  private async fetchAutofillValue(
    from: string,
    select: string,
    filters: AutofillFilter[]
  ): Promise<string | null> {
    try {
      this.dynamicService.load(from);
      const result = await this.dynamicService.autofillQuery(
        from,
        select,
        filters
      );

      console.log('CAIO4 - QuestionPhoneComponent - fetchAutofillValue', {
        result,
        result2: result?.[0],
        result3: result?.[0]?.[select as any],
        result4:
          result?.[0]?.[
            (select as any).split('->>')[
              (select as any).split('->>').length - 1
            ]
          ],
      });

      return (
        result?.[0]?.[
          (select as any).split('->>')[(select as any).split('->>').length - 1]
        ] || null
      );
    } catch (error) {
      console.error('Erro ao buscar valor de autofill:', error);
      return null;
    }
  }

  change() {
    this.question.answer!.value = this.phone.controls.number.value;
    this.question.answer!.addtional_information = {
      phone: this.phone.controls.number.value,
    };
    this.question.isValid = true;
    this.changeAnswer.next({
      answer: this.question.answer!,
      question: this.question,
    });
  }
}
